// 현재 가장 작은 높이를 가진 열의 인덱스를 찾기
export function getMinHeightMasonryColumn(
  COLUMN_COUNT: number,
  masonryColumnsRef: React.MutableRefObject<HTMLDivElement[] | null[]>,
) {
  const columnHeights = Array.from(
    { length: COLUMN_COUNT },
    (_, index) => masonryColumnsRef.current[index]?.offsetHeight,
  ).filter((height): height is number => typeof height === 'number');

  // undefined를 가진 인덱스를 제외하고 가장 작은 높이를 가진 열의 인덱스를 찾기
  const minIndex = columnHeights.reduce(
    (minIndex, height, currentIndex) =>
      height !== undefined && height < columnHeights[minIndex]
        ? currentIndex
        : minIndex,
    0,
  );
  return minIndex;
}

//masonryColumnElements에서 deleteImageId의 위치를 찾아 index를 반환
export function getDeletePosition(
  masonryColumnElements: JSX.Element[][],
  deleteImageId: number,
) {
  let startColumn = -1;
  let startIndex = -1;

  for (let column = 0; column < masonryColumnElements.length; column++) {
    const element = masonryColumnElements[column];

    for (let index = 0; index < element.length; index++) {
      const imageCard = element[index];
      if (imageCard.key === deleteImageId.toString()) {
        startColumn = column;
        startIndex = index;
        break;
      }
    }
  }
  return { startColumn, startIndex };
}

//masonryColumnElements에서 columnIndex, startIndex 이전의 element들을 복사하여 반환
export function copyPreviousElements(
  newMasonryColumnElements: JSX.Element[][],
  startColumn: number,
  startIndex: number,
  COLUMN_COUNT: number,
) {
  const copiedElements: JSX.Element[][] = Array.from(
    { length: COLUMN_COUNT },
    () => [],
  );

  for (let column = 0; column < COLUMN_COUNT; column++) {
    const element = newMasonryColumnElements[column];
    for (let index = 0; index <= startIndex; index++) {
      const imageCard = element[index];

      if (column >= startColumn && index === startIndex) {
        break;
      } else {
        // imageCard가 존재하는 경우에만 처리
        imageCard && copiedElements[column].push(imageCard);
      }
    }
  }
  return copiedElements;
}

//masonryColumnElements에서 columnIndex, startIndex 이후의 element들을 복사하여 반환
export function copyNextElements(
  masonryColumnElements: JSX.Element[][],
  startColumn: number,
  startRow: number,
) {
  const result = [];

  // masonryColumnElements를 행과 열을 바꾸어 렌더링된 형태로 변환

  const transposedMasonryElements = masonryColumnElements[0].map(
    (_, colIndex) =>
      masonryColumnElements
        .map((row) => row[colIndex])
        .filter((value) => value !== undefined),
  );

  // startIndex 이후의 element들을 복사하여 반환
  for (let i = startRow; i < transposedMasonryElements.length; i++) {
    // column의 범위: 시작 행에서는 startColumn 이후부터, 그 외의 행에서는 처음부터
    const startColIndex = i === startRow ? startColumn + 1 : 0;

    for (let j = startColIndex; j < transposedMasonryElements[i].length; j++) {
      result.push(transposedMasonryElements[i][j]);
    }
  }

  return result;
}
