import GroupCardList from '../features/etc/group/GroupCardList';
import ImageViewOption from '../features/etc/imageViewOption/ImageViewOption';
import GalleryManagerViewer from '../features/galleryManager/GalleryManagerViewer';
import Banner from '../features/slideShow/banner/Banner';

const SlideShowManagePage = () => {
  return (
    <div className="w-full h-full flex  flex-col px-[15px]  ">
      {/* Header */}
      <Banner />
      {/* Container */}
      <ImageViewOption />

      <div className="w-full h-full flex overflow-hidden ">
        <div className="w-2/12 pr-9">
          <GroupCardList />
        </div>

        <div id="galleryViewer" className="w-10/12 h-full  ">
          <GalleryManagerViewer />
        </div>
      </div>
    </div>
  );
};

export default SlideShowManagePage;
