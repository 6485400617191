import { useAppSelector } from '../../../app/hooks';
import { UserImage } from '../../../types/UserImage';

interface UserCardProps {
  user: UserImage;
  active?: boolean;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  clickEvent: () => void; // 수정: clickEvent의 인수 없앰
}
const UserCard = ({ user, clickEvent, Icon }: UserCardProps) => {
  const selectedUser = useAppSelector((state) => state.userCard.selectedUser);

  return (
    <div className="">
      <div
        key={user.id}
        className="w-full py-1 flex flex-row items-center justify-between  rounded-[5px]  text-[#555555] font-medium text-base cursor-pointer  transition-all duration-300 ease-in-out"
        onClick={() => clickEvent()}
      >
        <div className="flex flex-row items-center">
          {Icon && <Icon />}

          <div
            className={`ml-3 text-lg ${
              selectedUser === user.id && `text-[#F95E21]`
            }`}
          >
            {user.name}
          </div>
        </div>
        <div className="text-[#F95E21] text-sm mr-1 ">{user.image_count}</div>
      </div>
    </div>
  );
};
export default UserCard;
