import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

interface BestGalleryState {
  loadingImgList: boolean;
  loadingDetail: boolean;
  imageListData: {
    id: number;
    image: string;
    width: number;
    height: number;
  }[];
  imageDetailData: {
    image: string;
    prompt: string;
    negative_prompt: string;
    create_date: string;
    material: string;
    style: string;
    width: number;
    height: number;
  } | null;
  openBestDetailModal: boolean;
}

const initialState: BestGalleryState = {
  loadingImgList: false,
  loadingDetail: false,
  imageListData: [],
  imageDetailData: null,
  openBestDetailModal: false,
};

export const getBestGalleryApi = createAsyncThunk(
  'gallery/catalog',
  async (page: number) => {
    const response = await fetch(
      `${apiInfo.api_url}/gallery/catalog?sequence=${page}`,
    );
    let data = await response.json();

    return data.payload;
  },
);

export const getDetailBestimgApi = createAsyncThunk(
  'gallery/detail',
  async (id: number) => {
    const response = await fetch(`${apiInfo.api_url}/gallery/catalog?id=${id}`);
    let data = await response.json();

    return data.payload;
  },
);

const bestGallerySlice = createSlice({
  name: 'BestGallery',
  initialState,
  reducers: {
    setOpenBestDetailModal: (state, action) => {
      state.openBestDetailModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBestGalleryApi.pending, (state) => {
        state.loadingImgList = true;
        state.imageListData = [];
      })
      .addCase(getBestGalleryApi.fulfilled, (state, action) => {
        state.loadingImgList = false;
        state.imageListData = action.payload;
      })
      .addCase(getBestGalleryApi.rejected, (state) => {
        state.loadingImgList = false;
        state.imageListData = [];
      })

      // 베스트 갤러리 이미지 선택 조회
      .addCase(getDetailBestimgApi.pending, (state) => {
        state.loadingDetail = true;
        state.imageDetailData = null;
      })
      .addCase(getDetailBestimgApi.fulfilled, (state, action) => {
        state.loadingDetail = false;
        state.imageDetailData = action.payload;
      })
      .addCase(getDetailBestimgApi.rejected, (state) => {
        state.loadingDetail = false;
        state.imageDetailData = null;
      });
  },
});

export const { setOpenBestDetailModal } = bestGallerySlice.actions;
export default bestGallerySlice.reducer;
