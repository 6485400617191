import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Checkbox from '../../../components/Checkbox';
import { Image } from '../../../types/ImageCards';
import { setChecked } from '../../galleryManager/galleryManagerSlice';

import { getImageSelectApi, setImageId, setOpenDetail } from './userGallerySlice';

// export const WithImageDeleteModal: React.FC<ImageCardProps> = ({ image }) => {
//   // const open = useAppSelector((state) => state.userGallery.isOpenDelete);
//   // return <div>{open ? <ImageDeleteModal imageId={image.id} /> : null}</div>;
// };

export interface ImageCardProps {
  image: Image;
  imageElement: HTMLImageElement;
  checkable?: boolean;
  ButtonPlacement?: React.ReactNode;
  isPrompt?: boolean;
}

export const ImageCard: React.FC<ImageCardProps> = ({ image, imageElement, checkable, isPrompt = true }) => {
  const dispatch = useAppDispatch();

  const checkedImages = useAppSelector((state) => state.galleryManager.checkedImages);

  const onClickImg = useCallback(
    (id: number) => {
      dispatch(setOpenDetail(true));
      dispatch(setImageId(id));
      if (checkable) {
        dispatch(setChecked(id));
      } else dispatch(getImageSelectApi(id));
    },
    [dispatch],
  );

  const [hovered, setHovered] = useState(false);
  const handleCheckboxChange = () => {
    if (checkable) {
      setHovered(true);
    }
  };

  return (
    <div
      className="my-2 last:mr-0 last:mb-0 relative "
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img
        className={`w-full object-fill rounded-xl cursor-pointer  `}
        alt={image.id.toString()}
        src={imageElement.src}
        onClick={() => onClickImg(image.id)}
      />
      {checkedImages.includes(image.id) && (
        <div className="absolute top-0 left-0 w-full h-full border-4 rounded-[10px] border-[#F95E21] pointer-events-none"></div>
      )}
      {checkable && (hovered || checkedImages.includes(image.id)) && (
        <div className="absolute top-[5%] left-[5%] bg-white rounded-[5px]">
          <Checkbox checked={checkedImages.includes(image.id)} onCheck={handleCheckboxChange} />
        </div>
      )}
      {isPrompt && <div className="my-2 font-normal truncate text-[#CCCCCC]">{image.prompt}</div>}
    </div>
  );
};
