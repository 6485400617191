import { useEffect } from 'react';
import { useAppDispatch, useAppSelector, useMasonryAdmin } from '../app/hooks';
import { initImageListAdmin, initRenderingImageList, setPage } from '../features/admin/adminForm/adminFormSlice';
import GroupUserCardList from '../features/etc/group/GroupUserCardList';
import MasonryGallery from '../features/gallery/userGallery/MasonryGallery';
import Banner from '../features/galleryManager/Banner';
import { initCheckedImages } from '../features/galleryManager/galleryManagerSlice';

const GalleryManagerPage = () => {
  const dispatch = useAppDispatch();

  const { imageListData, isFetching, userId, fetchMoreImage, initImageEvent, page } = useMasonryAdmin();

  const deleteImageId = useAppSelector((state) => state.imageDelete.deleteImageId);

  useEffect(() => {
    dispatch(initImageListAdmin());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
    };
  }, [deleteImageId]);

  useEffect(() => {
    return () => {
      dispatch(initCheckedImages());
    };
  }, []);

  useEffect(() => {
    dispatch(initRenderingImageList());
  }, [userId]);

  return (
    <div className="w-full h-full flex flex-col  px-[15px]">
      {/* Header */}
      <Banner />
      <div className="my-10"></div>
      {/* Content */}
      <div className="w-full h-full flex overflow-hidden  ">
        <div className="w-2/12 pr-9 overflow-y-scroll">
          <GroupUserCardList />
        </div>

        <div id="MasonryGallery" className="w-10/12 h-full ">
          <div key={deleteImageId} className="h-full flex flex-col ">
            <MasonryGallery
              key={userId}
              imageListData={imageListData}
              actionEvent={fetchMoreImage}
              initData={initImageEvent}
              initEvent={() => {}}
              isFetching={isFetching}
              page={page}
              checkable={true}
              isBtnDelete={false}
              isLoading={true}
              isPrompt={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryManagerPage;
