import React, { useEffect, useRef, useState } from 'react';
import { IconArrowDown } from './Icons';

interface SelectProps {
  options?: {
    id: number | null;
    value: string;
    type?: string;
  }[];
  defaultValue?: string;
  placehorder?: string;
  createMode?: boolean;
  isDisable?: boolean;
  onClick?: (id: number | null, value?: string) => void;
  onClickSearch?: (id: string) => void;
}

const Select = ({
  options,
  placehorder,
  defaultValue,
  createMode,
  isDisable,
  onClick,
  onClickSearch,
}: SelectProps) => {
  const bgEl = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState(false);
  const [currentValue, setCurrentValue] = useState('');

  const onClickActiveBox = () => {
    if (!isDisable) {
      setActive((prev) => !prev);
    }
  };

  const handleChangeSelectValue = (
    id: number | null,
    value: string,
    type?: string,
  ) => {
    setCurrentValue(value);
    if (onClick) {
      onClick(id, value);
    }
    if (onClickSearch && type) {
      onClickSearch(type);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setCurrentValue(defaultValue);
    }
  }, [defaultValue]);

  const printText = () => {
    if (placehorder) {
      if (currentValue) {
        if (createMode) {
          return <p>{currentValue}</p>;
        }
        return <p>선택회원을 {currentValue}으로 이동</p>;
      }
      return <p>{placehorder}</p>;
    }
    return null;
  };

  useEffect(() => {
    const { current } = bgEl;

    const clickHandler = (e: MouseEvent) => {
      if (active && !current?.contains(e.target as Node)) {
        setActive(false);
      }
    };

    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setActive(false);
      }
    };
    window.addEventListener('click', clickHandler);
    window.addEventListener('keydown', keydownHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
      window.removeEventListener('keydown', keydownHandler);
    };
  }, [active]);

  return (
    <div
      className={`flex justify-between items-center w-full h-[50px] border border-[#ccc] rounded-[5px] relative px-[10px] text-[18px] [&_p]:text-[#555] bg-white ${
        isDisable ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      onClick={onClickActiveBox}
      ref={bgEl}
    >
      {printText()}
      {active ? (
        <div
          className={`flex w-full max-h-[200px] overflow-y-scroll flex-col absolute top-[60px] left-0 border border-[#ccc] rounded-[5px] bg-white`}
        >
          {options?.map((element) => {
            return (
              <div
                key={element.id}
                className="flex items-center w-full h-[50px] min-h-[50px] px-[10px] border-b border-[#ccc] last:border-0 hover:bg-[#EBEBEA]"
                onClick={() =>
                  handleChangeSelectValue(
                    element.id,
                    element.value,
                    element.type,
                  )
                }
              >
                {createMode ? (
                  <p className="text-[#555]">{element.value}</p>
                ) : (
                  <p className="text-[#555]">
                    선택회원을{' '}
                    <span className="text-[#089572]">{element.value}</span>으로
                    이동
                  </p>
                )}
              </div>
            );
          })}
        </div>
      ) : null}
      <IconArrowDown />
    </div>
  );
};

export default Select;
