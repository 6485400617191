import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setIsMainPage } from '../features/etc/navigation/navigationSlice';
import Navigation from '../features/etc/navigation/Navigation';

const Templates = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isMainPage = useAppSelector((state) => state.navigation.isMainPage);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem('isAdmin') === 'true') {
      setIsAdmin(true);
    }
  }, []);

  useEffect(() => {
    if (pathname === '/') {
      dispatch(setIsMainPage(true));
    } else dispatch(setIsMainPage(false));
  }, [pathname]);

  return (
    <div
      id="gpt-template"
      className={`w-full h-screen flex flex-col min-w-[1366px] min-h-[850px] ${
        isAdmin
          ? `bg-template-black`
          : isMainPage
          ? 'bg-template-green'
          : 'bg-template-black'
      }`}
    >
      <Navigation />
      <div id="main-container" className="h-full px-[15px] pt-[80px] pb-[20px]">
        <Outlet />
      </div>
    </div>
  );
};

export default Templates;
