import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

interface ImageDeleteState {
  loadingDelete: boolean;
  isOpenDelete: boolean;
  isDeleteComplete: boolean;
  deleteImageId: number | undefined;
  deleteClickId: number[] | undefined;
}

const initialState: ImageDeleteState = {
  loadingDelete: false,
  isOpenDelete: false,
  isDeleteComplete: false,
  deleteImageId: undefined,
  deleteClickId: undefined,
};

export const deleteImageApi = createAsyncThunk(
  'image/delete',
  async (id: number[]) => {
    const response = await fetch(`${apiInfo.api_url}/image/delete`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    let data = await response.json();

    data.id = id;
    return data;
  },
);

const imageDeleteSlice = createSlice({
  name: 'imageDelete',
  initialState,
  reducers: {
    setDeleteImageId: (state, action) => {
      state.deleteImageId = action.payload;
    },
    setDeleteClickId: (state, action) => {
      state.deleteClickId = action.payload;
    },
    setOpenDelete: (state, action) => {
      state.isOpenDelete = action.payload;
    },
    setDeleteComplete: (state, action) => {
      state.isDeleteComplete = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // 이미지 삭제
      .addCase(deleteImageApi.pending, (state) => {
        state.loadingDelete = true;
        state.isDeleteComplete = false;
      })
      .addCase(deleteImageApi.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.deleteImageId = action.payload.id;
        state.deleteClickId = undefined;
        state.isDeleteComplete = true;
      })
      .addCase(deleteImageApi.rejected, (state) => {
        state.loadingDelete = false;
      });
  },
});

export const {
  setDeleteImageId,
  setDeleteClickId,
  setOpenDelete,
  setDeleteComplete,
} = imageDeleteSlice.actions;
export default imageDeleteSlice.reducer;
