import { createSlice } from '@reduxjs/toolkit';

interface UserManagerState {
  page: number;
  openCreateGroupModal: boolean;
  openRenameGroupModal: boolean;
  openDeleteGroupModal: boolean;
  openDeleteUserModal: boolean;
  deleteGroupNumber: number;
  searchType: string;
  searchKeyword: string;
}

const initialState: UserManagerState = {
  page: 1,
  openCreateGroupModal: false,
  openRenameGroupModal: false,
  openDeleteGroupModal: false,
  openDeleteUserModal: false,
  deleteGroupNumber: 0,
  searchType: 'name',
  searchKeyword: '',
};

const userManagerSlice = createSlice({
  name: 'UserManager',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },
    setSearchKeyword: (state, action) => {
      state.searchKeyword = action.payload;
    },
    setOpenCreateGroupModal: (state, action) => {
      state.openCreateGroupModal = action.payload;
    },
    setOpenRenameGroupModal: (state, action) => {
      state.openRenameGroupModal = action.payload;
    },
    setOpenDeleteGroupModal: (state, action) => {
      state.openDeleteGroupModal = action.payload;
    },
    setOpenDeleteUserModal: (state, action) => {
      state.openDeleteUserModal = action.payload;
    },
    setDeleteGroupNumber: (state, action) => {
      state.deleteGroupNumber = action.payload;
    },
    initUserManager: () => {
      return initialState;
    },
  },
});

export const {
  setPage,
  setSearchType,
  setSearchKeyword,
  setOpenCreateGroupModal,
  setOpenRenameGroupModal,
  setOpenDeleteGroupModal,
  setOpenDeleteUserModal,
  setDeleteGroupNumber,
  initUserManager,
} = userManagerSlice.actions;
export default userManagerSlice.reducer;
