import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  getImageCountApi,
  getProfileApi,
} from '../../etc/navigation/navigationSlice';
import { initCheckedImages } from '../../galleryManager/galleryManagerSlice';
import { setSlideShowModal } from '../../slideShow/banner/bannerSlice';
import PresentationModal from '../../slideShow/slideImage/PresentationModal';
import { updateSlideShowSettings } from '../../slideShow/slideImage/slideShowOptionSlice';

const Banner = () => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.navigation.profile);
  const slideshowIsOpen = useAppSelector(
    (state) => state.banner.slideShowModalIsOpen,
  );

  const image_count = useAppSelector(
    (state) => state.navigation.profile.image_count,
  );
  const deleteComplete = useAppSelector(
    (state) => state.imageDelete.isDeleteComplete,
  );

  useEffect(() => {
    if (deleteComplete) {
      dispatch(getProfileApi());
    }
  }, [deleteComplete]);

  useEffect(() => {
    dispatch(getImageCountApi());
  }, [image_count]);

  const presentationTest = () => {
    if (profile.agency === '테스트기관') {
      return (
        <div className="flex items-center ">
          <button
            className="mr-2 md:ml-7 w-36 md:w-40 h-12 bg-[#FFFF10] rounded-lg text-black text-lg font-medium hover:bg-[#FDFD6A]"
            onClick={activePresentation}
          >
            프레젠테이션
          </button>
          {slideshowIsOpen && <PresentationModal />}
        </div>
      );
    }

    function activePresentation() {
      dispatch(initCheckedImages());
      dispatch({
        type: 'socket/presentation',
      });
      dispatch(setSlideShowModal(true));
      dispatch(
        updateSlideShowSettings({
          infinite: false,
          fade: true,
          // speed: 500,
          autoplaySpeed: 4000,
        }),
      );
    }
  };

  return (
    <div
      id="galleryBanner"
      className="w-full h-[80px] rounded-[10px] bg-[#056d48] flex flex-none items-center justify-between "
    >
      <div className="ml-10">
        <p className="text-[22px] font-bold text-white">
          {profile.name} 갤러리
        </p>
      </div>

      <div className="w-auto flex items-center">
        {/* 아누타 시연용 코드 */}
        {presentationTest()}
        <div className="flex items-center space-x-1">
          <span className="text-[#FFFF10]">{profile.date_joined}</span>
          <span className="text-[#00FFA4] pr-2">가입 </span>
        </div>

        <div className="flex items-center pr-6 ">
          <span className="text-[#FFFF10]  font-bold mr-1">{image_count}</span>
          <span className="text-[#00FFA4]  ">그림 저장중</span>
        </div>
      </div>
    </div>
  );
};

export default Banner;
