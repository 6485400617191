import React, { useEffect, useRef } from 'react';
import Select from '../../../components/Select';
import Button from '../../../components/DefaultButton';

interface UserManagerModalProps {
  title: string;
  highlightTitle?: string;
  subTitle?: string;
  input?: boolean;
  placeholder?: string;
  deleteMode?: boolean;
  createUserMode?: boolean;
  options?: {
    id: number | null;
    value: string;
  }[];
  onClickGroup?: (id: number | null) => void;
  onChangeInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeName?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeBirth?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeId?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePassword?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickCancel?: () => void;
  onClickConfirm?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

const UserManagerModal = ({
  title,
  highlightTitle,
  subTitle,
  input,
  placeholder,
  deleteMode,
  createUserMode,
  options,
  onClickGroup,
  onChangeInput,
  onChangeName,
  onChangeBirth,
  onChangeId,
  onChangePassword,
  onClickCancel,
  onClickConfirm,
}: UserManagerModalProps) => {
  const bgEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = bgEl;

    const clickHandler = (e: MouseEvent) => {
      if (current === e.target) {
        if (onClickCancel) {
          onClickCancel();
        }
      }
    };

    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (onClickCancel) {
          onClickCancel();
        }
      }
    };
    window.addEventListener('click', clickHandler);
    window.addEventListener('keydown', keydownHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
      window.removeEventListener('keydown', keydownHandler);
    };
  }, []);

  return (
    <div
      className="fixed w-full h-full left-0 top-0 bg-[rgba(0,0,0,0.7)] z-50"
      ref={bgEl}
    >
      <div
        id="modal-layout"
        className="w-[600px] bg-white rounded-[20px] fixed left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] py-[45px] flex flex-col items-center"
      >
        <p id="modal-title" className="text-[20px] text-[#222] font-semibold">
          {highlightTitle ? (
            <span className="text-[#089572]">{highlightTitle}</span>
          ) : null}
          &nbsp;{title}
        </p>
        {subTitle ? (
          <p className="text-[18px] text-[#888]">{subTitle}</p>
        ) : null}
        {input ? (
          <input
            className="w-[400px] h-[50px] mt-[40px] border border-[#ccc] rounded-[5px] px-[10px] mr-[10px] bg-white text-[18px] text-[#555555] placeholder:text-[#aaaaaa] focus:outline-none"
            type="text"
            placeholder={placeholder}
            onChange={onChangeInput}
          />
        ) : null}
        {createUserMode ? (
          <div className="flex flex-col items-center mt-[40px]">
            <div className="flex items-center mb-[10px] last:mb-0">
              <p className="w-[65px] text-end text-[18px] text-[#555] font-medium">
                이름
              </p>
              <input
                className="w-[350px] h-[50px] border border-[#ccc] rounded-[5px] px-[10px] ml-[10px] bg-white text-[18px] text-[#555555] focus:outline-none"
                onChange={onChangeName}
              />
            </div>
            <div className="flex items-center mb-[10px] last:mb-0">
              <p className="w-[65px] text-end text-[18px] text-[#555] font-medium">
                생년월일
              </p>
              <input
                className="w-[350px] h-[50px] border border-[#ccc] rounded-[5px] px-[10px] ml-[10px] bg-white text-[18px] text-[#555555] focus:outline-none"
                onChange={onChangeBirth}
                placeholder="ex)2002-01-01"
              />
            </div>
            <div className="flex items-center mb-[10px] last:mb-0">
              <p className="w-[65px] text-end text-[18px] text-[#555] font-medium">
                아이디
              </p>
              <input
                className="w-[350px] h-[50px] border border-[#ccc] rounded-[5px] px-[10px] ml-[10px] bg-white text-[18px] text-[#555555] focus:outline-none"
                onChange={onChangeId}
              />
            </div>
            <div className="flex items-center mb-[10px] last:mb-0">
              <p className="w-[65px] text-end text-[18px] text-[#555] font-medium">
                비밀번호
              </p>
              <input
                className="w-[350px] h-[50px] border border-[#ccc] rounded-[5px] px-[10px] ml-[10px] bg-white text-[18px] text-[#555555] focus:outline-none"
                onChange={onChangePassword}
              />
            </div>
            <div className="flex items-center mb-[10px] last:mb-0">
              <p className="w-[65px] text-end text-[18px] text-[#555] font-medium">
                그룹
              </p>
              <div className="w-[350px] h-[50px] ml-[10px]">
                <Select
                  options={options}
                  placehorder="소속없음"
                  createMode
                  onClick={onClickGroup}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div id="button-wrap" className="flex mt-[30px]">
          <button
            className="w-[92px] h-[50px] rounded-[5px] border border-[#222] text-[18px] text-[#555] mr-[10px]"
            onClick={onClickCancel}
          >
            취소
          </button>
          <div className="w-[92px] h-[50px]">
            <Button
              text={deleteMode ? '삭제' : '확인'}
              onClick={onClickConfirm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagerModal;
