import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getProfileApi } from '../../etc/navigation/navigationSlice';
import {
  getUserInfoApi,
  setOpenImgChangeModal,
  setUserInfoApi,
} from './editMypageSlice';
import ProfileImageModal from './ProfileImageModal';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/DefaultButton';

const UserInfo = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openImgChangeModal, userInfo } = useAppSelector(
    (state) => state.editMypage,
  );
  const profileImage = useAppSelector(
    (state) => state.navigation.profile.profile_image,
  );

  const [userName, setUserName] = useState<string>();
  const [userBirth, setUserBirth] = useState<string>();

  const onChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const onChangeUserBirth = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserBirth(event.target.value);
  };

  const onClickSaveButton = () => {
    dispatch(
      setUserInfoApi({
        name: userName,
        birth: userBirth,
      }),
    );
  };

  useEffect(() => {
    dispatch(getUserInfoApi());
  }, [dispatch]);

  useEffect(() => {
    if (userInfo) {
      setUserName(userInfo.name);
      setUserBirth(userInfo.birth);
    }
  }, [userInfo]);

  return (
    <div className="w-full grow flex flex-col items-center">
      <div className="profile-image flex items-center mt-[30px] mb-[15px]">
        <img
          className="image-wrap w-[120px] h-[120px] object-cover rounded-full bg-zinc-400 border border-[#aaaaaa]"
          src={'data:image/png;base64,' + profileImage}
          alt=""
        />
        <div className="h-full flex flex-col justify-center pl-8">
          <p className="text-[24px] text-[#222222] font-bold">{userName}</p>
          <div
            className="w-fit cursor-pointer"
            onClick={() => dispatch(setOpenImgChangeModal(true))}
          >
            <p className="text-[16px] text-[#666666]">프로필 사진 바꾸기</p>
          </div>
        </div>
      </div>
      {openImgChangeModal ? <ProfileImageModal /> : null}
      <div className="basic-info mb-[15px]">
        <div className="name flex items-center mb-[10px]">
          <p className="text-[18px] text-[#555555] font-medium w-[65px] text-end">
            이름
          </p>
          <input
            className="h-[50px] w-[315px] ml-[10px] font-medium text-[18px] py-2 px-3 border border-[#cccccc] text-[#555555] rounded-[5px] focus:outline-none"
            type="text"
            value={userName ? userName : ''}
            onChange={onChangeUserName}
          />
        </div>
        <div className="birth flex items-center">
          <p className="text-[18px] text-[#555555] font-medium w-[65px] text-end">
            생년월일
          </p>
          <input
            className="h-[50px] w-[315px] ml-[10px] font-medium text-[18px] py-2 px-3 border border-[#cccccc] text-[#555555] rounded-[5px] focus:outline-none"
            type="text"
            value={userBirth ? userBirth : ''}
            onChange={onChangeUserBirth}
          />
        </div>
      </div>
      <div id="button-wrapper" className="flex">
        <button
          className="w-[92px] h-[50px] rounded-[5px] border border-[#222222] mr-[10px] text-[18px] text-[#555555]"
          onClick={() => navigate('/')}
        >
          취소
        </button>
        <div className="w-[92px] h-[50px]">
          <Button text="저장" onClick={onClickSaveButton} />
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
