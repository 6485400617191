import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useInfiniteScroll } from '../common/utils/useInfiniteScroll';
import { ImageCardPrev } from '../features/gallery/userGallery/ImageCardPrev';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getUserGalleryCountApi } from '../features/gallery/userGallery/userGallerySlice';

interface MasonryProps {
  apiLoading: boolean;
  pageType: 'bestGallery' | 'myGallery' | 'manageGallery';
  columnCount?: number;
  imageList: {
    id: number;
    image: string;
    prompt?: string;
    seed?: number;
    width: number;
    height: number;
  }[];
  callApi: (num: number) => void;
}

/**
 * Masonry Component
 * @param apiLoading - 로딩 값
 * @param isBestGallery - 로딩 값
 * @param columnCount - 로딩 값
 * @param imageList - 로딩 값
 * @param callApi - 로딩 값
 */
const Masonry = ({
  apiLoading,
  pageType,
  columnCount = 5,
  imageList,
  callApi,
}: MasonryProps) => {
  const dispatch = useAppDispatch();
  const deleteImageId = useAppSelector(
    (state) => state.userGallery.deleteImageId,
  );
  // state
  const [imageArr, setImageArr] = useState<MasonryProps['imageList']>([]);
  const [page, setPage] = useState(2);
  // masonry 레이아웃을 렌더링할 때 사용하는 상태
  const [isRendering, setIsRendering] = useState(false);

  //target에 접근하면 이미지 갤러리를 조회한다.
  const target = useInfiniteScroll(async () => {
    // 최근에 호출된 sequence가 마지막인지 확인. 마지막이 아닐때 callApi
    if (imageList.length === 10) {
      callApi(page);
      setPage((prev) => prev + 1);
    }
  }, [isRendering, apiLoading]);

  const [deleteRender, setDeleteRender] = useState(false);

  const initialColumnElements: JSX.Element[][] = Array.from(
    { length: columnCount },
    () => [],
  );

  const [masonryColumnElements, setMasonryColumnElements] = useState<
    Array<JSX.Element[]>
  >(initialColumnElements);

  const masonryColumnsRef = useRef<null[] | HTMLDivElement[]>([]);

  const [nextImage, setNextImage] = useState<number>(0);

  useEffect(() => {
    setImageArr(imageList);
  }, [imageList]);

  //이미지 데이터를 조회 후 masonry 레이아웃을 렌더링한다
  useLayoutEffect(() => {
    while (imageArr.length > 0 && imageArr.length > nextImage) {
      setIsRendering(true);
      const image = imageArr[nextImage];
      if (image) {
        const img = new Image();
        img.src = 'data:image/png;base64,' + image.image;

        //이미지 로드가 완료되면 이미지 카드를 렌더링한다.
        img.onload = (e) => {
          if (e.target) {
            const minIndex = getMinHeightMasonryColumn();
            const updatedColumn = [
              ...masonryColumnElements[minIndex],
              <ImageCardPrev
                image={image}
                imageElement={img}
                key={image.id}
                pageType={pageType}
              />,
            ];

            //컬럼의 높이를 비교하여 가장 높이가 낮은 컬럼에 이미지 카드를 추가한다.
            setMasonryColumnElements((prevElements) => {
              const newElements = [...prevElements];
              newElements[minIndex] = updatedColumn;
              return newElements;
            });
            //다음 이미지를 렌더링한다.
            setNextImage((prev) => (prev !== undefined ? prev + 1 : 0));
          }
        };
        break;
      }
    }

    //이미지 카드를 전부 렌더링 한 다음 초기화
    if (imageArr.length > 0 && imageArr.length === nextImage) {
      setImageArr([]);
      setNextImage(0);
      setIsRendering(false);
    }
  }, [imageArr, nextImage]);

  // image 삭제 로직. 아직 적용x
  useLayoutEffect(() => {
    if (pageType === 'myGallery' && deleteImageId) {
      dispatch(getUserGalleryCountApi());
    }
  }, [pageType, deleteImageId, dispatch]);

  // 이미지 카드를 삭제하면 해당 이미지 카드를 삭제한 뒤 렌더링한다.
  useLayoutEffect(() => {
    if (deleteImageId) {
      const { startColumn, startIndex } = getDeletePosition(
        masonryColumnElements,
        deleteImageId,
      );
      if (startColumn >= 0 || startIndex >= 0) {
        const previouesElements = copyPreviousElements(
          masonryColumnElements,
          startColumn,
          startIndex,
        );

        setMasonryColumnElements(previouesElements);

        const nextElements = copyNextElements(
          masonryColumnElements,
          startColumn,
          startIndex,
        );

        if (nextElements.length > 0 && nextElements[0] !== undefined) {
          setMasonryColumnElements((prevElements) => {
            const newElements = [...prevElements];

            // 가장 작은 컬럼의 인덱스를 구한다.
            const minIndex = newElements.reduce(
              (smallestIndex, currentArray, currentIndex) => {
                return currentArray.length < newElements[smallestIndex].length
                  ? currentIndex
                  : smallestIndex;
              },
              0, // 초기값은 첫 번째 배열의 인덱스로 설정
            );

            // 가장 작은 컬럼의 인덱스부터 순차적으로 nextElements를 넣어준다.
            for (let i = 0; i < nextElements.length; i++) {
              newElements[(minIndex + i) % newElements.length] = [
                ...newElements[(minIndex + i) % newElements.length],
                nextElements[i],
              ];
            }

            return newElements;
          });
        }
      }
    }
  }, [deleteImageId, masonryColumnElements]);

  // 현재 가장 작은 높이를 가진 열의 인덱스를 찾기
  const getMinHeightMasonryColumn = () => {
    const columnHeights = Array.from(
      { length: columnCount },
      (_, index) => masonryColumnsRef.current[index]?.offsetHeight,
    ).filter((height): height is number => typeof height === 'number');

    // undefined를 가진 인덱스를 제외하고 가장 작은 높이를 가진 열의 인덱스를 찾기
    const minIndex = columnHeights.reduce(
      (minIndex, height, currentIndex) =>
        height !== undefined && height < columnHeights[minIndex]
          ? currentIndex
          : minIndex,
      0,
    );
    return minIndex;
  };

  // delete로직
  //masonryColumnElements에서 deleteImageId의 위치를 찾아 index를 반환
  function getDeletePosition(
    masonryColumnElements: JSX.Element[][],
    deleteImageId: number,
  ) {
    let startColumn = -1;
    let startIndex = -1;

    for (let column = 0; column < masonryColumnElements.length; column++) {
      const element = masonryColumnElements[column];

      for (let index = 0; index < element.length; index++) {
        const imageCard = element[index];
        if (imageCard.key === deleteImageId.toString()) {
          startColumn = column;
          startIndex = index;
          break;
        }
      }
    }
    return { startColumn, startIndex };
  }

  //masonryColumnElements에서 columnIndex, startIndex 이전의 element들을 복사하여 반환
  function copyPreviousElements(
    newMasonryColumnElements: JSX.Element[][],
    startColumn: number,
    startIndex: number,
  ) {
    const copiedElements = initialColumnElements;

    for (let column = 0; column < columnCount; column++) {
      const element = newMasonryColumnElements[column];
      for (let index = 0; index <= startIndex; index++) {
        const imageCard = element[index];

        if (column >= startColumn && index === startIndex) {
          break;
        } else {
          // imageCard가 존재하는 경우에만 처리
          imageCard && copiedElements[column].push(imageCard);
        }
      }
    }
    return copiedElements;
  }

  //masonryColumnElements에서 columnIndex, startIndex 이후의 element들을 복사하여 반환
  function copyNextElements(
    masonryColumnElements: JSX.Element[][],
    startColumn: number,
    startRow: number,
  ) {
    const result = [];

    // masonryColumnElements를 행과 열을 바꾸어 렌더링된 형태로 변환

    const transposedMasonryElements = masonryColumnElements[0].map(
      (_, colIndex) =>
        masonryColumnElements
          .map((row) => row[colIndex])
          .filter((value) => value !== undefined),
    );

    // startIndex 이후의 element들을 복사하여 반환
    for (let i = startRow; i < transposedMasonryElements.length; i++) {
      // column의 범위: 시작 행에서는 startColumn 이후부터, 그 외의 행에서는 처음부터
      const startColIndex = i === startRow ? startColumn + 1 : 0;

      for (
        let j = startColIndex;
        j < transposedMasonryElements[i].length;
        j++
      ) {
        result.push(transposedMasonryElements[i][j]);
      }
    }

    return result;
  }

  return (
    <div id="masonry-component" className="w-full">
      <div className="w-full min-h-[800px] flex items-start">
        {masonryColumnElements.map((masonryColumn, index) => (
          <div
            key={index}
            className="w-full flex flex-col px-[20px] first:pl-0 last:pr-0"
            ref={(element) => {
              masonryColumnsRef.current[index] = element;
            }}
          >
            {masonryColumn}
          </div>
        ))}
      </div>
      <div ref={target} className="h-[100px]"></div>
    </div>
  );
};

export default Masonry;
