import React from 'react';
import ImageMaterial from './ImageMaterial';
import ImageSize from '../imageSize/ImageSize';
import ImagePaintingStyle from './ImagePaintingStyle';

interface StylesProps {
  title: string;
  mode: number;
}

const Styles = ({ title, mode }: StylesProps) => {
  const printBody = () => {
    switch (mode) {
      case 0:
        // 해상도 feature
        return <ImageSize />;
      case 1:
        // 재료 feature
        return <ImageMaterial />;
      case 2:
        // 화가 feature
        return <ImagePaintingStyle />;

      default:
        return null;
    }
  };

  return (
    <div className="w-full h-full bg-[#EFE7D6] rounded-[20px] p-[20px] flex flex-col">
      <div id="header" className="py-[20px] px-[14px] flex items-center">
        <div className="w-[14px] h-[14px] rounded-full bg-[#F95E21] mr-[10px]" />
        <p className="text-[22px] font-bold text-[#09654B] font-nps">{title}</p>
      </div>
      <div id="body" className="w-full h-[85%] grow">
        {printBody()}
      </div>
    </div>
  );
};

export default Styles;
