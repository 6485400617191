import { useCallback } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { Image } from '../../../types/ImageCards';
import { getImageSelectApi } from './userGallerySlice';

const ImageBtnDown: React.FC<{ image: Image }> = ({ image }) => {
  const dispatch = useAppDispatch();
  const onClickDownload = useCallback(
    (id: number) => {
      dispatch(getImageSelectApi(id)).then((res) => {
        const link = document.createElement('a');
        link.href = 'data:image/png;base64,' + res.payload.image;
        link.download = res.payload.seed + '.png';
        link.click();
      });
    },
    [dispatch],
  );
  return (
    <a
      className=" w-1/2 border-1 border-[#3B403E] rounded-md px-2 py-1 mr-2 text-[#AAAAAA] hover:bg-[#3B403E] hover:text-white "
      href={'data:image/png;base64,' + image.image}
      target=""
      onClick={() => onClickDownload(image.id)}
      rel="noopener noreferrer"
    >
      다운로드
    </a>
  );
};
export default ImageBtnDown;
