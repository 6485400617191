import { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useAppSelector } from '../../../app/hooks';

const Carousel = ({ slideShowImages, settings }) => {
  const sliderRef = useRef(null);

  const presentationImage = useAppSelector(
    (state) => state.slideImage.slideShowImages,
  );

  useEffect(() => {
    let pauseTimeout;
    let playTimeout;

    const handlePause = () => {
      pauseTimeout = setTimeout(() => {
        sliderRef.current?.slickNext();
        handlePlay();
      }, settings.autoplaySpeed);
    };

    const handlePlay = () => {
      playTimeout = setTimeout(() => {
        sliderRef.current?.slickPlay();
        handlePause();
      }, settings.speed);
    };

    handlePause();

    return () => {
      clearTimeout(pauseTimeout);
      clearTimeout(playTimeout);
    };
  }, [settings.autoplayTimeout, settings.speed]);

  const imageSlideSelector = (slideShowImages) => {
    return slideShowImages.map((image) => (
      <div
        key={`carouselImage_${image.id}`}
        className="overflow-hidden aspect-square flex items-center justify-center"
        style={{ margin: 'auto' }}
      >
        <img
          key={image.id}
          className="min-w-screen h-screen object-contain m-auto"
          src={`data:image/png;base64, ${image.image}`}
          alt={`Slide ${image.id}`}
        />
      </div>
    ));
  };

  return (
    <div key="carouselSlide" className="fixed w-full h-full bg-black">
      <Slider ref={sliderRef} {...settings}>
        {imageSlideSelector(
          slideShowImages !== undefined && slideShowImages.length !== 0
            ? slideShowImages
            : presentationImage,
        )}
      </Slider>
    </div>
  );
};

export default Carousel;
