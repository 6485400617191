import React from 'react';

interface StyleItemsProps {
  elName: string;
  elImg: string;
  description?: string;
  isNew: boolean;
  selectedItem: string;
  onClickItem: () => void;
}

const StyleItems = ({
  elName,
  elImg,
  description,
  isNew,
  selectedItem,
  onClickItem,
}: StyleItemsProps) => {
  return (
    <div
      className="flex flex-col cursor-pointer relative"
      onClick={onClickItem}
    >
      {isNew ? (
        <div className="absolute h-[20px] px-[5px] py-[3px] flex items-center justify-center bg-[#ff0] rounded-[10px] left-[10px] top-[-10px] shadow-[0px_3px_4px_0px_rgba(0,0,0,0.10)] z-10">
          <p className="font-bold text-[14px] text-[#222]">NEW</p>
        </div>
      ) : null}
      <div className="w-full h-[200px] flex relative mb-[10px]">
        <div
          id="border-wrapper"
          className={`absolute w-full h-full bg-inherit rounded-[10px] ${
            selectedItem === elName ? 'border-4 border-[#F95E21]' : null
          }`}
        />
        <img
          className="rounded-[10px] object-cover"
          src={'data:image/png;base64,' + elImg}
          alt=""
        />
      </div>
      <div className="w-full flex justify-center items-center">
        <p
          className={`font-bold text-[18px] text-[#333] ${
            selectedItem === elName ? 'text-[#F95E21]' : null
          }`}
        >
          {elName}
        </p>
      </div>
      {description ? (
        <p className="text-[#666] text-[15px] mt-[10px]">{description}</p>
      ) : null}
    </div>
  );
};

export default StyleItems;
