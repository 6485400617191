import { createSlice } from '@reduxjs/toolkit';

interface GroupUserCardState {
  toggleGroups: number[];
}

const initialState: GroupUserCardState = {
  toggleGroups: [],
};

const groupUserCardSlice = createSlice({
  name: 'groupUserCard',
  initialState,
  reducers: {
    addToggleGroups: (state, action) => {
      if (state.toggleGroups.includes(action.payload)) {
        state.toggleGroups = state.toggleGroups.filter(
          (group) => group !== action.payload,
        );
        return;
      }
      if (action.payload.length > 0) {
        state.toggleGroups.concat(state.toggleGroups, action.payload);
      } else {
        state.toggleGroups.push(action.payload);
      }
    },
    removeToggleGroups: (state, action) => {
      state.toggleGroups = state.toggleGroups.filter(
        (group) => group !== action.payload,
      );
    },
    initToggleGroups: (state) => {
      state.toggleGroups = [];
    },
  },
});
export const { addToggleGroups, removeToggleGroups, initToggleGroups } =
  groupUserCardSlice.actions;
export default groupUserCardSlice.reducer;
