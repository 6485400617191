import { ImageCardProps } from './ImageCard';

interface ButtonPlacementProps extends ImageCardProps {
  buttonPlacement: React.ReactNode;
}

const ImageCardWithBtn =
  (ImageCard: React.FC<ImageCardProps>) =>
  ({ buttonPlacement, ...props }: ButtonPlacementProps) => {
    return (
      <div>
        <ImageCard {...props} />
        <div className="w-full flex  items-center justify-center  text-center text-sm mb-4 ">
          {buttonPlacement}
        </div>
      </div>
    );
  };

export default ImageCardWithBtn;
