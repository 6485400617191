import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import usePagination from '../../../app/hooks/usePagination';
import Pagination from '../../../components/Pagination';

interface ImageViewPaginationProps {
  page: number;
  totalSequence: number;
  setPage: (page: number) => AnyAction; // setPage 추가
}

const ImageViewPagination: React.FC<ImageViewPaginationProps> = ({
  page: initialPage,
  totalSequence,
  setPage, // setPage 매개변수 추가
}) => {
  const dispatch = useDispatch();
  const dispatchedSetPage = (page: number) => dispatch(setPage(page));

  const { activePage, handlePageClick, handleNextPage, handlePrevPage } =
    usePagination(initialPage, totalSequence, dispatchedSetPage);

  return (
    <Pagination
      active={activePage}
      totalSequence={totalSequence}
      handlePageClick={handlePageClick}
      handleNextPage={handleNextPage}
      handlePrevPage={handlePrevPage}
    />
  );
};

export default ImageViewPagination;
