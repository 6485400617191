import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { changePasswordApi } from './editMypageSlice';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/DefaultButton';

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [currentPassword, setCurrentPassword] = useState('');
  const [changePassword, setChangePassword] = useState('');
  const [checkPassword, setCheckPassword] = useState('');
  const [isActive, setIsActive] = useState(false);

  const validationCheckPassword = (value: string) => {
    const pattern = /^[0-9a-zA-Z!@#$%^&*()_+{}\[\]:;<>,.?~\\/|-]{8,16}$/;

    if (pattern.test(value)) {
      return true;
    }
    return false;
  };

  const onClickSaveButton = () => {
    dispatch(
      changePasswordApi({
        exist_password: currentPassword,
        change_password: changePassword,
      }),
    );
  };

  useEffect(() => {
    if (
      validationCheckPassword(changePassword) &&
      changePassword === checkPassword &&
      currentPassword !== ''
    ) {
      setIsActive(true);
    } else setIsActive(false);
  }, [changePassword, checkPassword, currentPassword]);

  return (
    <div className="w-full flex flex-col items-center mt-[50px]">
      <div className="password-wrap flex flex-col items-center">
        <div className="id-form flex w-full mb-[10px] items-center">
          <p className="text-[18px] text-[#555555] font-medium text-end w-[100px] mr-[10px]">
            현재 비밀번호
          </p>
          <input
            className="h-[50px] w-[315px] text-[18px] placeholder:text-[16px] placeholder:font-normal text-[#555555] py-2 px-3 font-medium border-1 border-[#cccccc] rounded-[5px] focus:outline-none"
            type="password"
            placeholder="현재 비밀번호를 입력해주세요"
            onChange={(event) => {
              setCurrentPassword(event.target.value);
            }}
          />
        </div>
        <div className="id-form flex w-full mb-[50px] items-center relative">
          <p className="text-[18px] text-[#555555] font-medium text-end w-[100px] mr-[10px]">
            비밀번호
          </p>
          <input
            className="h-[50px] w-[315px] text-[18px] placeholder:text-[16px] placeholder:font-normal text-[#555555] py-2 px-3 font-medium border-1 border-[#cccccc] rounded-[5px] focus:outline-none"
            type="password"
            placeholder="변경할 8~16자의 비밀번호를 입력하세요"
            onChange={(event) => {
              setChangePassword(event.target.value);
            }}
          />
          <p className="absolute text-[#666666] text-[16px] left-[110px] top-[55px]">
            *8~16자의 비밀번호를 사용해주세요
          </p>
        </div>
        <div className="id-form flex w-full mb-[15px] items-center">
          <p className="text-[18px] text-[#555555] font-medium text-end w-[100px] mr-[10px]">
            비밀번호 확인
          </p>
          <input
            className="h-[50px] w-[315px] text-[18px] placeholder:text-[16px] placeholder:font-normal text-[#555555] py-2 px-3 font-medium border-1 border-[#cccccc] rounded-[5px] focus:outline-none"
            type="password"
            placeholder="비밀번호를 다시 한번 입력해주세요"
            onChange={(event) => {
              setCheckPassword(event.target.value);
            }}
          />
        </div>
        <div id="button-wrapper" className="flex">
          <button
            className="w-[92px] h-[50px] rounded-[5px] border border-[#222222] mr-[10px] text-[18px] text-[#555555]"
            onClick={() => navigate('/')}
          >
            취소
          </button>
          <div className="w-[92px] h-[50px]">
            <Button
              className={
                isActive
                  ? ''
                  : 'bg-neutral-400 cursor-not-allowed hover:bg-neutral-400'
              }
              onClick={onClickSaveButton}
              disabled={!isActive}
              text="저장"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
