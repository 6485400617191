import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import DefaultModal from '../DefaultModal';

const BtnCreateImage = () => {
  const dispatch = useAppDispatch();
  const prompt = useAppSelector((state) => state.prompt.prompt);
  const negative_prompt = useAppSelector(
    (state) => state.nagativePrompt.negativePrompt,
  );
  const width = useAppSelector((state) => state.imageSize.width);
  const height = useAppSelector((state) => state.imageSize.height);
  const style_id = useAppSelector((state) => state.imageStyles.styleId);
  const material_id = useAppSelector((state) => state.imageStyles.materialId);
  const sampler_name = useAppSelector(
    (state) => state.imageOptions.selectedSamler,
  );
  const cfg_scale = useAppSelector((state) => state.imageOptions.cfgScale);
  const steps = useAppSelector((state) => state.imageOptions.steps);
  const clip_skip = useAppSelector((state) => state.imageOptions.clipSkip);
  const seed = useAppSelector((state) => state.imageOptions.seed);

  // state
  const [openPromptWarning, setOpenPromptWarning] = useState(false);
  const [openResolutionModal, setOpenResolutionModal] = useState(false);

  const send = useCallback(() => {
    if (prompt === '') {
      setOpenPromptWarning(true);
      return false;
    }
    if (!width) {
      setOpenResolutionModal(true);
      return false;
    }
    dispatch({
      type: 'socket/sendMessage',
      payload: {
        prompt: prompt,
        negative_prompt: negative_prompt,
        width: width,
        height: height,
        style_id: style_id,
        material_id: material_id,
        sampler_name: sampler_name,
        cfg_scale: cfg_scale,
        steps: steps,
        clip_skip: clip_skip,
        seed: seed,
      },
    });
  }, [
    dispatch,
    prompt,
    negative_prompt,
    width,
    height,
    style_id,
    material_id,
    sampler_name,
    cfg_scale,
    steps,
    clip_skip,
    seed,
  ]);

  return (
    <>
      {openPromptWarning ? (
        <DefaultModal
          type="warning"
          text="그리고 싶은 내용을 입력해주세요."
          onClickCheck={() => setOpenPromptWarning(false)}
        />
      ) : null}
      {openResolutionModal ? (
        <DefaultModal
          type="warning"
          text="캔버스 사이즈를 선택해주세요."
          onClickCheck={() => setOpenResolutionModal(false)}
        />
      ) : null}
      <button
        className="w-full min-h-[70px] rounded-[10px] border-2 border-transparent shadow-[0px_4px_4px_0px_rgba(240,130,0,0.20),inset_0px_5px_5px_0px_#FFFF5C] bg-create-button bg-origin-border hover:bg-create-button-hover"
        onClick={send}
        style={{
          backgroundClip: 'content-box, border-box',
        }}
      >
        <p className="text-[#222] text-[20px] font-bold font-nps">
          그림 그리기
        </p>
      </button>
    </>
  );
};

export default BtnCreateImage;
