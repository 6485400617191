import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getBestGalleryApi } from './bestGallerySlice';
import Masonry from '../../../components/Masonry';
import DetailImage from '../userGallery/DetailImage';

const BestGallery = () => {
  const dispatch = useAppDispatch();
  const loadingImgList = useAppSelector(
    (state) => state.bestGallery.loadingImgList,
  );
  const loadingDetailModal = useAppSelector(
    (state) => state.bestGallery.loadingDetail,
  );
  const bestImageList = useAppSelector(
    (state) => state.bestGallery.imageListData,
  );
  const imageDetailData = useAppSelector(
    (state) => state.bestGallery.imageDetailData,
  );
  const openBestDetailModal = useAppSelector(
    (state) => state.bestGallery.openBestDetailModal,
  );

  useEffect(() => {
    dispatch(getBestGalleryApi(1));
  }, []);

  return (
    <div className="w-full h-full">
      {openBestDetailModal && imageDetailData ? (
        <DetailImage
          isLoading={loadingDetailModal}
          image={imageDetailData.image}
          width={imageDetailData.width}
          height={imageDetailData.height}
          prompt={imageDetailData.prompt}
          material={imageDetailData.material}
          style={imageDetailData.style}
        />
      ) : null}
      <Masonry
        pageType="bestGallery"
        imageList={bestImageList}
        callApi={(num) => dispatch(getBestGalleryApi(num))}
        apiLoading={loadingImgList}
      />
    </div>
  );
};

export default BestGallery;
