import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IconNavUser } from '../../../components/Icons';
import { GroupImage } from '../../../types/GroupImage';
import { UserImage } from '../../../types/UserImage';
import { setPage } from '../../admin/adminForm/adminFormSlice';

import { setSelectedGroup } from './groupCardSlice';
import UserCard from './UserCard';
import { setSelectedUser } from './userCardSlice';

const UserCardList = ({ group }: { group: GroupImage }) => {
  const dispatch = useAppDispatch();

  const selectedUserId = useAppSelector((state) => state.userCard.selectedUser);

  const clickEvent = (userId: number) => () => {
    if (selectedUserId === userId) {
      return;
    }
    dispatch(setPage(1));
    dispatch(setSelectedUser(userId));
    // dispatch(setSelectedGroup(group.group_id));
  };

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSelectedGroup(null));
    };
  }, [dispatch, group.group_id]);

  const selectedUser = useAppSelector((state) => state.userCard.selectedUser);
  return (
    <div className="flex flex-col h-full rounded-[10px] px-3">
      {group.users &&
        group.users.map((user: UserImage) => (
          <UserCard
            key={user.id}
            user={user}
            clickEvent={clickEvent(user.id)}
            Icon={() => (
              <IconNavUser
                className={
                  selectedUser === user.id ? `fill-[#F95E21]` : `fill-[#FFC800]`
                }
              />
            )}
          />
        ))}
    </div>
  );
};
export default UserCardList;
