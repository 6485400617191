import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserImage } from '../../../types/UserImage';

import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export interface UserCardState {
  isFetching: boolean;
  selectedUser?: number;

  users?: UserImage[];
}

const initialState: UserCardState = {
  isFetching: false,
};

export const getUserListApi = createAsyncThunk('user/list', async (groupId: number) => {
  const response = await fetch(`${apiInfo.api_url}/manager/accountlist?group_id=${groupId}`);
  let data = await response.json();
  return data.payload;
});

const userCardSlice = createSlice({
  name: 'userCard',
  initialState,
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // 유저 리스트 조회
      .addCase(getUserListApi.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getUserListApi.fulfilled, (state, action) => {
        state.isFetching = false;
        state.users = action.payload.user_list;
      })
      .addCase(getUserListApi.rejected, (state) => {
        state.isFetching = false;
      });
  },
});
// Endpoint를 사용하기 위한 hooks 생성
// Hook 네이밍 규칙(camelCase): use + endpoint명 + Query[query|mutation]

export const { setSelectedUser } = userCardSlice.actions;
export default userCardSlice.reducer;
