import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

interface ImageStylesState {
  styles: any[];
  // 화풍 id
  styleId: number | null;
  // 재료 id
  materialId: number | null;
  isLoadingMaterial: boolean;
  isLoadingStyle: boolean;
  materialList: {
    id: number;
    name: string;
    image: string;
    new_update: boolean;
  }[];
  styleList: {
    id: number;
    name: string;
    image: string;
    description: string;
    new_update: boolean;
  }[];
}

const initialState: ImageStylesState = {
  styles: [],
  styleId: null,
  materialId: null,
  isLoadingMaterial: false,
  isLoadingStyle: false,
  materialList: [],
  styleList: [],
};

// 재료 호출 API
export const getMaterialApi = createAsyncThunk('option/material', async () => {
  const response = await fetch(`${apiInfo.api_url}/option/material`);
  let data = await response.json();
  return data.payload;
});

// 화풍 호출 API
export const getStyleApi = createAsyncThunk('option/style', async () => {
  const response = await fetch(`${apiInfo.api_url}/option/style`);
  let data = await response.json();
  return data.payload;
});

export const imageStylesSlice = createSlice({
  name: 'ImageStyles',
  initialState,
  reducers: {
    setStyleId: (state, action) => {
      state.styleId = action.payload;
    },
    setMaterialId: (state, action) => {
      state.materialId = action.payload;
    },
    initImageStyles: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // material
      .addCase(getMaterialApi.pending, (state) => {
        state.isLoadingMaterial = true;
        state.materialList = [];
      })
      .addCase(getMaterialApi.fulfilled, (state, action) => {
        state.isLoadingMaterial = false;
        state.materialList = action.payload;
      })
      .addCase(getMaterialApi.rejected, (state) => {
        state.isLoadingMaterial = false;
        state.materialList = [];
      })

      // style
      .addCase(getStyleApi.pending, (state) => {
        state.isLoadingStyle = true;
        state.styleList = [];
      })
      .addCase(getStyleApi.fulfilled, (state, action) => {
        state.isLoadingStyle = false;
        state.styleList = action.payload;
      })
      .addCase(getStyleApi.rejected, (state) => {
        state.isLoadingStyle = false;
        state.styleList = [];
      });
  },
});

export const { setStyleId, setMaterialId, initImageStyles } =
  imageStylesSlice.actions;

export default imageStylesSlice.reducer;
