import React from 'react';

interface ButtonProps {
  className?: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  text: string;
  basic?: boolean;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button = ({
  className,
  buttonRef,
  text,
  basic,
  disabled,
  type,
  onClick,
}: ButtonProps) => {
  return (
    <button
      ref={buttonRef}
      className={`w-full h-full rounded-[5px] text-[18px] font-semibold ${className} ${
        basic
          ? 'border border-[#222] text-[#555]'
          : 'bg-[#089572] hover:bg-[#00C292] text-white'
      }`}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
