import BestGallery from '../features/gallery/bestGallery/BestGallery';

const BestGalleryPage = () => {
  return (
    <div className="w-full h-full flex flex-col overflow-hidden px-[15px]">
      <div id="banner" className="w-full h-[80px] rounded-[10px] bg-[#056d48] flex flex-none  items-center px-[40px]">
        <p className="text-[22px] text-[#e6f0ed] font-bold">베스트 갤러리</p>
      </div>
      <div id="body" className="w-full h-full mt-[40px] overflow-auto">
        <BestGallery />
      </div>
    </div>
  );
};

export default BestGalleryPage;
