import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IconViewOption } from '../../../components/Icons';

import { setPage, setViewCount } from './imageViewOptionSlice';

const ImageViewCountBox = () => {
  const dispatch = useAppDispatch();

  const viewCount = useAppSelector((state) => state.imageViewOption.viewCount);

  const changeViewCount = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    dispatch(setViewCount(event.currentTarget.value));
    dispatch(setPage(1));
  };

  return (
    <div className=" space-x-2 flex justify-center items-center">
      <div className="flex flex-row space-x-3 mr-10 justify-center items-center   ">
        <IconViewOption className="fill-[#FFC800]" />
        <select
          className="rounded-md h-8 w-16 text-center"
          onChange={changeViewCount}
          value={viewCount}
        >
          <option value="20">20</option>
          <option value="40">40</option>
          <option value="60">60</option>
          <option value="80">80</option>
          <option value="100">100</option>
        </select>
      </div>
    </div>
  );
};

export default ImageViewCountBox;
