import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

interface EditMypageState {
  openImgChangeModal: boolean;
  openModal: boolean;
  loadingUpload: boolean;
  loadingUpdate: boolean;
  modalMessage: string;
  modalStatus: 'warning' | 'success';
  resultModalOpen: boolean;
  changedProfile: boolean;
  userInfo: {
    name: string;
    phone_number: string;
    birth: string;
  } | null;
}

const initialState: EditMypageState = {
  openImgChangeModal: false,
  openModal: false,
  loadingUpload: false,
  loadingUpdate: false,
  modalMessage: '',
  modalStatus: 'warning',
  resultModalOpen: false,
  changedProfile: false,
  userInfo: null,
};

// 프로필 이미지 업로드
export const uploadProfileApi = createAsyncThunk(
  'editmypage/uploadProfile',
  async (image: string) => {
    const response = await fetch(`${apiInfo.api_url}/account/profile`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        profile_image: image,
      }),
    });
    let data = await response.json();
    return data.payload;
  },
);

// 프로필 이미지 삭제
export const deleteProfileApi = createAsyncThunk(
  'editmypage/deleteProfile',
  async () => {
    const response = await fetch(`${apiInfo.api_url}/account/profile`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    let data = await response.json();
    return data.payload;
  },
);

// 회원정보 조회
export const getUserInfoApi = createAsyncThunk(
  'edit/mypage/userInfo',
  async () => {
    const response = await fetch(`${apiInfo.api_url}/account/information`);
    let data = await response.json();
    return data.payload;
  },
);

// 회원정보 수정
export const setUserInfoApi = createAsyncThunk(
  'editmypage/setUserInfo',
  async (info: { name?: string; birth?: string; phone_number?: string }) => {
    const response = await fetch(`${apiInfo.api_url}/account/information`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: info.name,
        birth: info.birth,
        phone_number: info.phone_number,
      }),
    });
    let data = await response.json();
    return data;
  },
);

// 비밀번호 변경
export const changePasswordApi = createAsyncThunk(
  'editmypage/changePassword',
  async (info: { exist_password: string; change_password: string }) => {
    const response = await fetch(`${apiInfo.api_url}/account/changepassword`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        exist_password: info.exist_password,
        change_password: info.change_password,
      }),
    });
    let data = await response.json();
    return data;
  },
);

const editMypageSlice = createSlice({
  name: 'EditMypage',
  initialState,
  reducers: {
    setOpenImgChangeModal: (state, action) => {
      state.openImgChangeModal = action.payload;
    },
    closeResultModal: (state) => {
      state.resultModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadProfileApi.pending, (state) => {
        state.loadingUpload = true;
        state.changedProfile = false;
      })
      .addCase(uploadProfileApi.fulfilled, (state) => {
        state.loadingUpload = false;
        state.changedProfile = true;
        state.openImgChangeModal = false;
      })
      .addCase(uploadProfileApi.rejected, (state) => {
        state.loadingUpload = false;
        state.changedProfile = false;
      })

      // 프로필 이미지 삭제
      .addCase(deleteProfileApi.pending, (state) => {
        state.changedProfile = false;
      })
      .addCase(deleteProfileApi.fulfilled, (state) => {
        state.openImgChangeModal = false;
        state.changedProfile = true;
      })
      .addCase(deleteProfileApi.rejected, (state) => {
        state.changedProfile = false;
      })

      // 회원정보 조회
      .addCase(getUserInfoApi.pending, (state) => {
        state.userInfo = null;
      })
      .addCase(getUserInfoApi.fulfilled, (state, action) => {
        state.userInfo = action.payload;
      })
      .addCase(getUserInfoApi.rejected, (state) => {
        state.userInfo = null;
      })

      // 회원정보 수정
      .addCase(setUserInfoApi.pending, (state) => {
        state.loadingUpdate = true;
        state.resultModalOpen = false;
        state.changedProfile = false;
      })
      .addCase(setUserInfoApi.fulfilled, (state, action) => {
        state.loadingUpdate = false;
        state.resultModalOpen = true;
        state.changedProfile = true;
        if (action.payload.code === '200') {
          state.modalMessage = '회원정보가 변경되었습니다.';
          state.modalStatus = 'success';
        } else {
          state.modalMessage = action.payload.message;
          state.modalStatus = 'warning';
        }
      })
      .addCase(setUserInfoApi.rejected, (state) => {
        state.loadingUpdate = false;
        state.resultModalOpen = false;
        state.changedProfile = false;
      })

      // 회원정보 수정
      .addCase(changePasswordApi.pending, (state) => {
        state.resultModalOpen = false;
      })
      .addCase(changePasswordApi.fulfilled, (state, action) => {
        state.resultModalOpen = true;
        if (action.payload.code === '200') {
          state.modalMessage = '회원정보가 변경되었습니다.';
          state.modalStatus = 'success';
        } else {
          state.modalMessage = action.payload.message;
          state.modalStatus = 'warning';
        }
      })
      .addCase(changePasswordApi.rejected, (state) => {
        state.loadingUpdate = false;
        state.resultModalOpen = false;
      });
  },
});

export const { setOpenImgChangeModal, closeResultModal } =
  editMypageSlice.actions;
export default editMypageSlice.reducer;
