import React from 'react';
import { ReactComponent as IconCheckSVG } from '../assets/icons/icon-check.svg';

interface CheckboxProps {
  checked?: boolean;
  onCheck?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

/**
 * Checkbox Component 입니다.
 * @param checked - 체크 여부
 * @param onCheck - handler 함수
 */
const Checkbox = ({ checked, onCheck }: CheckboxProps) => {
  return (
    <button
      className={`w-[24px] h-[24px] rounded-[5px] flex items-center justify-center cursor-pointer ${
        checked ? 'bg-[#0000ff]' : 'border border-[#aaa]'
      }`}
      onClick={onCheck}
    >
      <IconCheckSVG />
    </button>
  );
};

export default Checkbox;
