import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ImageState, initImageState } from '../../../types/image';
import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

interface UserGalleryState {
  isFetching: boolean;
  loadingImgList: boolean;
  isOpenShow: boolean;
  isOpenDetail: boolean;
  loadingDelete: boolean;
  loadingDetail: boolean;
  page: number;
  clickedImageId: number;
  imageListData: {
    id: number;
    image: string;
    prompt: string;
    seed: number;
    width: number;
    height: number;
  }[];
  imageListCount: number;
  imageSelectData: ImageState;
  isDelete: boolean;
  deleteImageId: number | undefined;
}

const initialState: UserGalleryState = {
  isFetching: false,
  loadingImgList: false,
  isOpenShow: false,
  isOpenDetail: false,
  loadingDelete: false,
  loadingDetail: false,
  page: 1,
  clickedImageId: 0,
  imageListData: [],
  imageListCount: 0,
  imageSelectData: { ...initImageState },
  isDelete: false,
  deleteImageId: undefined,
};

export const getUserGalleryApi = createAsyncThunk(
  'image/list',
  async (page: number, userGallerySlice) => {
    const response = await fetch(
      `${apiInfo.api_url}/image/list?sequence=${page}`,
    );
    let data = await response.json();

    if (data.code === '202') {
      //응답코드 202는 조회할 이미지가 없는 경우
      userGallerySlice.dispatch(setPage(0));
    }

    return data.payload;
  },
);
export const getUserGalleryCountApi = createAsyncThunk(
  'image/count',
  async () => {
    const response = await fetch(`${apiInfo.api_url}/image/count`);
    let data = await response.json();

    return data.payload;
  },
);

export const getImageSelectApi = createAsyncThunk(
  'image/select',
  async (id: number) => {
    const response = await fetch(`${apiInfo.api_url}/image/select?id=${id}`);
    let data = await response.json();
    return data.payload;
  },
);

export const deleteImageApi = createAsyncThunk(
  'image/delete',
  async (id: number[]) => {
    const response = await fetch(`${apiInfo.api_url}/image/delete`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    let data = await response.json();

    data.id = id;
    return data;
  },
);

const userGallerySlice = createSlice({
  name: 'UserGallery',
  initialState,
  reducers: {
    initImageList: (state) => {
      state.imageListData = [];

      state.isFetching = false;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setOpenShow: (state, action) => {
      state.isOpenShow = action.payload;
    },
    setOpenDetail: (state, action) => {
      state.isOpenDetail = action.payload;
    },
    setImageId: (state, action) => {
      state.clickedImageId = action.payload;
    },
    setFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setDeleteImageId: (state, action) => {
      state.deleteImageId = action.payload;
    },
    setImageListCount: (state, action) => {
      state.imageListCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // 갤러리 조회
      .addCase(getUserGalleryApi.pending, (state) => {
        state.isFetching = true;
        state.loadingImgList = true;
        state.imageListData = [];
      })
      .addCase(getUserGalleryApi.fulfilled, (state, action) => {
        state.isFetching = false;
        state.loadingImgList = false;

        if (action.payload.length > 0) {
          state.imageListData = action.payload;

          state.page += 1;
        } else {
          state.isFetching = false;
          state.page = 0;
        }
      })
      .addCase(getUserGalleryApi.rejected, (state, action) => {
        state.isFetching = false;
        state.loadingImgList = false;
        state.imageListData = [];
        state.page = 0;
      })

      // 갤러리 카운트 조회
      .addCase(getUserGalleryCountApi.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getUserGalleryCountApi.fulfilled, (state, action) => {
        state.isFetching = false;
        state.imageListCount = action.payload;
      })
      .addCase(getUserGalleryCountApi.rejected, (state) => {
        state.isFetching = false;
        state.imageListCount = 0;
      })

      // 이미지 상세 데이터
      .addCase(getImageSelectApi.pending, (state) => {
        state.loadingDetail = true;
        state.imageSelectData = { ...initImageState };
      })
      .addCase(getImageSelectApi.fulfilled, (state, action) => {
        state.loadingDetail = false;
        state.imageSelectData = action.payload;
      })
      .addCase(getImageSelectApi.rejected, (state, action) => {
        state.loadingDetail = false;
        state.imageSelectData = { ...initImageState };
      })

      // 이미지 삭제
      .addCase(deleteImageApi.pending, (state) => {
        state.loadingDelete = true;
        state.isDelete = false;
      })
      .addCase(deleteImageApi.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.isDelete = true;
        state.isOpenDetail = false;
        state.deleteImageId = action.payload.id;
      })
      .addCase(deleteImageApi.rejected, (state) => {
        state.loadingDelete = false;
        state.isDelete = false;
      });
  },
});
export const {
  initImageList,
  setPage,
  setOpenShow,
  setOpenDetail,
  setImageId,
  setFetching,
  setImageListCount,
  setDeleteImageId,
} = userGallerySlice.actions;
export default userGallerySlice.reducer;
