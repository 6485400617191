import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import { setSlideOptionsModal } from '../banner/bannerSlice';

import Button from '../../../components/DefaultButton';
import { updateSlideShowSettings } from './slideShowOptionSlice';

const SlideOptionModal = () => {
  const dispatch = useDispatch();

  const autoplaySpeed = useAppSelector(
    (state) => state.slideOptionSettings.autoplaySpeed,
  );
  const [transitionSpeed, setTransitionSpeed] = useState<number>(autoplaySpeed);
  const speed = useAppSelector((state) => state.slideOptionSettings.speed);
  const [motionSpeed, setMotionSpeed] = useState<number>(speed);
  const vertical = useAppSelector(
    (state) => state.slideOptionSettings.vertical,
  );
  const fade = useAppSelector((state) => state.slideOptionSettings.fade);
  // 슬라이드 효과에 따라 선택된 값을 저장
  const [slideEffect, setSlideEffect] = useState<number>(0);

  useEffect(() => {
    if (vertical) {
      setSlideEffect(1);
    } else if (fade) {
      setSlideEffect(2);
    } else {
      setSlideEffect(0);
    }
  }, [vertical, fade]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // 모달에서 설정한 값을 Redux 슬라이스에 업데이트
    dispatch(
      updateSlideShowSettings({
        speed: motionSpeed,
        autoplaySpeed: transitionSpeed,
        vertical: slideEffect === 1,
        fade: slideEffect === 2,
      }),
    );

    dispatch(setSlideOptionsModal(false));
  };

  const clickCancerModal = () => {
    dispatch(setSlideOptionsModal(false));
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <div
        className="bg-white p-8 rounded-2xl flex flex-col items-center"
        style={{ width: '600px', height: '400px' }}
      >
        <h2 className="text-xl font-semibold mb-14">슬라이드 쇼 옵션</h2>
        <form onSubmit={handleSubmit} className="flex flex-col gap-y-3">
          <div className="flex flex-row items-center">
            <label
              className="block text-lg font-medium mb-1 w-2/4"
              htmlFor="transitionSpeed"
            >
              슬라이드 전환 속도
            </label>
            <input
              type="number"
              id="transitionSpeed"
              min={0}
              value={transitionSpeed / 1000}
              onChange={(e) =>
                setTransitionSpeed(parseInt(e.target.value) * 1000)
              }
              className="border border-gray-300 rounded px-3 py-2 w-1/4 mr-2"
            />
            초
          </div>
          <div className="flex flex-row items-center">
            <label
              className="block text-lg font-medium mb-1 w-2/4"
              htmlFor="motionSpeed"
            >
              슬라이드 모션 속도
            </label>
            <input
              type="number"
              id="motionSpeed"
              min={0}
              value={motionSpeed / 1000}
              onChange={(e) => setMotionSpeed(parseInt(e.target.value) * 1000)}
              className="border border-gray-300 rounded px-3 py-2 w-1/4 mr-2"
            />
            초
          </div>
          <div className="flex flex-row items-center">
            <label
              className="block text-lg font-medium mb-1 w-2/4"
              htmlFor="slideEffect"
            >
              슬라이드 효과
            </label>
            <select
              id="slideEffect"
              value={slideEffect}
              onChange={(e) => setSlideEffect(parseInt(e.target.value))}
              className="border border-gray-300 rounded px-2 py-2 w-2/4"
            >
              <option value={0}>좌우 슬라이드</option>
              <option value={1}>상하 슬라이드</option>
              <option value={2}>페이드 슬라이드</option>
            </select>
          </div>
          <div className="flex justify-center items-center mt-3">
            <button
              type="button"
              className="w-[92px] h-[50px] rounded-[5px] border border-[#222] text-[18px] text-[#555] mr-[10px]"
              onClick={clickCancerModal}
            >
              취소
            </button>
            <div className="w-[92px] h-[50px]">
              <Button text="확인" type="submit" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SlideOptionModal;
