import { useAppSelector } from '../../../app/hooks';
import ImageViewCountBox from './ImageViewCountBox';
import { setPage } from './imageViewOptionSlice';
import ImageViewPagination from './ImageViewPagination';

const ImageViewOption = () => {
  const page = useAppSelector((state) => state.imageViewOption.page);

  const galleryTotalSequence = useAppSelector(
    (state) => state.galleryManager.imageCards.totalSequence,
  );
  return (
    <div className="w-full my-4  flex flex-row justify-between ">
      <div className="w-2/12 mr-6"></div>

      <div className="w-10/12">
        <div className="flex flex-row justify-between">
          <ImageViewPagination
            page={page}
            totalSequence={galleryTotalSequence}
            setPage={setPage}
          />
          <ImageViewCountBox />
        </div>
      </div>
    </div>
  );
};
export default ImageViewOption;
