import { useEffect, useRef, useState } from 'react';
import { ReactComponent as IconResolutionSVG } from '../assets/icons/icon-resolution.svg';
import { ReactComponent as IconMaterialSVG } from '../assets/icons/icon-material.svg';
import { ReactComponent as IconStyleSVG } from '../assets/icons/icon-style.svg';
import { ReactComponent as IconStyleArrowSVG } from '../assets/icons/icon-style-arrow.svg';
import BtnCreateImage from '../features/image/btnCreateImage/BtnCreateImage';
import ImageGallery from '../features/image/imageGallery/ImageGallery';
import Prompt from '../features/image/prompt/Prompt';
import Styles from '../features/image/imageStyle/Styles';
import SelectedOptions from '../features/image/selectedOptions/SelectedOptions';
import { useAppDispatch } from '../app/hooks';
import {
  getMaterialApi,
  getStyleApi,
} from '../features/image/imageStyle/imageStylesSlice';

const CreateImage = () => {
  const dispatch = useAppDispatch();

  const resolutionIconEl = useRef<HTMLDivElement>(null);
  const styleIconEl = useRef<HTMLDivElement>(null);
  const painterIconEl = useRef<HTMLDivElement>(null);

  const [selectMode, setSelectMode] = useState(0);
  const [styleHeader, setStyleHeader] = useState('');
  // const [trianglePosition, setTrianglePosition] = useState({
  //   x: 0,
  //   y: 0,
  // });

  useEffect(() => {
    dispatch(getMaterialApi());
    dispatch(getStyleApi());
  }, []);

  useEffect(() => {
    switch (selectMode) {
      case 0:
        setStyleHeader('캔버스 사이즈 선택하기');
        break;
      case 1:
        setStyleHeader('재료 선택하기');
        break;
      case 2:
        setStyleHeader('유명화가 따라하기');
        break;

      default:
        break;
    }
  }, [selectMode]);

  const onClickStyleIcon = (
    ref: React.RefObject<HTMLDivElement>,
    mode: number,
  ) => {
    setSelectMode(mode);
    // const { current } = ref;
    // if (current) {
    //   // const XAxis = current.getBoundingClientRect().right;
    //   const XAxis = current.offsetLeft + current.offsetWidth;
    //   const yAxis =
    //     // current.getBoundingClientRect().top + current.clientHeight / 2;
    //     current.offsetTop + current.offsetHeight / 2;
    //   console.log(XAxis, yAxis);
    //   setTrianglePosition({ x: XAxis, y: yAxis });
    // }
  };

  return (
    <div className="w-full h-full flex">
      <div className="h-full max-w-[50%] basis-1/2 px-[15px]">
        <div id="prompt-wrapper" className="w-full h-[15%]">
          <Prompt />
        </div>
        <div id="select-wrapper" className="flex w-full h-[85%] pt-[20px]">
          <div
            id="icon-wrapper"
            className="w-[16%] h-full flex flex-col items-center mr-[40px] relative"
          >
            <div
              className="w-full flex justify-center items-center mt-[140px] relative"
              ref={resolutionIconEl}
            >
              <IconResolutionSVG
                className="cursor-pointer"
                onClick={() => onClickStyleIcon(resolutionIconEl, 0)}
              />
              {/* 화살표 Div */}
              <IconStyleArrowSVG
                className={`absolute right-[-50px] ${
                  selectMode === 0 ? null : 'hidden'
                }`}
              />
            </div>
            <div
              className="w-full flex justify-center items-center mt-[50px] relative"
              ref={styleIconEl}
            >
              <IconMaterialSVG
                className="cursor-pointer"
                onClick={() => onClickStyleIcon(styleIconEl, 1)}
              />
              {/* 화살표 Div */}
              <IconStyleArrowSVG
                className={`absolute right-[-50px] ${
                  selectMode === 1 ? null : 'hidden'
                }`}
              />
            </div>
            <div
              className="w-full flex justify-center items-center mt-[50px] relative"
              ref={painterIconEl}
            >
              <IconStyleSVG
                className="cursor-pointer"
                onClick={() => onClickStyleIcon(painterIconEl, 2)}
              />
              {/* 화살표 Div */}
              <IconStyleArrowSVG
                className={`absolute right-[-50px] ${
                  selectMode === 2 ? null : 'hidden'
                }`}
              />
            </div>
            {/* <div
              className={`absolute w-[50px] h-[50px] bg-black top-[179.5px] left-[110px] translate-y-[-50%]`}
            /> */}
          </div>
          <div id="style-wrapper" className="grow w-[84%] h-full z-10">
            <Styles title={styleHeader} mode={selectMode} />
          </div>
        </div>
      </div>
      <div className="h-full max-w-[50%] basis-1/2 px-[15px]">
        <div className="flex flex-col w-full h-full rounded-[20px] bg-[#FFC800] p-[20px] overflow-y-scroll">
          {/* 가로형, 유화, 풍경화 가 들어가는 레이아웃 */}
          <div className="flex w-full p-[20px] mb-[10px]">
            <SelectedOptions />
          </div>
          <BtnCreateImage />
          <ImageGallery />
        </div>
      </div>
    </div>
  );
};

export default CreateImage;
