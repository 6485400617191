import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { GroupImage } from '../../../types/GroupImage';
import { setPage } from '../imageViewOption/imageViewOptionSlice';

import { setSelectedGroup } from './groupCardSlice';

export interface GroupCardProps {
  group: GroupImage;
  clickEvent?: () => void; // 수정: clickEvent의 인수 없앰
}

const GroupCard: React.FC<GroupCardProps> = ({ group, clickEvent }) => {
  const dispatch = useAppDispatch();

  const selectedGroup = useAppSelector(
    (state) => state.groupCard.selectedGroup,
  );

  const clickGroupItem = (groupId: number) => () => {
    dispatch(setSelectedGroup(groupId));
    dispatch(setPage(1));
  };

  const handleClick = () => {
    if (clickEvent) {
      clickEvent();
    } else {
      clickGroupItem(group.group_id)();
    }
  };

  return (
    <div className="" key={group.group_id}>
      <button
        className="w-full h-12 rounded-[5px] px-3 font-semibold bg-[#FFFFFF] text-lg"
        onClick={handleClick}
      >
        <div className="w-full flex flex-row justify-between items-center group">
          <div
            className={`${
              group.group_id === selectedGroup && 'text-[#F95E21]'
            }`}
          >
            {group.name}
          </div>
          <div className="text-[#F95E21]">{group.image_count}</div>
        </div>
      </button>
    </div>
  );
};

export default GroupCard;
