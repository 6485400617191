import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

interface ImageGalleryState {
  imageList: imageState;
  loadingSocket: boolean;
  progress: number;
  btnDisabled: boolean;
  createdImageInfo: imageInfo | null;
  successImageSave: boolean;
  canvasSize: {
    width: number;
    height: number;
  };
}

interface imageInfo {
  seed: number;
  prompt: string;
  negative_prompt: string;
  image: string[];
  config: imageConfig;
}

interface imageConfig {
  prompt: string;
  negative_prompt: string;
  width: number;
  height: number;
  sampler_name: string;
  steps: number;
  restore_faces: boolean;
  cfg_scale: number;
  seed: number;
  clip_skip: number;
  style_id: number | null;
  material_id: number | null;
  task_id: string;
  option: imageOptions;
  model: string;
  infotexts: string;
}

interface imageOptions {
  material?: {
    id: number;
    name: string;
    prompt: string;
  };
  style?: {
    id: number;
    name: string;
    prompt: string;
  };
}

interface imageState {
  image: string;
  prompt: string;
  seed: number;
}

const initialState: ImageGalleryState = {
  imageList: {
    image: '',
    prompt: '',
    seed: -1,
  },
  progress: 0,
  loadingSocket: false,
  btnDisabled: false,
  createdImageInfo: null,
  successImageSave: false,
  canvasSize: {
    width: 0,
    height: 0,
  },
};

// 이미지 저장 API
export const saveImageApi = createAsyncThunk(
  'imageGallery/saveImage',
  async (imageInfo: imageInfo) => {
    const response = await fetch(`${apiInfo.api_url}/image/save`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        seed: imageInfo.seed,
        images: imageInfo.image,
        prompt: imageInfo.prompt,
        negative_prompt: imageInfo.negative_prompt,
        config: imageInfo.config,
      }),
    });
    let data = await response.json();
    return data;
  },
);

export const imageGallerySlice = createSlice({
  name: 'imageGallery',
  initialState,
  reducers: {
    setImageList: (state, action) => {
      const base64img = 'data:image/png;base64,' + action.payload.image;

      state.imageList.image = base64img;
      state.imageList.prompt = action.payload.prompt;
      state.imageList.seed = action.payload.seed;
    },
    deleteImage: (state) => {
      state.imageList.image = '';
      state.imageList.prompt = '';
      state.imageList.seed = -1;
    },
    initImageList: (state) => {
      state.imageList = {
        image: '',
        prompt: '',
        seed: -1,
      };
    },
    setLoadingSocket: (state, action) => {
      state.loadingSocket = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setImageInfo: (state, action) => {
      state.createdImageInfo = action.payload;
    },
    closeImgSaveModal: (state) => {
      state.successImageSave = false;
    },
    setCanvasSize: (state, action) => {
      state.canvasSize = action.payload;
    },
    initImageGallery: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveImageApi.pending, (state) => {
        state.successImageSave = false;
      })
      .addCase(saveImageApi.fulfilled, (state, action) => {
        if (action.payload.code === '200') {
          state.successImageSave = true;
        }
      })
      .addCase(saveImageApi.rejected, (state) => {
        state.successImageSave = false;
      });
  },
});

export const {
  setImageList,
  deleteImage,
  setProgress,
  setLoadingSocket,
  initImageList,
  setImageInfo,
  closeImgSaveModal,
  setCanvasSize,
  initImageGallery,
} = imageGallerySlice.actions;

export default imageGallerySlice.reducer;
