import React, { useEffect, useRef } from 'react';
import { IconCreateSuccess, IconCreateWarning } from '../../components/Icons';
import Button from '../../components/DefaultButton';

interface DefaultModalProps {
  type?: 'warning' | 'success';
  text: string;
  basic?: boolean;
  onClickCheck?: () => void;
  onClickConfirm?: () => void;
}

const DefaultModal = ({
  type,
  text,
  basic,
  onClickCheck,
  onClickConfirm,
}: DefaultModalProps) => {
  const bgEl = useRef<HTMLDivElement>(null);

  const printIcon = () => {
    switch (type) {
      case 'warning':
        return <IconCreateWarning />;
      case 'success':
        return <IconCreateSuccess />;

      default:
        return null;
    }
  };

  useEffect(() => {
    const { current } = bgEl;

    const clickHandler = (e: MouseEvent) => {
      if (current === e.target) {
        if (onClickCheck) {
          onClickCheck();
        }
      }
    };

    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (onClickCheck) {
          onClickCheck();
        }
      }
    };
    window.addEventListener('click', clickHandler);
    window.addEventListener('keydown', keydownHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
      window.removeEventListener('keydown', keydownHandler);
    };
  }, []);

  return (
    <div
      className="fixed w-full h-full left-0 top-0 bg-[rgba(0,0,0,0.7)] z-50"
      ref={bgEl}
    >
      <div
        id="modal-layout"
        className="w-[600px] bg-white rounded-[20px] fixed left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] py-[40px] flex flex-col items-center"
      >
        <div className="flex items-center mb-[30px]">
          {printIcon()}
          <p className="ml-[8px] text-[20px] text-[#222] font-semibold">
            {text}
          </p>
        </div>
        {basic ? (
          <div className="flex">
            <div className="w-[95px] h-[50px] mr-[10px]">
              <Button text="취소" basic onClick={onClickCheck} />
            </div>
            <div className="w-[95px] h-[50px]">
              <Button text="확인" onClick={onClickConfirm} />
            </div>
          </div>
        ) : (
          <div className="w-[95px] h-[50px]">
            <Button text="확인" onClick={onClickCheck} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DefaultModal;
