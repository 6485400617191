import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../app/hooks';

import ClipboardJS from 'clipboard';
import { IconExit } from '../../../components/Icons';
import Spinner from '../../../components/Spinner';
import { setOpenBestDetailModal } from '../bestGallery/bestGallerySlice';
import { setOpenDetail } from './userGallerySlice';
import Button from '../../../components/DefaultButton';
import DefaultModal from '../../image/DefaultModal';

interface DetailImageProps {
  isLoading?: boolean;
  image: string;
  width: number;
  height: number;
  prompt: string;
  material: string;
  style: string;
}

const DetailImage = ({
  isLoading,
  image,
  width,
  height,
  prompt,
  material,
  style,
}: DetailImageProps) => {
  const [canvasSize, setCanvasSize] = useState('');
  const [copyModalInfo, setCopyModalInfo] = useState<{
    open: boolean;
    type: 'warning' | 'success';
    text: string;
  }>({
    open: false,
    type: 'success',
    text: '',
  });

  const bgEl = useRef<HTMLDivElement>(null);
  const copyClipboard = useRef<HTMLButtonElement>(null);
  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(setOpenDetail(false));
    dispatch(setOpenBestDetailModal(false));
  };

  useEffect(() => {
    if (width === 1024) {
      switch (height) {
        case 576:
          setCanvasSize('작은 가로형');
          break;
        case 768:
          setCanvasSize('가로형');
          break;
        case 1024:
          setCanvasSize('정사각형');
          break;

        default:
          break;
      }
    } else if (width === 768) {
      setCanvasSize('세로형');
    } else setCanvasSize('');
  }, [width, height]);

  useEffect(() => {
    const { current } = bgEl;

    const clickHandler = (e: MouseEvent) => {
      if (current === e.target) {
        closeModal();
      }
    };

    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };
    window.addEventListener('click', clickHandler);
    window.addEventListener('keydown', keydownHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
      window.removeEventListener('keydown', keydownHandler);
    };
  }, []);

  // 클립보드 복사 기능
  useEffect(() => {
    if (copyClipboard.current) {
      const clipboard = new ClipboardJS(copyClipboard.current!, {
        //https://clipboardjs.com/ 참고
        text: function (trigger) {
          trigger.setAttribute('aria-label', 'Copied!');

          return prompt;
        },
      });

      clipboard.on('success', function (e) {
        setCopyModalInfo({
          open: true,
          text: '프롬프트를 복사하였습니다.',
          type: 'success',
        });
        e.clearSelection();
      });

      clipboard.on('error', function (e) {
        setCopyModalInfo({
          open: true,
          text: '복사에 실패했습니다!',
          type: 'warning',
        });
        clipboard.destroy();
      });
      return () => {
        clipboard.destroy();
      };
    }
  }, [copyClipboard.current]);

  const ConfirmModalButton = () => {
    setCopyModalInfo({
      open: false,
      text: '',
      type: 'success',
    });
  };

  return (
    <div
      id="backgroud"
      className="fixed bg-[rgba(0,0,0,0.7)] w-full h-full left-0 top-0 z-50"
      ref={bgEl}
    >
      {copyModalInfo.open ? (
        <DefaultModal
          text={copyModalInfo.text}
          type={copyModalInfo.type}
          onClickCheck={ConfirmModalButton}
        />
      ) : null}
      {isLoading ? <Spinner /> : null}
      <div
        id="detail-card"
        className="fixed flex flex-col left-1/2 top-[100px] translate-x-[-50%] bg-white w-[990px] h-[70vh] p-[50px] rounded-[20px]"
      >
        <div className="body flex flex-row w-full h-full">
          <div className=" w-[60%] h-full flex flex-col items-center pr-[25px]">
            <div className="flex w-full h-full items-center">
              <img
                id="detail-image"
                className="object-contain rounded-[10px] w-full h-full"
                src={'data:image/png;base64,' + image}
                alt=""
              />
            </div>
          </div>

          <div className="prompt-wrapper w-[40%] h-full pl-[25px] flex flex-col">
            <div className="w-full flex justify-end mb-[30px]">
              <button className="w-[52px] h-[52px]" onClick={closeModal}>
                <IconExit />
              </button>
            </div>
            <div>
              <p className="text-[18px] text-[#222] font-bold">프롬프트</p>
              <div className="w-full my-[15px] max-h-[150px] overflow-scroll">
                <p className="text-[16px] text-[#666]">{prompt}</p>
              </div>
            </div>
            <div className="w-[127px] h-[40px] mb-[30px]">
              <Button text="프롬프트 복사" buttonRef={copyClipboard} />
            </div>
            <div className="flex items-center mb-[12px]">
              <p className="text-[18px] text-[#222] font-bold mr-[15px]">
                캔버스 크기 선택
              </p>
              <p className="text-[16px] text-[#666]">{canvasSize}</p>
            </div>
            <div className="flex items-center mb-[12px]">
              <p className="text-[18px] text-[#222] font-bold mr-[15px]">
                재료선택
              </p>
              <p className="text-[16px] text-[#666]">
                {material ? material : '선택안함'}
              </p>
            </div>
            <div className="flex items-center">
              <p className="text-[18px] text-[#222] font-bold mr-[15px]">
                유명화가 따라하기
              </p>
              <p className="text-[16px] text-[#666]">
                {style ? style : '선택안함'}
              </p>
            </div>

            <div className="w-full "></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailImage;
