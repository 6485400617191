import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { deleteImageApi, setDeleteComplete, setOpenDelete } from './imageDeleteSlice';

const ImageDeleteModal = () => {
  const dispatch = useAppDispatch();

  const isOpenDelete = useAppSelector((state) => state.imageDelete.isOpenDelete);
  const deleteClickId = useAppSelector((state) => state.imageDelete.deleteClickId);

  const onDeleteButton = useCallback(() => {
    if (deleteClickId) {
      dispatch(deleteImageApi(deleteClickId));
      dispatch(setOpenDelete(false));
    }
  }, [dispatch]);

  const onCancel = useCallback(() => {
    dispatch(setOpenDelete(false)); // 취소 버튼 클릭 시 모달 닫기
    dispatch(setDeleteComplete(false));
  }, [dispatch]);

  useEffect(() => {
    // isOpen prop이 변경되면 모달 열기/닫기 상태 업데이트
    dispatch(setOpenDelete(isOpenDelete));
  }, [dispatch, deleteClickId]);

  return (
    <div
      className={` fixed w-full h-screen top-0 left-0 flex justify-center items-center transition-opacity bg-[rgba(0,0,0,0.7)]  z-50  ${
        isOpenDelete ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
    >
      <div className="absolute w-full h-full  " onClick={(e) => e.stopPropagation()}></div>
      <div className="z-10 w-[600px] h-[194px] bg-white rounded-[20px] shadow-lg flex flex-col justify-center items-center text-center">
        <div className="w-[90%] h-[50px] flex justify-center items-center">
          <p className="font-semibold text-xl">선택한 그림을 지우시겠습니까?</p>
        </div>
        <div className="w-[90%] h-[50px] flex justify-center items-center  mt-[30px]">
          <button
            className="w-[92px] h-[50px] rounded-[5px] border border-[#222] text-[18px] text-[#555] mr-[10px]"
            onClick={onCancel}
          >
            취소
          </button>
          <button
            className="w-[92px] h-[50px] rounded-[5px] bg-[#089572] text-[18px] text-white font-semibold"
            onClick={onDeleteButton}
          >
            지우기
          </button>
        </div>
      </div>
    </div>
  );
};
export default ImageDeleteModal;
