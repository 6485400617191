import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setStyleId } from './imageStylesSlice';
import { setStyleName } from '../selectedOptions/selectedOptionsSlice';
import StyleItems from '../../../components/StyleItems';

const ImagePaintingStyle = () => {
  const dispatch = useAppDispatch();
  const styleList = useAppSelector((state) => state.imageStyles.styleList);
  const selectedStyle = useAppSelector(
    (state) => state.selectedOptions.selectedOptions.style,
  );

  const onClickStyle = (id: number, name: string) => {
    dispatch(setStyleId(id));
    dispatch(setStyleName(name));
  };

  return (
    <div className="w-full h-full bg-white rounded-[10px] shadow-[0px_4px_4px_0px_#E8D9BB] p-[35px] overflow-y-auto grid grid-cols-2 gap-[40px]">
      {styleList.map((element) => {
        return (
          <StyleItems
            key={element.id}
            elName={element.name}
            elImg={element.image}
            isNew={element.new_update}
            selectedItem={selectedStyle}
            description={element.description}
            onClickItem={() => onClickStyle(element.id, element.name)}
          />
        );
      })}
    </div>
  );
};

export default ImagePaintingStyle;
