import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Checkbox from '../../components/Checkbox';
import { ImageCards } from '../../types/ImageCards';
import { setChecked } from './galleryManagerSlice';

interface ImageCardProps {
  images: ImageCards['images'];
}

const ImageCard: React.FC<ImageCardProps> = ({ images }) => {
  const dispatch = useAppDispatch();

  const viewCount = useAppSelector((state) => state.imageViewOption.viewCount);
  const ROW_LENGTH = viewCount > 40 ? 10 : 5;
  const checkedImages = useAppSelector(
    (state) => state.galleryManager.checkedImages,
  );

  const handleImageClick = (id: number) => {
    dispatch(setChecked(id));
  };

  // 이미지 수가 ROW_LENGTH보다 적으면 레이아웃이 깨지는것을 막기위해 placeholder역할의 div를 추가
  const renderPlaceholderDivs = () => {
    const remainingDivs = ROW_LENGTH - images.length;
    const placeholderDivs = [];
    for (let i = 0; i < remainingDivs; i++) {
      placeholderDivs.push(
        <div
          key={`placeholder-${i}`}
          className={`relative flex items-center justify-center overflow-hidden w-full h-auto aspect-square rounded-lg shrink ${
            ROW_LENGTH >= 10 ? 'mx-2 my-2' : 'mx-4 mb-4'
          }`}
        />,
      );
    }
    return placeholderDivs;
  };

  return (
    <div className="flex flex-col ">
      {images.map((image, idx) => {
        if (idx % ROW_LENGTH === 0) {
          const remainingImages = images.slice(idx, idx + ROW_LENGTH);
          const numRemaining = ROW_LENGTH - remainingImages.length;
          return (
            <div key={image.id} className="flex flex-row">
              {remainingImages.map((subImage, subIdx) => (
                <div
                  key={subImage.id}
                  className={`flex items-center justify-center relative rounded-lg cursor-pointer bg-[#3B403E] shrink w-full h-auto overflow-hidden aspect-square ${
                    ROW_LENGTH >= 10 ? 'mx-2 my-2' : 'mx-4 mb-4'
                  }
                  `}
                >
                  <div
                    key={subImage.id}
                    className="relative aspect-square w-full h-auto"
                    onClick={() => handleImageClick(subImage.id)}
                  >
                    <img
                      className="object-contain w-full h-full rounded-[10px]"
                      id={subImage.id.toString()}
                      alt="이미지"
                      src={'data:image/png;base64,' + subImage.image}
                    />
                    {checkedImages.includes(subImage.id) && (
                      <div className="absolute top-0 left-0 w-full h-full border-4 rounded-[10px] border-[#F95E21] pointer-events-none"></div>
                    )}

                    <div className="absolute top-[5%] left-[5%] bg-white rounded-[5px]">
                      <Checkbox
                        checked={checkedImages.includes(subImage.id)}
                        onCheck={() => {}}
                      />
                    </div>
                  </div>
                </div>
              ))}

              {Array.from({ length: numRemaining }).map((_, i) => (
                <div
                  key={i}
                  className={`relative flex items-center justify-center overflow-hidden w-full h-auto aspect-square rounded-lg shrink ${
                    ROW_LENGTH >= 10 ? 'mx-2 my-2' : 'mx-4 mb-4'
                  }`}
                />
              ))}
            </div>
          );
        }
        return null;
      })}
      {/* 이미지 수가 ROW_LENGTH개 미만인 경우 처리 */}
      {images.length < ROW_LENGTH && (
        <div className="flex flex-row ">
          {Array.from({
            length: ROW_LENGTH - images.length,
          }).map((_, i) => (
            <div
              key={i}
              className={`relative flex items-center justify-center overflow-hidden w-full h-auto aspect-square rounded-lg shrink ${
                ROW_LENGTH >= 10 ? 'mx-2 my-2' : 'mx-4 mb-4'
              }`}
            />
          ))}
        </div>
      )}
      {/* {renderPlaceholderDivs()} */}
    </div>
  );
};

export default ImageCard;
