import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IconSaveGallery } from '../../../components/Icons';
import Spinner from '../../../components/Spinner';
import DefaultModal from '../DefaultModal';
import {
  closeImgSaveModal,
  saveImageApi,
  setCanvasSize,
} from './imageGallerySlice';
import { debounce } from 'lodash';

const ImageGallery = () => {
  const divEl = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { loadingSocket, imageList, createdImageInfo } = useAppSelector(
    (state) => state.imageList,
  );
  const progress = useAppSelector((state) => state.imageList.progress);
  const successImageSave = useAppSelector(
    (state) => state.imageList.successImageSave,
  );
  const hoverPrompt = useAppSelector(
    (state) => state.imageList.createdImageInfo?.config.prompt,
  );
  const hoverMaterial = useAppSelector(
    (state) => state.imageList.createdImageInfo?.config.option.material?.name,
  );
  const hoverStyle = useAppSelector(
    (state) => state.imageList.createdImageInfo?.config.option.style?.name,
  );
  const createdWidth = useAppSelector(
    (state) => state.imageList.createdImageInfo?.config.width,
  );
  const createdHeight = useAppSelector(
    (state) => state.imageList.createdImageInfo?.config.height,
  );
  const canvasSize = useAppSelector((state) => state.imageList.canvasSize);
  // state
  const [imageSize, setImageSize] = useState('');

  const handleResize = debounce((width: number, height: number) => {
    dispatch(
      setCanvasSize({
        width: `${width}px`,
        height: `${height}px`,
      }),
    );
  }, 100);

  useEffect(() => {
    const { current } = divEl;
    if (current) {
      const observer = new ResizeObserver((entries) => {
        handleResize(
          entries[0].contentRect.width,
          entries[0].contentRect.height,
        );
      });
      observer.observe(current);
    }
  }, []);

  useEffect(() => {
    if (createdWidth === 1024) {
      switch (createdHeight) {
        case 576:
          setImageSize('작은 가로형');
          break;
        case 768:
          setImageSize('가로형');
          break;
        case 1024:
          setImageSize('정사각형');
          break;

        default:
          break;
      }
    } else if (createdWidth === 768) {
      setImageSize('세로형');
    } else setImageSize('');
  }, [createdWidth, createdHeight]);

  return (
    <div id="galleryArea" className="w-full grow flex flex-col pt-[20px]">
      {loadingSocket ? <Spinner progress={progress} /> : null}
      {successImageSave ? (
        <DefaultModal
          type="success"
          text="그림이 갤러리에 저장되었습니다."
          onClickCheck={() => dispatch(closeImgSaveModal())}
        />
      ) : null}
      <div ref={divEl} className="flex grow items-center justify-center">
        {imageList.image ? (
          <div className="group relative flex justify-center">
            <img
              style={{
                maxWidth: canvasSize.width,
                maxHeight: canvasSize.height,
              }}
              className="object-contain rounded-[10px]"
              src={imageList.image}
              alt={imageList.prompt}
            />
            <div className="absolute hidden group-hover:flex flex-col items-center justify-center w-full h-full rounded-[10px] bg-[rgba(0,0,0,0.6)] p-[50px] [&_p]:font-nps font-nps text-[20px] text-white font-bold">
              <div className="overflow-scroll">
                <p className="mb-[20px]">{hoverPrompt}</p>
                <div className="flex mb-[20px]">
                  <p>캔버스 크기 선택 : {imageSize}</p>
                </div>
                <div className="flex mb-[20px]">
                  <p>
                    재료 선택 : {hoverMaterial ? hoverMaterial : '선택안함'}
                  </p>
                </div>
                <div className="flex">
                  <p>
                    유명 화가 따라하기 : {hoverStyle ? hoverStyle : '선택안함'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full h-full rounded-[10px] bg-white" />
        )}
      </div>

      <div
        id="ImageGallery-footer"
        className="w-full h-[25px] flex items-center justify-center mt-[10px]"
      >
        <div
          className="flex items-center justify-center cursor-pointer mr-[20px]"
          onClick={() => {
            if (createdImageInfo) {
              dispatch(saveImageApi(createdImageInfo));
            }
          }}
        >
          <IconSaveGallery />
          <p className="text-[#222] font-bold ml-1 font-nps">갤러리에 저장</p>
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
