import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

interface SignupFormState {
  loadingSignup: boolean;
  available: boolean;
  checkMessage: string;
  checkPolicy: boolean;
  openModal: boolean;
  isSignup: boolean;
}

const initialState: SignupFormState = {
  loadingSignup: false,
  available: false,
  checkMessage: '',
  checkPolicy: false,
  openModal: false,
  isSignup: false,
};

interface GetCheckEmailParamType {
  email: string;
}

interface SetSignupParamType {
  email: string;
  name: string;
  password: string;
  phone_number: string;
  birth: string;
  role_id: number;
}

export const checkEmailApi = createAsyncThunk(
  'account/checkEmail',
  async (info: GetCheckEmailParamType) => {
    const response = await fetch(
      `${apiInfo.api_url}/account/signup?email=${info.email}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    let data = await response.json();
    return data;
  },
);

export const signupApi = createAsyncThunk(
  'account/signup',
  async (info: SetSignupParamType) => {
    const response = await fetch(`${apiInfo.api_url}/account/signup`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: info.email,
        name: info.name,
        password: info.password,
        phone_number: info.phone_number,
        birth: info.birth,
        role_id: info.role_id,
      }),
    });
    let data = await response.json();

    return data;
  },
);

const SignupFormSlice = createSlice({
  name: 'Signup',
  initialState,
  reducers: {
    setAvailable: (state, action) => {
      state.available = action.payload;
    },
    setCheckMessage: (state, action) => {
      state.checkMessage = action.payload;
    },
    setCheckPolicy: (state, action) => {
      state.checkPolicy = action.payload;
    },
    setOpenModal: (state, action) => {
      state.openModal = action.payload;
    },
    setIsSignup: (state, action) => {
      state.isSignup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkEmailApi.pending, (state) => {
        state.available = false;
        state.checkMessage = '';
      })
      .addCase(checkEmailApi.fulfilled, (state, action) => {
        if (action.payload.code === '200') {
          state.available = true;
        }
        state.checkMessage = action.payload.message;
      })
      .addCase(checkEmailApi.rejected, (state) => {
        state.available = false;
      })

      .addCase(signupApi.pending, (state) => {
        state.loadingSignup = true;
        state.isSignup = false;
      })
      .addCase(signupApi.fulfilled, (state, action) => {
        state.loadingSignup = false;
        if (action.payload.code === '200') {
          alert('회원가입 되었습니다.');
          state.isSignup = true;
        } else {
          alert('회원가입이 실패하였습니다.\n' + action.payload.message);
          state.isSignup = false;
        }
      })
      .addCase(signupApi.rejected, (state) => {
        state.loadingSignup = false;
        state.isSignup = false;
        alert('인터넷 연결을 확인해주세요');
      });
  },
});

export const {
  setAvailable,
  setCheckMessage,
  setCheckPolicy,
  setOpenModal,
  setIsSignup,
} = SignupFormSlice.actions;

export default SignupFormSlice.reducer;
