import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Logo from '../../../assets/icons/logo-nav.png';
import {
  IconBestGallery,
  IconNavGallery,
  IconNavLogout,
} from '../../../components/Icons';
import { setLogoutApi } from '../../user/LoginForm/loginFormSlice';
import { getProfileApi } from './navigationSlice';
import { initImageSize } from '../../image/imageSize/imageSizeSlice';
import { initImageStyles } from '../../image/imageStyle/imageStylesSlice';
import { initOptions } from '../../image/selectedOptions/selectedOptionsSlice';
import { initUserManager } from '../../admin/userManager/userManagerSlice';
import DefaultModal from '../../image/DefaultModal';
import { initImageGallery } from '../../image/imageGallery/imageGallerySlice';

const Navigation = () => {
  const BUTTON_STYLE =
    'w-[50px] h-[50px] border-1 border-[#03d5e0]/[.3] rounded-[20px] bg-nav-button backdrop-blur-[5px] shadow-nav-button flex items-center justify-center';
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoginSuccess = useAppSelector(
    (state) => state.LoginForm.successLogin,
  );
  const isMainPage = useAppSelector((state) => state.navigation.isMainPage);
  const profile = useAppSelector((state) => state.navigation.profile);
  const changedProfile = useAppSelector(
    (state) => state.editMypage.changedProfile,
  );

  const [isAdmin, setIsAdmin] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const onClickLogout = () => {
    setOpenLogoutModal(true);
  };

  const closeLogoutModal = () => {
    setOpenLogoutModal(false);
  };

  const confirmLogoutModal = () => {
    dispatch(setLogoutApi());
    dispatch(initImageSize());
    dispatch(initImageStyles());
    dispatch(initOptions());
    dispatch(initUserManager());
    dispatch(initImageGallery());
  };

  useEffect(() => {
    if (isLoginSuccess) {
      navigate('/');
    }
  }, [isLoginSuccess]);

  useEffect(() => {
    // admin
    if (sessionStorage.getItem('isAdmin') === 'true') {
      setIsAdmin(true);
    }

    // expire session
    let interval: ReturnType<typeof setTimeout> | null = null;
    const expireSession = sessionStorage.getItem('session_expire');
    if (expireSession) {
      const transExpire = new Date(expireSession);

      interval = setInterval(() => {
        const currentTime = new Date();
        const val = Math.floor(
          (transExpire.getTime() - currentTime.getTime()) / 1000,
        );

        if (val <= 1) {
          dispatch(setLogoutApi());
          sessionStorage.removeItem('session_expire');
          if (interval) {
            clearInterval(interval);
          }
        }
      }, 1000);
    }
    dispatch(getProfileApi());
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    if (changedProfile) {
      dispatch(getProfileApi());
    }
  }, [changedProfile, dispatch]);

  return (
    <div
      id="nav-bar"
      className={`w-full h-[75px] pt-[16px] pl-[50px] pr-[40px] pb-[9px] fixed z-50 ${
        isAdmin
          ? `bg-template-black`
          : isMainPage
          ? 'bg-template-green'
          : 'bg-template-black'
      }`}
    >
      {openLogoutModal ? (
        <DefaultModal
          text="로그아웃 하시겠습니까?"
          basic
          onClickConfirm={confirmLogoutModal}
          onClickCheck={closeLogoutModal}
        />
      ) : null}
      <div
        id="nav-wrap"
        className="w-full h-full flex justify-between items-center"
      >
        <Link to="/">
          <div className="flex items-center h-[75px]">
            <img src={Logo} alt="" className="mr-2" />

            {isMainPage && !isAdmin && (
              <p
                id="agencyName"
                className="pt-1 text-xl pl-2 font-bold text-[#E6F0ED]"
              >
                {profile.agency}
              </p>
            )}
          </div>
        </Link>
        {isAdmin ? (
          // 관리자 UI
          <div id="admin-nav-wrap" className="flex items-center">
            <p className="text-[18px] text-[#aaa] font-semibold mr-[10px]">
              관리자
            </p>
            <Link className="flex items-center mr-[10px]" to="/userManage">
              <div className="w-[8px] h-[8px] bg-[#056d48] rounded-full mr-[10px]" />
              <p className="text-[18px] text-[#aaa] font-semibold">회원관리</p>
            </Link>
            <Link className="flex items-center mr-[10px]" to="/slideshowManage">
              <div className="w-[8px] h-[8px] bg-[#056d48] rounded-full mr-[10px]" />
              <p className="text-[18px] text-[#aaa] font-semibold">
                슬라이드쇼 관리
              </p>
            </Link>
            <Link className="flex items-center mr-[30px]" to="/gallaryManage">
              <div className="w-[8px] h-[8px] bg-[#056d48] rounded-full mr-[10px]" />
              <p className="text-[18px] text-[#aaa] font-semibold">
                갤러리 관리
              </p>
            </Link>
            <button className={BUTTON_STYLE} onClick={onClickLogout}>
              <IconNavLogout />
            </button>
          </div>
        ) : (
          // 일반 사용자 UI
          <div id="default-user-wrap" className="flex items-center">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => navigate('/mypage')}
            >
              <img
                className=" object-cover w-[50px] h-[50px] rounded-full mr-[10px]"
                src={'data:image/png;base64,' + profile.profile_image}
                alt="Profile"
              />
              <p className="font-semibold text-[18px] text-[#aaaaaa]">
                {profile.name}
              </p>
            </div>
            <div id="nav-button-wrap" className="flex ml-[22px]">
              <button
                className={`${BUTTON_STYLE} mr-[10px]`}
                onClick={() => navigate('/bestgallery')}
              >
                <IconBestGallery />
              </button>
              <button
                className={`${BUTTON_STYLE} mr-[10px]`}
                onClick={() => navigate('/gallery')}
              >
                <IconNavGallery />
              </button>
              <button className={BUTTON_STYLE} onClick={onClickLogout}>
                <IconNavLogout />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navigation;
