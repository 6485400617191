import { ReactComponent as IconArrowDownSVG } from '../assets/icons/icon-arrow-down.svg';
import { ReactComponent as IconChevronLeftSVG } from '../assets/icons/icon-chevron-left.svg';
import { ReactComponent as IconChevronRightSVG } from '../assets/icons/icon-chevron-right.svg';
import { ReactComponent as IconNavGallerySVG } from '../assets/icons/icon-nav-gallery.svg';
import { ReactComponent as IconNavLogoutSVG } from '../assets/icons/icon-nav-logout.svg';
import { ReactComponent as IconNavUserSVG } from '../assets/icons/icon-nav-user.svg';
import { ReactComponent as IconBestGallerySVG } from '../assets/icons/icon-bestgallery.svg';
import { ReactComponent as IconRedrawSVG } from '../assets/icons/icon-redraw.svg';
import { ReactComponent as IconSaveGallerySVG } from '../assets/icons/icon-save-gallery.svg';
import { ReactComponent as IconViewOptionSVG } from '../assets/icons/icon-view-option.svg';
import { ReactComponent as IconCreateWarningSVG } from '../assets/icons/icon-warning.svg';
import { ReactComponent as IconCreateSuccessSVG } from '../assets/icons/icon-success.svg';
import { ReactComponent as IconExitSVG } from '../assets/icons/icon-x.svg';

interface IconProps {
  className?: string;
  circleFill?: string;
  strokeWidth?: number;
}
export const IconNavGallery = () => {
  return <IconNavGallerySVG />;
};

export const IconNavLogout = () => {
  return <IconNavLogoutSVG />;
};

export const IconBestGallery = () => {
  return <IconBestGallerySVG />;
};

export const IconSaveGallery = () => {
  return <IconSaveGallerySVG />;
};

export const IconRedraw = () => {
  return <IconRedrawSVG />;
};

export const IconArrowDown = () => {
  return <IconArrowDownSVG />;
};
export const IconNavUser = ({ className }: IconProps) => {
  return <IconNavUserSVG className={className} />;
};
export const IconViewOption = (className: IconProps) => {
  return <IconViewOptionSVG className={className.className} />;
};

export const IconChevronLeft = ({ className, strokeWidth }: IconProps) => {
  return <IconChevronLeftSVG strokeWidth={strokeWidth} className={className} />;
};

export const IconChevronRight = ({ className, strokeWidth }: IconProps) => {
  return (
    <IconChevronRightSVG strokeWidth={strokeWidth} className={className} />
  );
};

export const IconCreateWarning = () => {
  return <IconCreateWarningSVG />;
};

export const IconCreateSuccess = () => {
  return <IconCreateSuccessSVG />;
};

export const IconExit = () => {
  return <IconExitSVG />;
};
