import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import UserManagerModal from './UserManagerModal';
import { setPage } from './userManagerSlice';
import Select from '../../../components/Select';
import {
  closeDefaultModal,
  createUserApi,
  deleteUserApi,
  getAccountListApi,
  initUserPassword,
  searchUserApi,
  setIsOpenUserCreateModal,
  updateUsergroupApi,
} from '../adminForm/adminFormSlice';
import { Pagination } from '@mui/material';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/DefaultButton';
import DefaultModal from '../../image/DefaultModal';

const UserListTable = () => {
  const dispatch = useAppDispatch();
  const userList = useAppSelector((state) => state.adminForm.accountListData);
  const groupList = useAppSelector((state) => state.adminForm.groupListData);
  const successUpdateUser = useAppSelector(
    (state) => state.adminForm.successUpdateUser,
  );
  const successUpdateGroup = useAppSelector(
    (state) => state.adminForm.successUpdateGroup,
  );
  const groupId = useAppSelector((state) => state.adminForm.selectedGroup);
  const page = useAppSelector((state) => state.userManager.page);
  const searchType = useAppSelector((state) => state.userManager.searchType);
  const searchKeyword = useAppSelector(
    (state) => state.userManager.searchKeyword,
  );
  const resultCreateUserModal = useAppSelector(
    (state) => state.adminForm.isOpenUserCreateModal,
  );
  const createUserMessage = useAppSelector(
    (state) => state.adminForm.createUserMessage,
  );
  const statusCreateUser = useAppSelector(
    (state) => state.adminForm.createUserResult,
  );
  const isCreateUser = useAppSelector((state) => state.adminForm.isCreateUser);
  const openResultModal = useAppSelector(
    (state) => state.adminForm.openResultModal,
  );
  const resultModalMessage = useAppSelector(
    (state) => state.adminForm.resultModalMessage,
  );
  const resultModalstatus = useAppSelector(
    (state) => state.adminForm.resultModalstatus,
  );
  // state
  const [activeButton, setActiveButton] = useState(false);
  const [openInitPassword, setOpenInitPassword] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [openMoveUserModal, setOpenMoveUserModal] = useState(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedInitUserId, setSelectedInitUserId] = useState(0);
  const [checkedArr, setCheckedArr] = useState<number[]>([]);
  const [allCheck, setAllCheck] = useState(false);
  const [changedGroupInfo, setChangedGroupInfo] = useState<number | null>(0);
  const [changedGroupName, setChangedGroupName] = useState('');
  const [rebGroupArr, setRebGroupArr] = useState<
    {
      id: number | null;
      value: string;
    }[]
  >([]);
  // 유저 생성
  const [createUserName, setCreateUserName] = useState('');
  const [createUserBirth, setCreateUserBirth] = useState('');
  const [createUserId, setCreateUserId] = useState('');
  const [createUserPassword, setCreateUserPassword] = useState('');
  const [createUsergroup, setCreateUserGroup] = useState<number | null>(-1);

  useEffect(() => {
    if (checkedArr.length > 0) {
      setActiveButton(true);
    } else setActiveButton(false);
  }, [checkedArr]);

  const handledCheckbox = (id: number) => {
    let data_temp = [...checkedArr];

    if (data_temp.includes(id)) {
      // delete
      data_temp = checkedArr.filter((element) => id !== element);
    } else data_temp.push(id);

    setCheckedArr(data_temp);
    if (allCheck) {
      setAllCheck(false);
    }
  };

  const allCheckedHandler = () => {
    const data_temp: number[] = [];
    userList.user_list.forEach((element) => {
      data_temp.push(element.id);
    });
    setCheckedArr(data_temp);
    setAllCheck((prev) => !prev);
    if (allCheck) {
      setCheckedArr([]);
    }
  };

  // pagination
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPage(value));
  };

  const confirmInitUserPassword = () => {
    dispatch(initUserPassword(selectedInitUserId));
    setSelectedUser('');
    setOpenInitPassword(false);
  };

  const cancelInitUserPassword = () => {
    setSelectedInitUserId(0);
    setSelectedUser('');
    setOpenInitPassword(false);
  };

  // 회원 삭제 모달 확인 버튼
  const confirmDeleteUser = () => {
    dispatch(deleteUserApi(checkedArr));
    setOpenDeleteUserModal(false);
  };

  // 회원 삭제 모달 취소 버튼
  const cancelDeleteUser = () => {
    setOpenDeleteUserModal(false);
  };

  // 회원 이동 모달 확인 버튼
  const confirmMoveUser = () => {
    setChangedGroupInfo(0);
    setChangedGroupName('');
    dispatch(
      updateUsergroupApi({ userId: checkedArr, groupId: changedGroupInfo }),
    );
    setOpenMoveUserModal(false);
  };

  // 회원 이동 모달 취소 버튼
  const cancelMoveUser = () => {
    setChangedGroupInfo(0);
    setChangedGroupName('');
    setOpenMoveUserModal(false);
  };

  // 회원 생성 버튼 클릭
  const clickCreateUserButton = () => {
    setOpenCreateUserModal(true);
    setCreateUserName('');
    setCreateUserBirth('');
    setCreateUserId('');
    setCreateUserPassword('');
    setCreateUserGroup(-1);
  };

  // 회원 생성 결과 모달 확인 버튼
  const confirmCreateUserMessage = () => {
    dispatch(setIsOpenUserCreateModal(false));
  };

  useEffect(() => {
    // 유저가 생성되었을 경우 회원 생성 모달 off
    if (isCreateUser) {
      setOpenCreateUserModal(false);
    }
  }, [isCreateUser]);

  // 회원 생성 모달 확인 버튼
  const confirmCreateUser = () => {
    dispatch(
      createUserApi({
        id: createUserId,
        name: createUserName,
        password: createUserPassword,
        birth: createUserBirth,
        group_id: createUsergroup,
      }),
    );
  };

  // 회원 생성 모달 취소 버튼
  const cancelCreateUser = () => {
    setOpenCreateUserModal(false);
  };

  // default 모달 close
  const onCloseDefaultModal = () => {
    dispatch(closeDefaultModal());
  };

  // 회원 이동 Select Option 클릭시 id 값 저장
  const onClickSelect = (id: number | null, value?: string) => {
    setChangedGroupInfo(id);
    if (value) {
      setChangedGroupName(value);
    }
    setOpenMoveUserModal(true);
  };

  // 회원 생성 Select Option 클릭시 id 값 저장
  const onClickCreateUserGroup = (id: number | null) => {
    setCreateUserGroup(id);
  };

  useEffect(() => {
    if (successUpdateUser) {
      setCheckedArr([]);
      dispatch(
        getAccountListApi({
          groupId,
          sequence: page,
        }),
      );
    }
  }, [successUpdateUser]);

  useEffect(() => {
    setCheckedArr([]);
    if (searchKeyword) {
      dispatch(
        searchUserApi({
          type: searchType,
          search: searchKeyword,
          sequence: page,
          groupId,
        }),
      );
    } else {
      dispatch(
        getAccountListApi({
          groupId,
          sequence: page,
        }),
      );
    }
  }, [page, groupId]);

  useEffect(() => {
    setCheckedArr([]);
    if (successUpdateGroup) {
      if (searchKeyword) {
        dispatch(
          searchUserApi({
            type: searchType,
            search: searchKeyword,
            sequence: page,
            groupId,
          }),
        );
      } else {
        dispatch(
          getAccountListApi({
            groupId,
            sequence: page,
          }),
        );
      }
    }
  }, [successUpdateGroup]);

  // Select Component에 전달할 리빌드 그룹 arr
  useEffect(() => {
    const data_temp: {
      id: number | null;
      value: string;
    }[] = [];
    groupList.forEach((element) => {
      data_temp.push({
        id: element.group_id,
        value: element.name,
      });
    });
    setRebGroupArr(data_temp);
  }, [groupList]);

  return (
    <div className="w-full h-full">
      {openInitPassword ? (
        <UserManagerModal
          title="비밀번호를 초기화 하시겠습니까?"
          highlightTitle={selectedUser}
          subTitle="*(회원아이디)1234로 일괄 초기화"
          onClickConfirm={confirmInitUserPassword}
          onClickCancel={cancelInitUserPassword}
        />
      ) : null}
      {openDeleteUserModal ? (
        <UserManagerModal
          title="선택 회원을 삭제하시겠습니까?"
          deleteMode
          onClickConfirm={confirmDeleteUser}
          onClickCancel={cancelDeleteUser}
        />
      ) : null}
      {openMoveUserModal ? (
        <UserManagerModal
          title="으로 선택 회원을 이동하시겠습니까?"
          highlightTitle={changedGroupName}
          onClickConfirm={confirmMoveUser}
          onClickCancel={cancelMoveUser}
        />
      ) : null}
      {openCreateUserModal ? (
        <UserManagerModal
          title="회원 생성하기"
          createUserMode
          options={rebGroupArr}
          onClickGroup={onClickCreateUserGroup}
          onChangeName={(e) => setCreateUserName(e.target.value)}
          onChangeBirth={(e) => setCreateUserBirth(e.target.value)}
          onChangeId={(e) => setCreateUserId(e.target.value)}
          onChangePassword={(e) => setCreateUserPassword(e.target.value)}
          onClickConfirm={confirmCreateUser}
          onClickCancel={cancelCreateUser}
        />
      ) : null}
      {resultCreateUserModal ? (
        <DefaultModal
          type={statusCreateUser}
          text={createUserMessage}
          onClickCheck={confirmCreateUserMessage}
        />
      ) : null}
      {openResultModal ? (
        <DefaultModal
          type={resultModalstatus}
          text={resultModalMessage}
          onClickCheck={onCloseDefaultModal}
        />
      ) : null}
      <div
        id="top-button-wrap"
        className="flex w-full justify-between mb-[20px]"
      >
        <div className="w-[300px] h-[50px]">
          <Select
            isDisable={!activeButton}
            options={rebGroupArr}
            placehorder="이동시킬 그룹을 선택하세요"
            onClick={(id, value) => onClickSelect(id, value)}
          />
        </div>
        <div className="flex">
          <button
            className={`w-[150px] h-[50px] rounded-[5px] border border-[#222] text-center text-[18px] text-[#555] mr-[5px] ${
              !activeButton ? 'cursor-not-allowed' : null
            }`}
            onClick={() => setOpenDeleteUserModal(true)}
            disabled={!activeButton}
          >
            선택회원 삭제
          </button>
          <div className="w-[120px] h-[50px]">
            <Button text="회원생성" onClick={clickCreateUserButton} />
          </div>
        </div>
      </div>
      <div id="table-wrap" className="flex flex-col w-full mb-[30px]">
        <div
          id="table-header"
          className="flex items-center w-full h-[50px] border-t-[3px] border-[#222] border-b text-[18px] [&_div]:text-[#555] [&_div]:font-medium"
        >
          <div className="flex px-[20px]">
            <Checkbox checked={allCheck} onCheck={allCheckedHandler} />
          </div>
          <div className="w-[200px]">아이디</div>
          <div className="w-[120px]">가입일</div>
          <div className="w-[120px] text-center">이미지 생성 수</div>
          <div className="grow"></div>
          <div className="w-[140px]">소속 그룹</div>
          <div className="w-[135px]"></div>
        </div>
        <div id="table-body" className="flex flex-col w-full">
          {userList.user_list?.map((element) => {
            return (
              <div
                key={element.id}
                id="body-row"
                className="flex items-center w-full h-[50px] border-b border-[#ccc] [&_p]:text-[18px]"
              >
                <div className="flex px-[20px]">
                  <Checkbox
                    checked={checkedArr.includes(element.id)}
                    onCheck={() => handledCheckbox(element.id)}
                  />
                </div>
                <div className="w-[200px]">
                  <p className="text-[#222] font-semibold truncate">
                    {element.name}({element.account_id})
                  </p>
                </div>
                <div className="w-[120px]">
                  <p className="text-[#555]">{element.date_joined}</p>
                </div>
                <div className="w-[120px] text-center">
                  <p className="text-[#555]">{element.image_count}</p>
                </div>
                <div className="grow" />
                <div className="w-[140px]">
                  <p className="text-[#555]">{element.group}</p>
                </div>
                <div className="px-[10px]">
                  <button
                    className="w-[115px] h-[35px] rounded-[5px] border-1  border-[#888] hover:border-[#089572] text-[15px] text-[#555] hover:border-2 hover:text-[#089572]"
                    onClick={() => {
                      setSelectedUser(element.account_id);
                      setSelectedInitUserId(element.id);
                      setOpenInitPassword(true);
                    }}
                  >
                    비밀번호 초기화
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div id="paginatin-wrap" className="w-full flex justify-center">
        <Pagination
          count={userList.total_sequence}
          onChange={handleChange}
          page={page}
        />
      </div>
    </div>
  );
};

export default UserListTable;
