import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setPrompt } from './promptSlice';
import _, { debounce } from 'lodash';

const Prompt = () => {
  const promptEl = useRef<HTMLTextAreaElement>(null);
  const [dotUi, setDotUi] = useState<Array<JSX.Element>>([]);
  const prompt = useAppSelector((state) => state.prompt.prompt);
  const dispatch = useAppDispatch();

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setPrompt(e.target.value));
  };

  const handleResize = debounce((width: number) => {
    const data_temp: Array<JSX.Element> = [];
    /*
     * dot이 영역안에 몇개가 들어가는지에 대한 수식. dotCount === x
     * current.getBoundingClientRect().width - (30px + 30px) = x*20px + (x-1)*15px
     * prompt 너비 - 좌우 padding(30px + 30px) = 20px의 dot의 개수 + 마진(15px)의 개수
     */
    const dotCount = (width - 45) / 35;

    for (let index = 0; index < dotCount; index++) {
      data_temp.push(
        <div
          key={index}
          className="w-[20px] h-[20px] bg-[#007345] rounded-full mr-[15px] last:mr-0"
        />,
      );
    }
    setDotUi(data_temp);
  }, 100);

  useEffect(() => {
    const { current } = promptEl;
    if (current) {
      const observer = new ResizeObserver((entries) => {
        handleResize(entries[0].borderBoxSize[0].inlineSize);
      });
      observer.observe(current);
    }
  }, [promptEl]);

  return (
    <div className="w-full h-full relative">
      <textarea
        ref={promptEl}
        id="prompt"
        className="px-[30px] py-[25px] w-full h-full text-lg text-[#777] bg-white resize-none focus-visible:outline-none rounded-[20px] customScroll"
        placeholder="그리고 싶은 내용을 쓰세요!"
        onChange={onChange}
        value={prompt}
      />
      <div className="absolute w-full h-[20px] flex bottom-0 translate-y-[50%] px-[30px]">
        {dotUi.map((element) => {
          return element;
        })}
      </div>
    </div>
  );
};

export default Prompt;
