import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { setIsLogin } from '../features/user/LoginForm/loginFormSlice';
import CreateImage from '../pages/CreateImage';
import GalleryManagerPage from '../pages/GalleryManagerPage';
import GalleryPage from '../pages/GalleryPage';
import LoginPage from '../pages/LoginPage';
import MyPage from '../pages/MyPage';
import PageNotFound from '../pages/PageNotFound';
import SignupPage from '../pages/SignupPage';
import SlideShowManagePage from '../pages/SlideShowManagePage';
import UserManagePage from '../pages/UserManagePage';
import Templates from '../Templates';
import { useAppDispatch, useAppSelector } from './hooks';
import BestGalleryPage from '../pages/BestGalleryPage';

const App = () => {
  const dispatch = useAppDispatch();
  const { isLogin } = useAppSelector((state) => state.LoginForm);

  useEffect(() => {
    if (sessionStorage.getItem('session_anuta')) {
      dispatch(setIsLogin(true));
    } else dispatch(setIsLogin(false));
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        {isLogin ? (
          <Route path="/" element={<Templates />}>
            {sessionStorage.getItem('isAdmin') === 'true' ? (
              // 관리자 도메인 구성
              <React.Fragment>
                <Route index element={<UserManagePage />} />
                <Route path="/userManage" element={<UserManagePage />} />
                <Route
                  path="/slideshowManage"
                  element={<SlideShowManagePage />}
                />
                <Route path="/gallaryManage" element={<GalleryManagerPage />} />
              </React.Fragment>
            ) : (
              // 사용자 도메인 구성
              <React.Fragment>
                <Route index element={<CreateImage />} />
                <Route path="/bestgallery" element={<BestGalleryPage />} />
                <Route path="/gallery" element={<GalleryPage />} />
                <Route path="/mypage" element={<MyPage />} />
              </React.Fragment>
            )}
            {/* 공통 영역 도메인 구성 */}
            <Route path="*" element={<PageNotFound />} />
          </Route>
        ) : (
          <>
            {/* <Route index path="/" element={<SignupPage />} /> */}
            <Route path="/*" element={<LoginPage />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
