import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  createGroupApi,
  deleteGroupApi,
  getGroupListApi,
  renameGroupApi,
  setSelectedGroup,
} from '../adminForm/adminFormSlice';
import { ReactComponent as IconPencilSVG } from '../../../assets/icons/icon-pencil.svg';
import { ReactComponent as IconTrashSVG } from '../../../assets/icons/icon-trash.svg';
import {
  setDeleteGroupNumber,
  setOpenCreateGroupModal,
  setOpenDeleteGroupModal,
  setOpenRenameGroupModal,
  setPage,
} from './userManagerSlice';
import UserManagerModal from './UserManagerModal';
import Button from '../../../components/DefaultButton';

const GroupWrapper = () => {
  const dispatch = useAppDispatch();
  const totalUserCount = useAppSelector(
    (state) => state.adminForm.total_user_count,
  );
  const groupListData = useAppSelector(
    (state) => state.adminForm.groupListData,
  );
  const selectedGroup = useAppSelector(
    (state) => state.adminForm.selectedGroup,
  );
  const openCreateModal = useAppSelector(
    (state) => state.userManager.openCreateGroupModal,
  );
  const openRenameModal = useAppSelector(
    (state) => state.userManager.openRenameGroupModal,
  );
  const openDeleteModal = useAppSelector(
    (state) => state.userManager.openDeleteGroupModal,
  );
  const successUpdateGroup = useAppSelector(
    (state) => state.adminForm.successUpdateGroup,
  );
  const successUpdateUser = useAppSelector(
    (state) => state.adminForm.successUpdateUser,
  );
  const deleteGroupNumber = useAppSelector(
    (state) => state.userManager.deleteGroupNumber,
  );
  // state
  const [createGroupName, setCreateGroupName] = useState('');
  const [changeGroupName, setChangeGroupName] = useState('');
  const [editGroupId, setEditGroupId] = useState<number>(0);
  const [noGroupUserCnt, setNoGroupUserCnt] = useState(0);
  const [selectedGroupName, setSelectedGroupName] = useState('');

  const onClickGroupButton = (id: number | null) => {
    dispatch(setPage(1));
    dispatch(setSelectedGroup(id));
  };

  const onClickEditGroupButton = (
    id: number,
    name: string,
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    dispatch(setOpenRenameGroupModal(true));
    setEditGroupId(id);
    setSelectedGroupName(name);
  };

  const onClickDelGroupButton = (
    id: number,
    name: string,
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    dispatch(setOpenDeleteGroupModal(true));
    dispatch(setDeleteGroupNumber(id));
    setSelectedGroupName(name);
  };

  // 그룹 생성 모달 확인 버튼
  const confirmCreateGroup = () => {
    dispatch(createGroupApi(createGroupName));
    dispatch(setOpenCreateGroupModal(false));
    setCreateGroupName('');
  };

  // 그룹 생성 모달 취소 버튼
  const cancelCreateGroup = () => {
    setCreateGroupName('');
    dispatch(setOpenCreateGroupModal(false));
  };

  // 그룹 이름 변경 모달 확인 버튼
  const confirmRenameGroup = () => {
    dispatch(renameGroupApi({ groupId: editGroupId, name: changeGroupName }));
    dispatch(setOpenRenameGroupModal(false));
    setChangeGroupName('');
    setEditGroupId(0);
  };

  // 그룹 이름 변경 모달 취소 버튼
  const cancelRenameGroup = () => {
    setChangeGroupName('');
    dispatch(setOpenRenameGroupModal(false));
  };

  // 그룹 삭제 모달 확인 버튼
  const confirmDeleteGroup = () => {
    dispatch(deleteGroupApi(deleteGroupNumber));
    dispatch(setOpenDeleteGroupModal(false));
  };

  // 그룹 삭제 모달 취소 버튼
  const cancelDeleteGroup = () => {
    dispatch(setOpenDeleteGroupModal(false));
  };

  useEffect(() => {
    dispatch(getGroupListApi());
  }, []);

  useEffect(() => {
    if (successUpdateGroup || successUpdateUser) {
      dispatch(getGroupListApi());
    }
  }, [successUpdateGroup, successUpdateUser]);

  useEffect(() => {
    //
    let elseSum = 0;
    groupListData.forEach((element) => {
      elseSum += element.user_count;
    });
    setNoGroupUserCnt(totalUserCount - elseSum);
  }, [groupListData, totalUserCount]);

  return (
    <div className="w-full h-full">
      {openCreateModal ? (
        <UserManagerModal
          title="그룹 생성하기"
          input
          onChangeInput={(event) => setCreateGroupName(event.target.value)}
          onClickConfirm={confirmCreateGroup}
          onClickCancel={cancelCreateGroup}
        />
      ) : null}
      {openRenameModal ? (
        <UserManagerModal
          title="그룹 이름 변경"
          input
          placeholder={selectedGroupName}
          onChangeInput={(event) => setChangeGroupName(event.target.value)}
          onClickConfirm={confirmRenameGroup}
          onClickCancel={cancelRenameGroup}
        />
      ) : null}
      {openDeleteModal ? (
        <UserManagerModal
          title=" 그룹을 삭제하시겠습니까?"
          highlightTitle={selectedGroupName}
          deleteMode
          onClickConfirm={confirmDeleteGroup}
          onClickCancel={cancelDeleteGroup}
        />
      ) : null}
      <div className="w-full h-[50px]">
        <Button
          text="그룹만들기"
          onClick={() => dispatch(setOpenCreateGroupModal(true))}
        />
      </div>
      <div id="group-list" className="mt-[20px] w-full">
        <button
          className="group w-full h-[50px] border-b border-[#555555] flex items-center justify-between px-[10px] focus:outline-none"
          onClick={() => onClickGroupButton(null)}
        >
          <div className="flex items-center">
            <div
              className={`w-[8px] h-[8px] rounded-full mr-[12px] ${
                selectedGroup === null ? 'bg-[#089572]' : 'bg-[#d9d9d9]'
              }`}
            />
            <p
              className={`text-[18px] ${
                selectedGroup === null
                  ? 'font-semibold text-[#222222]'
                  : 'text-[#555555]'
              }`}
            >
              전체회원 ({totalUserCount})
            </p>
          </div>
        </button>
        {groupListData.map((element) => {
          return (
            <button
              key={element.group_id}
              className="group w-full h-[50px] border-b border-[#555555] flex items-center justify-between px-[10px] focus:outline-none"
              onClick={() => onClickGroupButton(element.group_id)}
            >
              <div className="flex items-center">
                <div
                  className={`w-[8px] h-[8px] rounded-full mr-[12px] ${
                    selectedGroup === element.group_id
                      ? 'bg-[#089572]'
                      : 'bg-[#d9d9d9]'
                  }`}
                />
                <p
                  className={`text-[18px] ${
                    selectedGroup === element.group_id
                      ? 'font-semibold text-[#222222]'
                      : 'text-[#555555]'
                  }`}
                >
                  {element.name} ({element.user_count})
                </p>
              </div>
              {element.group_id === -1 ? null : (
                <div className="items-center hidden group-hover:flex">
                  <IconPencilSVG
                    className="mr-[5px] [&_path]:hover:stroke-[#089572]"
                    onClick={(e) => {
                      onClickEditGroupButton(element.group_id, element.name, e);
                    }}
                  />
                  <IconTrashSVG
                    className="[&_path]:hover:stroke-[#089572]"
                    onClick={(e) => {
                      onClickDelGroupButton(element.group_id, element.name, e);
                    }}
                  />
                </div>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default GroupWrapper;
