import LoginForm from '../features/user/LoginForm/LoginForm';
import LogoImg from '../assets/images/img-login-logo.png';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../app/hooks';
import Spinner from '../components/Spinner';

const LoginPage = () => {
  const { pathname } = useLocation();
  const loginLoading = useAppSelector((state) => state.LoginForm.loginLoading);
  const [havePathname, setHavePathname] = useState(false);

  useEffect(() => {
    if (pathname.slice(1)) {
      setHavePathname(true);
    } else {
      setHavePathname(false);
    }
  }, [pathname]);

  return (
    <div className="h-screen w-full min-w-[1366px] min-h-[850px] px-[130px] flex flex-col items-center justify-center bg-[url('/src/assets/images/img-login-background.png')] bg-cover">
      {loginLoading ? <Spinner /> : null}
      <div
        id="wrapper"
        className="w-full h-[700px] max-w-[1600px] flex items-center justify-between rounded-[30px] px-[40px] border border-white backdrop-blur-[50px] bg-login-wrapper"
      >
        <div
          id="logo-wrap"
          className="flex grow items-center justify-end mr-[100px]"
        >
          <img src={LogoImg} alt="" />
        </div>

        <LoginForm isPathname={havePathname} />
      </div>
    </div>
  );
};

export default LoginPage;
