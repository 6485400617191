import React, { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import {
  deleteProfileApi,
  setOpenImgChangeModal,
  uploadProfileApi,
} from './editMypageSlice';
import Button from '../../../components/DefaultButton';

const ProfileImageModal = () => {
  const dispatch = useAppDispatch();
  const bgEl = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onUploadImage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return;
      }

      const uploadFile = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(uploadFile);
      reader.onloadend = () => {
        if (reader.result) {
          const strResult = reader.result.toString();
          dispatch(uploadProfileApi(strResult.split(',')[1]));
        }
      };
    },
    [],
  );

  const onClickUpload = () => {
    const { current } = inputRef;
    if (current) {
      current.click();
    }
  };

  useEffect(() => {
    const { current } = bgEl;
    const eventHandler = (e: MouseEvent) => {
      if (current === e.target) {
        dispatch(setOpenImgChangeModal(false));
      }
    };
    window.addEventListener('click', eventHandler);
    return () => {
      window.removeEventListener('click', eventHandler);
    };
  }, []);

  return (
    <div
      className="fixed w-full h-full left-0 top-0 bg-[rgba(0,0,0,0.7)] z-50"
      ref={bgEl}
    >
      <div className="create-card fixed flex flex-col items-center border border-[#222] left-1/2 top-[50%] translate-x-[-50%] translate-y-[-50%] bg-white w-[600px] h-[325px] rounded-[20px] py-[40px]">
        <div id="header" className="flex mb-[40px]">
          <p className="font-semibold text-[20px] text-[#222]">
            프로필 사진 바꾸기
          </p>
        </div>

        <div id="btn-wrap" className="flex flex-col">
          <div className="w-[300px] h-[50px]">
            <Button text="사진 업로드" onClick={onClickUpload} />
          </div>
          <input
            className="hidden"
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={onUploadImage}
          />
          <div
            className="w-[300px] h-[50px] border border-[#222] flex justify-center items-center cursor-pointer rounded-[5px] my-[10px]"
            onClick={() => dispatch(deleteProfileApi())}
          >
            <p className="text-[18px] text-[#555]">현재 사진 삭제</p>
          </div>
          <div
            className="w-[300px] h-[50px] border border-[#222] flex justify-center items-center cursor-pointer rounded-[5px]"
            onClick={() => dispatch(setOpenImgChangeModal(false))}
          >
            <p className="text-[18px] text-[#555]">취소</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileImageModal;
