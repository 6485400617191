import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

const Speed = keyframes`
    0% {
    transform: translate(0%);
    border-radius: 50%;
  }

  25% {
    transform: translate(150%) scale(0.5);
    border-radius: 0%;
  }

  50% {
    transform: translate(150%, 150%);
    border-radius: 50%;
  }

  75% {
    transform: translate(0, 150%) scale(0.5);
    border-radius: 0%;
  }
`;

const Backgroud = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(12, 4, 4, 0.7);
  z-index: 500;
`;

const Component = styled.div`
  width: 100px;
  height: 100px;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 600;
`;

const Span = styled.span`
  display: block;
  position: absolute;
  width: 40%;
  height: 40%;
  border-radius: 50%;
  animation: ${Speed} 2s infinite ease-in-out;
`;

const One = styled(Span)`
  background: #c9ba12;
  animation-delay: 1.5s;
`;
const Two = styled(Span)`
  background: #a11cc7;
  animation-delay: 1s;
`;
const Three = styled(Span)`
  background: #237ead;
  animation-delay: 0.5s;
`;
const Four = styled(Span)`
  background: #e0bcbc;
`;

const Label = styled.div`
  position: absolute;
  width: 100px;
  bottom: -40px;
  p {
    text-align: center;
    font-size: 18px;
    color: #e6f0ed;
    font-weight: 700;
  }
`;

interface SpinnerProps {
  progress?: number;
}

const Spinner = ({ progress }: SpinnerProps) => {
  return (
    <Backgroud>
      <Component>
        <One />
        <Two />
        <Three />
        <Four />
        {progress !== undefined ? (
          <Label>
            <p>{progress}%</p>
          </Label>
        ) : null}
      </Component>
    </Backgroud>
  );
};

export default Spinner;
