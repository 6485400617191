import { useAppSelector } from '../app/hooks';
import GroupWrapper from '../features/admin/userManager/GroupWrapper';
import SearchUser from '../features/admin/userManager/SearchUser';
import UserListTable from '../features/admin/userManager/UserListTable';

const UserManagePage = () => {
  const agency = useAppSelector((state) => state.navigation.profile.agency);
  return (
    <div className="w-full h-full overflow-auto px-[15px]">
      <div id="banner" className="w-full h-[80px] rounded-[10px] bg-[#056d48] flex items-center px-[40px]">
        <p className="text-[22px] text-[#e6f0ed] font-bold">{agency} 회원관리</p>
      </div>
      <div id="beige" className="flex w-full min-h-[700px] rounded-[10px] bg-[#efe7d6] mt-[40px] px-[100px] py-[50px]">
        <div className="w-full rounded-[10px] bg-white py-[40px] px-[60px]">
          <SearchUser />
          <div id="table-wapper" className="mt-[30px] flex w-full">
            <div id="group-wrapper" className="w-[20%] max-w-[220px]">
              <GroupWrapper />
            </div>
            <div id="user-wrapper" className="grow pl-[60px]">
              <UserListTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagePage;
