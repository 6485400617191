import { useEffect, useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  initTotalSequence,
  setPage,
} from '../features/etc/imageViewOption/imageViewOptionSlice';
import {
  getGroupImageApi,
  getUserImageListApi,
  initCheckedImages,
  initImageCards,
} from '../features/galleryManager/galleryManagerSlice';

import {
  addRenderingImageList,
  getUserImageListFromIdApi,
  initImageListAdmin,
} from '../features/admin/adminForm/adminFormSlice';

import {
  getUserGalleryApi,
  initImageList,
} from '../features/gallery/userGallery/userGallerySlice';
import type { AppDispatch, RootState } from './store';

export const useGroupImage = () => {
  const dispatch = useAppDispatch();

  const page = useAppSelector((state) => state.imageViewOption.page);
  const viewCount = useAppSelector((state) => state.imageViewOption.viewCount);
  const groupId = useAppSelector((state) => state.groupCard.selectedGroup);

  const isFirstRender = useRef(true); // useRef를 사용하여 초기 렌더링 여부를 추적

  useEffect(() => {
    if (!isFirstRender.current && groupId) {
      dispatch(
        getGroupImageApi({
          group_id: groupId,
          sequence: page,
          num_view: viewCount,
        }),
      );
    } else {
      isFirstRender.current = false;
    }
  }, [groupId, page, viewCount, dispatch]);

  useEffect(() => {
    dispatch(initImageCards());
    dispatch(initImageListAdmin());
  }, [groupId]);

  useEffect(() => {
    return () => {
      dispatch(initCheckedImages());
      dispatch(setPage(1));
      dispatch(initTotalSequence());
    };
  }, []);
};

export const useUserImage = () => {
  const dispatch = useAppDispatch();

  const page = useAppSelector((state) => state.imageViewOption.page);
  const viewCount = useAppSelector((state) => state.imageViewOption.viewCount);
  const userId = useAppSelector((state) => state.userCard.selectedUser);

  useEffect(() => {
    if (userId) {
      dispatch(
        getUserImageListApi({
          user_id: userId,
          sequence: page,
          viewCount: viewCount,
        }),
      );
    }
  }, [userId, page, viewCount, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(initCheckedImages());
      dispatch(setPage(1));
      dispatch(initTotalSequence());
    };
  }, []);
};

export const useMasonryAdmin = () => {
  const dispatch = useAppDispatch();

  const page = useAppSelector((state) => state.adminForm.page);
  const userId = useAppSelector((state) => state.userCard.selectedUser);

  const isFetching = useAppSelector(
    (state) => state.adminForm.loadingUserImgList,
  );

  const imageListData = useAppSelector(
    (state) => state.adminForm.userImageList.images,
  );

  function fetchMoreImage() {
    if (userId) {
      return dispatch(
        getUserImageListFromIdApi({ id: userId.toString(), sequence: page }),
      );
    }
  }

  function initImageEvent() {
    dispatch(initImageListAdmin());
    dispatch(addRenderingImageList(imageListData));
  }
  return {
    page,
    userId,
    isFetching,
    imageListData,
    fetchMoreImage,
    initImageEvent,
  };
};

export const useMasonryUser = () => {
  const imageListData = useAppSelector(
    (state) => state.userGallery.imageListData,
  );

  const isFetching = useAppSelector((state) => state.userGallery.isFetching);

  const dispatch = useAppDispatch();
  const page = useAppSelector((state) => state.userGallery.page);

  function fetchMoreImage() {
    dispatch(getUserGalleryApi(page));
  }

  function initImageEvent() {
    dispatch(initImageList());
  }

  function initEvent() {
    dispatch(setPage(1));
  }

  return {
    page,
    isFetching,
    imageListData,
    fetchMoreImage,
    initImageEvent,
    initEvent,
  };
};

// 원형의 useDispatch, useSelector 대신 아래를 사용한다.
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
