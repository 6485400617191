import { setPresentImage } from '../features/galleryManager/galleryManagerSlice';
import {
  initImageList,
  setImageInfo,
  setImageList,
  setLoadingSocket,
  setProgress,
} from '../features/image/imageGallery/imageGallerySlice';
import { initOptions } from '../features/image/imageOptions/imageOptionSlice';
import {
  deleteReferenceImage,
  initDenoisingStrength,
} from '../features/image/imageReference/imageReferenceSlice';
import { initImageSize } from '../features/image/imageSize/imageSizeSlice';
import {
  setMaterialId,
  setStyleId,
} from '../features/image/imageStyle/imageStylesSlice';
import { setNegativePrompt } from '../features/image/negativePrompt/negativePromptSlice';
import { setPrompt } from '../features/image/prompt/promptSlice';

export const socketMiddleware =
  (socket) => (store) => (next) => async (action) => {
    const { type } = action;

    switch (type) {
      case 'socket/connect':
        socket.connect(action.payload);

        // 소켓연결이후 서버로부터 수신 메시지 처리
        responseCreateAction(socket, next);

        break;
      case 'socket/sendMessage':
        socket.disconnect();

        setLoadingSpinner(true, 0, next);

        requestCreateAction(socket, action.payload);

        responseCreateAction(socket, next);

        break;
      case 'socket/presentation':
        socket.disconnect();

        setLoadingSpinner(true, 0, next);

        requestPresentationAction(socket);

        responsePresentationAction(socket, next);

        break;

      case 'socket/presentation/disconnect':
        socket.disconnect();
        break;

      case 'socket/disconnect':
        socket.disconnect();
        break;

      case 'account/login/fulfilled':
        next(setPrompt(''));
        next(setStyleId(null));
        next(setMaterialId(null));
        next(initImageList());
        next(deleteReferenceImage());
        next(initDenoisingStrength());
        next(initOptions());
        next(setNegativePrompt(''));
        next(initImageSize());

        break;

      default:
        break;
    }

    return next(action);

    function requestPresentationAction(socket) {
      const apiInfo = {
        api_url: 'wss://anutacanvas.com',
        api_url_dev: 'ws://10.100.100.137',
      };
      let api_url = apiInfo.api_url;
      const env = process.env.NODE_ENV;

      if (env === 'development') {
        api_url = apiInfo.api_url_dev;
      }
      socket.connect(`${api_url}/ws/demoshow`);

      socket.on('open', () => {
        // socket.send({ connect: false });
      });
    }

    function responsePresentationAction(socket, next) {
      socket.on('message', (data) => {
        const response = JSON.parse(data.data);
        console.log(response.payload);
        next(setPresentImage(response.payload));
      });
    }

    function requestCreateAction(socket, checkedMessage) {
      const apiInfo = {
        api_url: 'wss://anutacanvas.com',
        api_url_dev: 'ws://10.100.100.137',
      };
      let api_url = apiInfo.api_url;
      const env = process.env.NODE_ENV;

      if (env === 'development') {
        api_url = apiInfo.api_url_dev;
      }
      if (checkedMessage.init_image) {
        socket.connect(`${api_url}/ws/img2img`);
      } else {
        socket.connect(`${api_url}/ws/txt2img`);
      }
      socket.on('open', () => {
        socket.send(checkedMessage);
      });
    }

    function responseCreateAction(socket, next) {
      socket.on('message', (data) => {
        const response = JSON.parse(data.data);
        const responseCode = response.code;

        if (responseCode === '200') {
          const progressPercent = response.payload.progress_percent;
          if (!isNaN(progressPercent)) {
            const progress = (progressPercent * 100).toFixed(0);

            next(setProgress(Number(progress)));
          }

          //seed return시 이미지 생성완료
          if (response.payload.seed) {
            const image = {};
            image.prompt = response.payload.prompt;
            image.seed = response.payload.seed;
            image.image = response.payload.image[0];

            next(setImageList(image));
            next(setImageInfo(response.payload));
            next(setLoadingSocket(false));
          }
        } else if (responseCode === '400') {
        }
      });
    }

    function setLoadingSpinner(loadingSpinner, progressPercent, next) {
      next(setLoadingSocket(loadingSpinner));
      next(setProgress(progressPercent));
    }
  };
