import { IconChevronLeft, IconChevronRight } from './Icons';

interface PaginationProps {
  active: number;
  totalSequence: number;
  handlePageClick: (index: number) => void;
  handleNextPage: () => void;
  handlePrevPage: () => void;
}

const Pagination: React.FC<PaginationProps> = ({
  active,
  totalSequence,
  handlePageClick,
  handleNextPage,
  handlePrevPage,
}) => {
  const renderPageNumbers = () => {
    const start = Math.max(1, active - 2);
    const end = Math.min(totalSequence, start + 4);

    const pageNumbers = [];
    for (let i = start; i <= end; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`text-lg font-normal mx-1 ${
            active === i ? 'text-[#056D48] font-semibold' : ''
          }`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>,
      );
    }

    return pageNumbers;
  };

  return (
    <div className="flex items-center gap-4 text-[#AAAAAA]">
      <button
        className="flex "
        onClick={handlePrevPage}
        disabled={active === 1}
      >
        <IconChevronLeft strokeWidth={2} className="h-6 w-6" />
      </button>
      <div className="flex items-center gap-2">
        {active > 3 && (
          <>
            <button
              key={1}
              className="text-lg font-normal mx-1 "
              onClick={() => handlePageClick(1)}
            >
              1
            </button>
            <span className="mx-1 ">...</span>
          </>
        )}

        {renderPageNumbers()}

        {totalSequence > 5 && active < totalSequence - 2 && (
          <>
            <span className="mx-1 ">...</span>
            <button
              key={totalSequence}
              className="text-lg font-normal mx-1 "
              onClick={() => handlePageClick(totalSequence)}
            >
              {totalSequence}
            </button>
          </>
        )}
      </div>
      <button
        className="flex items-center gap-2"
        onClick={handleNextPage}
        disabled={active === totalSequence}
      >
        <IconChevronRight strokeWidth={2} className="h-6 w-6" />
      </button>
    </div>
  );
};

export default Pagination;
