import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}
interface ProfileState {
  isMainPage: boolean;
  profile: {
    email: string;
    name: string;
    date_joined: string;
    image_count: number;
    manager: boolean;
    profile_image: string;
    agency: string;
  };
}

const initialState: ProfileState = {
  isMainPage: true,
  profile: {
    email: '',
    name: '',
    date_joined: '',
    image_count: 0,
    manager: false,
    profile_image: '',
    agency: '',
  },
};

export const getProfileApi = createAsyncThunk('account/profile', async () => {
  const response = await fetch(`${apiInfo.api_url}/account/profile`);
  let json = await response.json();
  return json.payload;
});

export const getImageCountApi = createAsyncThunk(
  'account/imageCount',
  async () => {
    const response = await fetch(`${apiInfo.api_url}/image/count`);
    let data = await response.json();

    return data.payload;
  },
);

export const navigationSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setIsMainPage: (state, action) => {
      state.isMainPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileApi.pending, (state) => {})
      .addCase(getProfileApi.fulfilled, (state, action) => {
        state.profile = action.payload;
      })
      .addCase(getProfileApi.rejected, (state) => {})
      .addCase(getImageCountApi.pending, (state) => {})
      .addCase(getImageCountApi.fulfilled, (state, action) => {
        state.profile.image_count = action.payload;
      })
      .addCase(getImageCountApi.rejected, (state) => {});
  },
});

export const { setIsMainPage } = navigationSlice.actions;
export default navigationSlice.reducer;
