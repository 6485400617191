import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Image } from '../../../types/ImageCards';
import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

interface AdminFormState {
  page: number;
  loadingUserList: boolean;
  loadingGroupList: boolean;
  loadingCreateGroup: boolean;
  loadingUpdateUser: boolean;
  loadingUserImgList: boolean;
  selectedGroup: number | null;
  total_user_count: number;
  accountListData: {
    total_sequence: number;
    user_list: {
      id: number;
      name: string;
      account_id: string;
      date_joined: string;
      image_count: number;
      group: string;
    }[];
  };
  groupListData: {
    group_id: number;
    name: string;
    image_count: number;
    user_count: number;
  }[];
  successUpdateGroup: boolean;
  successUpdateUser: boolean;
  activeCreate: boolean;
  groupName: string;
  userImageList: {
    user_info: {
      name: string;
      date_joined: string;
      image_count: number;
      profile_image: string;
    } | null;
    images: Image[];
  };
  renderingImageList: number[];
  createUserMessage: string;
  createUserResult: 'warning' | 'success';
  isOpenUserCreateModal: boolean;
  isCreateUser: boolean;
  resultModalMessage: string;
  resultModalstatus: 'warning' | 'success';
  openResultModal: boolean;
}

const initialState: AdminFormState = {
  page: 1,
  loadingUserList: false,
  loadingGroupList: false,
  loadingCreateGroup: false,
  loadingUpdateUser: false,
  loadingUserImgList: false,
  selectedGroup: null,
  total_user_count: 0,
  accountListData: {
    total_sequence: 0,
    user_list: [],
  },
  groupListData: [],
  successUpdateGroup: false,
  successUpdateUser: false,
  activeCreate: false,
  groupName: '',
  userImageList: {
    user_info: null,
    images: [],
  },
  renderingImageList: [],
  createUserMessage: '',
  createUserResult: 'warning',
  isOpenUserCreateModal: false,
  isCreateUser: false,
  resultModalMessage: '',
  resultModalstatus: 'warning',
  openResultModal: false,
};

// 사용자 목록 조회
export const getAccountListApi = createAsyncThunk(
  'manager/accountlist',
  async (info: { groupId: number | null; sequence: number }) => {
    let url = '';
    if (info.groupId) {
      url = `${
        apiInfo.api_url
      }/manager/accountlist${`?group_id=${info.groupId}&sequence=${info.sequence}`}`;
    } else
      url = `${
        apiInfo.api_url
      }/manager/accountlist${`?sequence=${info.sequence}`}`;
    const response = await fetch(url);
    let data = await response.json();
    return data.payload;
  },
);

// 그룹 목록 조회
export const getGroupListApi = createAsyncThunk(
  'manager/getGroup',
  async () => {
    const response = await fetch(`${apiInfo.api_url}/manager/group`);
    let data = await response.json();
    return data.payload;
  },
);

// 그룹 생성
export const createGroupApi = createAsyncThunk(
  'manager/createGroup',
  async (name: string) => {
    const response = await fetch(`${apiInfo.api_url}/manager/group`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
      }),
    });
    let data = await response.json();
    return data;
  },
);

// 그룹 이름 변경
export const renameGroupApi = createAsyncThunk(
  'manager/renameGroup',
  async (info: { groupId: number; name: string }) => {
    const response = await fetch(`${apiInfo.api_url}/manager/group`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        group_id: info.groupId,
        name: info.name,
      }),
    });
    let data = await response.json();
    return data;
  },
);

// 사용자 생성
export const createUserApi = createAsyncThunk(
  'manager/createUser',
  async (info: {
    id: string;
    name: string;
    password: string;
    birth: string;
    group_id: number | null;
  }) => {
    const response = await fetch(`${apiInfo.api_url}/account/signup`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: info.id,
        name: info.name,
        password: info.password,
        birth: info.birth,
        group_id: info.group_id,
      }),
    });
    let data = await response.json();
    return data;
  },
);

// 사용자 이동
export const updateUsergroupApi = createAsyncThunk(
  'manager/updateUsergroup',
  async (info: { userId: number[]; groupId: number | null }) => {
    const response = await fetch(`${apiInfo.api_url}/manager/group`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: info.userId,
        group_id: info.groupId,
      }),
    });
    let data = await response.json();
    return data;
  },
);

// 사용자 삭제
export const deleteUserApi = createAsyncThunk(
  'UserManager/deleteUser',
  async (id: number[]) => {
    const response = await fetch(`${apiInfo.api_url}/account/withdrawal`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: id,
      }),
    });
    let data = await response.json();
    return data;
  },
);

// 사용자 검색
export const searchUserApi = createAsyncThunk(
  'manager/searchUser',
  async (info: {
    type: string;
    search: string;
    sequence: number;
    groupId: number | null;
  }) => {
    let url = '';
    if (info.groupId) {
      url = `${
        apiInfo.api_url
      }/manager/search${`?type=${info.type}&search=${info.search}&sequence=${info.sequence}&group_id=${info.groupId}`}`;
    } else
      url = `${
        apiInfo.api_url
      }/manager/search${`?type=${info.type}&search=${info.search}&sequence=${info.sequence}`}`;
    const response = await fetch(url);
    let data = await response.json();
    return data.payload;
  },
);

// 그룹 삭제
export const deleteGroupApi = createAsyncThunk(
  'manager/deleteGroup',
  async (groupId: number) => {
    const response = await fetch(`${apiInfo.api_url}/manager/group`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        group_id: groupId,
      }),
    });
    let data = await response.json();
    return data;
  },
);

// 사용자 갤러리 조회
export const getUserImageListFromIdApi = createAsyncThunk(
  'manager/image',
  async (info: { id: string; sequence: number }) => {
    const response = await fetch(
      `${apiInfo.api_url}/manager/image?user_id=${info.id}&sequence=${info.sequence}`,
    );
    let data = await response.json();
    return data.payload;
  },
);

// 사용자 비밀번호 초기화
export const initUserPassword = createAsyncThunk(
  'UserManager/initPassword',
  async (id: number) => {
    const response = await fetch(`${apiInfo.api_url}/manager/pwreset`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: id,
      }),
    });
    let data = await response.json();
    return data;
  },
);

const adminFormSlice = createSlice({
  name: 'AdminForm',
  initialState,
  reducers: {
    setActiveCreate: (state, action) => {
      state.activeCreate = action.payload;
    },
    setGroupName: (state, action) => {
      state.groupName = action.payload;
    },
    initImageListAdmin: (state) => {
      state.userImageList.images = [];

      state.loadingUserImgList = false;
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    addRenderingImageList: (state, action) => {
      state.renderingImageList.push(
        ...action.payload.map((item: Image) => item.id),
      );
    },
    initRenderingImageList: (state) => {
      state.renderingImageList = initialState.renderingImageList;
    },
    setIsOpenUserCreateModal: (state, action) => {
      state.isOpenUserCreateModal = action.payload;
    },
    closeDefaultModal: (state) => {
      state.openResultModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountListApi.pending, (state) => {
        state.loadingUserList = true;
        state.accountListData.total_sequence = 0;
        if (!state.selectedGroup) {
          state.total_user_count = 0;
        }
        state.accountListData.user_list = [];
      })
      .addCase(getAccountListApi.fulfilled, (state, action) => {
        state.loadingUserList = false;
        state.accountListData = action.payload;
        if (!state.selectedGroup) {
          state.total_user_count = action.payload.total_user_count;
        }
      })
      .addCase(getAccountListApi.rejected, (state) => {
        state.loadingUserList = false;
        state.accountListData.total_sequence = 0;
        state.accountListData.user_list = [];
        if (!state.selectedGroup) {
          state.total_user_count = 0;
        }
      })

      // 그룹 조회
      .addCase(getGroupListApi.pending, (state) => {
        state.loadingGroupList = true;
        state.groupListData = [];
      })
      .addCase(getGroupListApi.fulfilled, (state, action) => {
        state.loadingGroupList = false;
        state.groupListData = action.payload;
      })
      .addCase(getGroupListApi.rejected, (state) => {
        state.loadingGroupList = false;
        state.groupListData = [];
      })

      // 그룹 생성
      .addCase(createGroupApi.pending, (state) => {
        state.loadingCreateGroup = true;
        state.successUpdateGroup = false;
        state.resultModalstatus = 'warning';
        state.resultModalMessage = '다시 한번 확인해주세요.';
        state.openResultModal = false;
      })
      .addCase(createGroupApi.fulfilled, (state, action) => {
        state.loadingCreateGroup = false;
        state.successUpdateGroup = true;
        if (action.payload.code === '200') {
          state.resultModalstatus = 'success';
          state.resultModalMessage = '그룹이 생성되었습니다.';
          state.openResultModal = true;
        }
      })
      .addCase(createGroupApi.rejected, (state) => {
        state.loadingCreateGroup = false;
        state.successUpdateGroup = false;
      })

      // 그룹 이름 변경
      .addCase(renameGroupApi.pending, (state) => {
        state.successUpdateGroup = false;
        state.resultModalstatus = 'warning';
        state.resultModalMessage = '다시 한번 확인해주세요.';
        state.openResultModal = false;
      })
      .addCase(renameGroupApi.fulfilled, (state, action) => {
        state.successUpdateGroup = true;
        if (action.payload.code === '200') {
          state.resultModalstatus = 'success';
          state.resultModalMessage = '그룹 이름이 변경되었습니다.';
          state.openResultModal = true;
        }
      })
      .addCase(renameGroupApi.rejected, (state) => {
        state.successUpdateGroup = false;
      })

      // 사용자 생성
      .addCase(createUserApi.pending, (state) => {
        state.successUpdateUser = false;
        state.createUserMessage = '';
        state.isCreateUser = false;
        state.isOpenUserCreateModal = false;
        state.createUserResult = 'warning';
      })
      .addCase(createUserApi.fulfilled, (state, action) => {
        state.isOpenUserCreateModal = true;
        state.createUserMessage = action.payload.message;
        if (action.payload.code === '200') {
          state.successUpdateUser = true;
          state.createUserResult = 'success';
          state.isCreateUser = true;
          state.total_user_count += 1;
        } else {
          state.createUserResult = 'warning';
          state.isCreateUser = false;
        }
      })
      .addCase(createUserApi.rejected, (state) => {
        state.successUpdateUser = false;
        state.isOpenUserCreateModal = false;
        state.createUserMessage = '서버와의 연결을 확인해주세요.';
        state.createUserResult = 'warning';
      })

      // 사용자 이동
      .addCase(updateUsergroupApi.pending, (state) => {
        state.loadingUpdateUser = true;
        state.successUpdateUser = false;
        state.successUpdateGroup = false;
        state.resultModalstatus = 'warning';
        state.resultModalMessage = '다시 한번 확인해주세요.';
        state.openResultModal = false;
      })
      .addCase(updateUsergroupApi.fulfilled, (state, action) => {
        state.loadingUpdateUser = false;
        state.successUpdateUser = true;
        state.successUpdateGroup = true;
        if (action.payload.code === '200') {
          state.resultModalstatus = 'success';
          state.resultModalMessage = '사용자 그룹이 변경되었습니다.';
          state.openResultModal = true;
        }
      })
      .addCase(updateUsergroupApi.rejected, (state) => {
        state.loadingUpdateUser = false;
        state.successUpdateUser = false;
        state.successUpdateGroup = false;
      })

      // 사용자 삭제
      .addCase(deleteUserApi.pending, (state) => {
        state.successUpdateUser = false;
        state.successUpdateUser = false;
        state.resultModalstatus = 'warning';
        state.resultModalMessage = '다시 한번 확인해주세요.';
        state.openResultModal = false;
      })
      .addCase(deleteUserApi.fulfilled, (state, action) => {
        state.successUpdateUser = true;
        state.successUpdateUser = true;
        if (action.payload.code === '200') {
          state.resultModalstatus = 'success';
          state.resultModalMessage = '사용자가 삭제되었습니다.';
          state.openResultModal = true;
          state.total_user_count -= 1;
        }
      })
      .addCase(deleteUserApi.rejected, (state) => {
        state.successUpdateUser = false;
        state.successUpdateUser = false;
      })

      // 사용자 검색
      .addCase(searchUserApi.pending, (state) => {
        state.accountListData.total_sequence = 0;
        state.accountListData.user_list = [];
      })
      .addCase(searchUserApi.fulfilled, (state, action) => {
        state.accountListData = action.payload;
      })
      .addCase(searchUserApi.rejected, (state) => {
        state.accountListData.total_sequence = 0;
        state.accountListData.user_list = [];
      })

      // 사용자 비밀번호 초기화
      .addCase(initUserPassword.pending, (state) => {
        state.resultModalstatus = 'warning';
        state.resultModalMessage = '다시 한번 확인해주세요.';
        state.openResultModal = false;
      })
      .addCase(initUserPassword.fulfilled, (state, action) => {
        if (action.payload.code === '200') {
          state.resultModalstatus = 'success';
          state.resultModalMessage = '초기화가 완료 되었습니다.';
          state.openResultModal = true;
        }
      })
      .addCase(initUserPassword.rejected, (state) => {})

      // 그룹 삭제
      .addCase(deleteGroupApi.pending, (state) => {
        state.successUpdateGroup = false;
        state.resultModalstatus = 'warning';
        state.resultModalMessage = '다시 한번 확인해주세요.';
        state.openResultModal = false;
      })
      .addCase(deleteGroupApi.fulfilled, (state, action) => {
        state.successUpdateGroup = true;
        if (action.payload.code === '200') {
          state.resultModalstatus = 'success';
          state.resultModalMessage = '그룹이 삭제 되었습니다.';
          state.openResultModal = true;
        }
      })
      .addCase(deleteGroupApi.rejected, (state) => {
        state.successUpdateGroup = false;
      })

      // 사용자 갤러리 조회
      .addCase(getUserImageListFromIdApi.pending, (state) => {
        state.loadingUserImgList = true;
        state.userImageList = {
          user_info: null,
          images: [],
        };
      })
      .addCase(getUserImageListFromIdApi.fulfilled, (state, action) => {
        state.loadingUserImgList = false;
        state.userImageList = {
          user_info: action.payload.user_info,
          images: action.payload.images,
        };
        if (
          action.payload.images.length < 1 ||
          action.payload.images.length < 10
        ) {
          state.page = 0;
        } else {
          state.page += 1;
        }
      })
      .addCase(getUserImageListFromIdApi.rejected, (state) => {
        state.loadingUserImgList = false;
        state.userImageList = {
          user_info: null,
          images: [],
        };
      });
  },
});

export const {
  setActiveCreate,
  setGroupName,
  setPage,
  setSelectedGroup,
  initImageListAdmin,
  addRenderingImageList,
  initRenderingImageList,
  setIsOpenUserCreateModal,
  closeDefaultModal,
} = adminFormSlice.actions;
export default adminFormSlice.reducer;
