import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  setMaterialName,
  setResolutionName,
  setStyleName,
} from './selectedOptionsSlice';
import { ReactComponent as IconDeleteOptSVG } from '../../../assets/icons/icon-delete-option.svg';
import { setMaterialId, setStyleId } from '../imageStyle/imageStylesSlice';
import { initImageSize } from '../imageSize/imageSizeSlice';

const SelectedOptions = () => {
  const dispatch = useAppDispatch();
  const selectedOptions = useAppSelector(
    (state) => state.selectedOptions.selectedOptions,
  );

  const onClickDeleteOpt = (type: string) => {
    switch (type) {
      case 'resolution':
        dispatch(setResolutionName(''));
        dispatch(initImageSize());
        break;
      case 'material':
        dispatch(setMaterialName(''));
        dispatch(setMaterialId(null));
        break;
      case 'style':
        dispatch(setStyleName(''));
        dispatch(setStyleId(null));
        break;

      default:
        break;
    }
  };

  return (
    <div className="w-full h-[40px] flex items-center justify-center">
      {Object.values(selectedOptions).map((element, index) => {
        if (element) {
          return (
            <div
              key={element}
              className="relative group h-[40px] py-[10px] px-[15px] bg-white rounded-[100px] flex items-center justify-center mr-[5px] last:mr-0 cursor-pointer"
              onClick={() =>
                onClickDeleteOpt(Object.keys(selectedOptions)[index])
              }
            >
              <p className=" text-[20px] font-bold text-[#222] font-nps">
                {element}
              </p>
              <IconDeleteOptSVG className="absolute bottom-0 hidden group-hover:block" />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default SelectedOptions;
