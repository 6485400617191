import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Slice 이름 및 초기 상태 정의
interface SlideShowOptionState {
  rows: number; // 이미지를 몇 줄로 표시할지 개수
  dots: boolean; // 슬라이더 아래에 도트 네비게이션 버튼 표시 여부 (기본값: false)
  appendDots: string; // 네비게이션 버튼 변경을 위한 선택자
  dotsClass: string; // 네비게이션 버튼 클래스 변경
  infinite: boolean; // 무한반복 여부 (기본값: true)
  slidesToShow: number; // 한 번에 보여줄 슬라이드 아이템 개수
  slidesToScroll: number; // 한 번에 넘길 슬라이드 아이템 개수
  slidesPerRow: number; // 보여질 행의 수 (한 줄, 두 줄 ... )
  autoplay: boolean; // 슬라이드 자동 시작 여부 (기본값: false)
  autoplaySpeed: number; // 슬라이드 자동 넘기기 시간 (ms) (기본값: 2000ms = 2초)
  variableWidth: boolean; // 사진마다 width의 크기가 다른지 여부 (기본값: false)
  draggable: boolean; // 슬라이드 드래그 가능 여부 (기본값: true)
  arrows: boolean; // 이전 다음 버튼 표시 여부 (기본값: true)
  pauseOnFocus: boolean; // 마우스 클릭 시 슬라이드 멈춤 여부 (기본값: true)
  pauseOnHover: boolean; // 마우스 호버 시 슬라이드 멈춤 여부 (기본값: true)
  pauseOnDotsHover: boolean; // 네비게이션버튼 호버 시 슬라이드 멈춤 여부 (기본값: false)
  vertical: boolean; // 세로 방향 여부 (기본값: false)
  verticalSwiping: boolean; // 세로 방향 스와이프 여부 (기본값: false)
  accessibility: boolean; // 접근성 여부 (기본값: false)
  appendArrows: string; // 좌우 화살표 변경을 위한 선택자
  prevArrow: string; // 이전 화살표 변경을 위한 선택자
  nextArrow: string; // 다음 화살표 변경을 위한 선택자
  initialSlide: number; // 처음 보여질 슬라이드 번호 (기본값: 0)
  centerMode: boolean; // 중앙에 슬라이드가 보여지는 모드 (기본값: false)
  centerPadding: string; // 중앙에 슬라이드가 보여지는 모드에서 패딩 값
  fade: boolean; // 크로스페이드 모션 사용 여부 (기본값: false)
  speed: number; // 모션 시간 (ms) (기본값: 2000ms = 2초)
  waitForAnimate: boolean; // 애니메이션 중에는 동작을 제한함 (기본값: true)
  // lazyLoad: 'ondemand' | 'progressive' | 'anticipated'; // 이미지 로딩 방식 (기본값: 'ondemand')
}

const initialState: SlideShowOptionState = {
  rows: 1,
  dots: false,
  appendDots: '',
  dotsClass: 'custom-dots',
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  slidesPerRow: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  variableWidth: false,
  draggable: false,
  arrows: false,
  pauseOnFocus: false,
  pauseOnHover: false,
  pauseOnDotsHover: true,
  vertical: false,
  verticalSwiping: false,
  accessibility: true,
  appendArrows: '',
  prevArrow: '',
  nextArrow: '',
  initialSlide: 0,
  centerMode: false,
  centerPadding: '70px',
  fade: false,
  speed: 3000,
  waitForAnimate: true,
  // lazyLoad: 'ondemand',
};

const slideShowOptionSlice = createSlice({
  name: 'slideShow',
  initialState,
  reducers: {
    updateSlideShowSettings(
      state,
      action: PayloadAction<Partial<SlideShowOptionState>>,
    ) {
      return { ...state, ...action.payload };
    },
    initSlideShowSettings(state) {
      return { ...state, ...initialState };
    },
  },
});

export const { updateSlideShowSettings, initSlideShowSettings } =
  slideShowOptionSlice.actions;

export default slideShowOptionSlice.reducer;
