import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setLoginApi } from './loginFormSlice';
import Spinner from '../../../components/Spinner';
import Button from '../../../components/DefaultButton';

interface LoginFormProps {
  isPathname: boolean;
}

const LoginForm = ({ isPathname }: LoginFormProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // state
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [agencyCode, setAgencyCode] = useState('');

  const { isLogout } = useAppSelector((state) => state.LoginForm);
  const logoutAgency = useAppSelector((state) => state.LoginForm.logoutAgency);

  const onChangeUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };
  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onClickLoginButton = () => {
    dispatch(
      setLoginApi({
        email: username,
        password: password,
        agency: agencyCode,
      }),
    );
  };

  const handleOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onClickLoginButton();
    }
  };

  useEffect(() => {
    if (isLogout) {
      navigate(`/${logoutAgency}`);
    }
  }, [isLogout]);

  useEffect(() => {
    setAgencyCode(pathname.slice(1));
  }, [pathname]);

  return (
    <div
      id="login-form"
      className="w-[600px] h-[500px] bg-white rounded-[20px] flex flex-col items-center px-[100px] justify-center shadow-[0px_10px_10px_0px_rgba(0,0,0,0.10)]"
    >
      {isPathname ? (
        <>
          <p className="text-[36px] text-[#555] font-bold mb-[35px]">Login</p>
          <div id="username-wrap" className="w-full mb-[12px]">
            <input
              className="h-[50px] w-full text-[18px] text-[#aaa] py-[14px] px-[16px] border-1 border-[#ccc] rounded-[5px] focus:outline-none"
              type="text"
              placeholder="아이디"
              autoComplete="anuta-username"
              onChange={onChangeUser}
              onKeyDown={handleOnKeyPress}
            />
          </div>
          <div id="password-wrap" className="w-full mb-[12px]">
            <input
              className="h-[50px] w-full text-[18px] text-[#aaa] py-[14px] px-[16px] border-1 border-[#ccc] rounded-[5px] focus:outline-none"
              type="password"
              placeholder="비밀번호"
              autoComplete="anuta-password"
              onChange={onChangePassword}
              onKeyDown={handleOnKeyPress}
            />
          </div>
          <div id="button-form" className="w-full h-[50px]">
            <Button
              text="로그인 하기"
              type="submit"
              onClick={onClickLoginButton}
            />
          </div>
        </>
      ) : (
        <>
          <p className="text-[22px] font-bold text-[#404040] mb-[18px]">
            환영합니다 !
          </p>
          <p className="text-[22px] font-bold text-[#404040] mb-[18px]">
            서비스에 가입하신 <span className="text-[#089572]">기관이름</span>을
            확인해주세요
          </p>
          <p className="text-[22px] font-bold text-[#404040] mb-[18px]">
            https://anutacanvas.com/
            <span className="text-[#089572]">기관이름</span>
          </p>
        </>
      )}
    </div>
  );
};

export default LoginForm;
