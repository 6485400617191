import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setMaterialName } from '../selectedOptions/selectedOptionsSlice';
import { setMaterialId } from './imageStylesSlice';
import StyleItems from '../../../components/StyleItems';

const ImageMaterial = () => {
  const dispatch = useAppDispatch();
  const materialList = useAppSelector(
    (state) => state.imageStyles.materialList,
  );
  const selectedMaterial = useAppSelector(
    (state) => state.selectedOptions.selectedOptions.material,
  );

  const onClickMaterialImage = (id: number | null, name: string) => {
    dispatch(setMaterialName(name));
    dispatch(setMaterialId(id));
  };

  return (
    <div className="w-full h-full bg-white rounded-[10px] shadow-[0px_4px_4px_0px_#E8D9BB] p-[35px] overflow-y-auto grid grid-cols-2 gap-[40px]">
      {materialList.map((element) => {
        return (
          <StyleItems
            key={element.id}
            elName={element.name}
            elImg={element.image}
            isNew={element.new_update}
            selectedItem={selectedMaterial}
            onClickItem={() => onClickMaterialImage(element.id, element.name)}
          />
        );
      })}
    </div>
  );
};

export default ImageMaterial;
