import React from 'react';
import { useAppSelector } from '../app/hooks';
import MyPageLayout from '../features/user/editMypage/MyPageLayout';

const MyPage = () => {
  const username = useAppSelector((state) => state.navigation.profile.name);
  return (
    <div className="w-full h-full overflow-auto px-[15px]">
      <div
        id="banner"
        className="w-full h-[80px] rounded-[10px] bg-[#056d48] flex items-center px-[40px]"
      >
        <p className="text-[22px] text-[#e6f0ed] font-bold">{username}님</p>
      </div>
      <div
        id="beige"
        className="flex justify-center w-full min-h-[700px] rounded-[10px] bg-[#efe7d6] mt-[40px] px-[100px] py-[50px]"
      >
        <MyPageLayout />
      </div>
    </div>
  );
};

export default MyPage;
