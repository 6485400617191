import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Spinner from '../../components/Spinner';
import { initImageListAdmin } from '../admin/adminForm/adminFormSlice';
import { setSelectedGroup } from '../etc/group/groupCardSlice';
import { setSelectedUser } from '../etc/group/userCardSlice';
import { getGroupImageApi, initCheckedImages, initImageCards } from './galleryManagerSlice';
import ImageCard from './ImageCard';

import { useRef } from 'react';
import { initTotalSequence, setPage } from '../etc/imageViewOption/imageViewOptionSlice';

const GalleryManagerViewer = () => {
  const dispatch = useAppDispatch();

  const isFetching = useAppSelector((state) => state.galleryManager.isFetching);
  const imageCardsImage = useAppSelector((state) => state.galleryManager.imageCards.images);
  const page = useAppSelector((state) => state.imageViewOption.page);
  const viewCount = useAppSelector((state) => state.imageViewOption.viewCount);
  const groupId = useAppSelector((state) => state.groupCard.selectedGroup);
  const isFirstRender = useRef(true); // useRef를 사용하여 초기 렌더링 여부를 추적

  useEffect(() => {
    if (!isFirstRender.current && groupId) {
      dispatch(
        getGroupImageApi({
          group_id: groupId,
          sequence: page,
          num_view: viewCount,
        }),
      );
    } else {
      isFirstRender.current = false;
    }
  }, [groupId, page, viewCount, dispatch]);

  useEffect(() => {
    dispatch(initImageCards());
    dispatch(initImageListAdmin());
  }, [groupId]);

  useEffect(() => {
    return () => {
      dispatch(initCheckedImages());
      dispatch(setPage(1));
      dispatch(initTotalSequence());
      dispatch(initImageCards());
      dispatch(initImageListAdmin());
      dispatch(setSelectedUser(null));
      dispatch(setSelectedGroup(null));
    };
  }, []);

  return (
    <div className="w-full h-full flex flex-col   overflow-x-hidden  ">
      {isFetching ? <Spinner /> : <ImageCard images={imageCardsImage} />}
    </div>
  );
};
export default GalleryManagerViewer;
