import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setHeight, setWidth } from './imageSizeSlice';
import { setResolutionName } from '../selectedOptions/selectedOptionsSlice';
import { ReactComponent as IconSquareSVG } from '../../../assets/icons/icon-1024x1024.svg';
import { ReactComponent as IconHorizontal1SVG } from '../../../assets/icons/icon-1024x768.svg';
import { ReactComponent as IconHorizontal2SVG } from '../../../assets/icons/icon-1024x576.svg';
import { ReactComponent as IconVerticalSVG } from '../../../assets/icons//icon-768x1024.svg';

const ImageSize = () => {
  const dispatch = useAppDispatch();
  const resolutionName = useAppSelector(
    (state) => state.selectedOptions.selectedOptions.resolution,
  );

  const resolutionData = [
    {
      name: '가로형',
      description: '가로형',
      width: 1024,
      height: 768,
    },
    {
      name: '가로형2',
      description: '작은 가로형',
      width: 1024,
      height: 576,
    },
    {
      name: '세로형',
      description: '세로형',
      width: 768,
      height: 1024,
    },
    {
      name: '정사각형',
      description: '정사각형',
      width: 1024,
      height: 1024,
    },
  ];

  const printCanvas = (data: {
    name: string;
    description: string;
    width: number;
    height: number;
  }) => {
    switch (data.name) {
      case '가로형':
        return <IconHorizontal1SVG />;
      case '가로형2':
        return <IconHorizontal2SVG />;
      case '세로형':
        return <IconVerticalSVG />;
      case '정사각형':
        return <IconSquareSVG />;

      default:
        return null;
    }
  };

  const onClickResolution = (data: {
    name: string;
    description: string;
    width: number;
    height: number;
  }) => {
    dispatch(setWidth(data.width));
    dispatch(setHeight(data.height));
    dispatch(setResolutionName(data.description));
  };

  return (
    <div className="w-full h-full bg-white rounded-[10px] shadow-[0px_4px_4px_0px_#E8D9BB] p-[35px] overflow-y-auto grid grid-cols-2 gap-[40px]">
      {resolutionData.map((element) => {
        return (
          <div
            key={element.name}
            className={`"w-full h-full flex justify-center items-center relative cursor-pointer ${
              resolutionName === element.description
                ? '[&_path]:fill-[#ff0]'
                : null
            }`}
            onClick={() => onClickResolution(element)}
          >
            {printCanvas(element)}
            <div className="absolute flex flex-col items-center justify-center pointer-events-none">
              <p className="text-[#222] font-bold text-[22px]">
                {element.description}
              </p>
              <p className="text-[#888] text-[16px] font-medium">{`${element.width}x${element.height}`}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default ImageSize;
