import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import GroupCard from './GroupCard';
import { getGroupListApi } from './groupCardSlice';

interface GroupCardProps {
  userCard?: React.ReactNode; // userCard만 받도록 설정
  active?: boolean;
}

const GroupCardList: React.FC<GroupCardProps> = ({ userCard }) => {
  const dispatch = useAppDispatch();
  const groups = useAppSelector((state) => state.groupCard.groups);

  useEffect(() => {
    dispatch(getGroupListApi());
  }, [dispatch]);

  return (
    <div className="bg-[#EFE7D6] flex flex-col rounded-[10px] py-5 px-3 space-y-1 overflow-y-scroll ">
      {groups.map((group) => (
        <div key={group.group_id}>
          <GroupCard group={group} />
          {userCard && userCard}
        </div>
      ))}
    </div>
  );
};

export default GroupCardList;
