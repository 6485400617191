import { createSlice } from '@reduxjs/toolkit';

interface ImageReferenceState {
  referenceImage: string;
  denoisingStrength: number;
}

const initialState: ImageReferenceState = {
  referenceImage: '',
  denoisingStrength: 0.75,
};
export const imageReferenceSlice = createSlice({
  name: 'imageReference',
  initialState,
  reducers: {
    setReferenceImage: (state, action) => {
      state.referenceImage = action.payload;
    },
    setDenoisingStength: (state, action) => {
      state.denoisingStrength = action.payload;
    },
    deleteReferenceImage: (state) => {
      state.referenceImage = '';
    },
    initDenoisingStrength: (state) => {
      state.denoisingStrength = 0.75;
    },
  },
});

export const {
  setReferenceImage,
  deleteReferenceImage,
  setDenoisingStength,
  initDenoisingStrength,
} = imageReferenceSlice.actions;

export default imageReferenceSlice.reducer;
