import { useCallback, useEffect, useRef } from 'react';

/**
 * 사용방법 : const target = useInfiniteScroll( callback, 제어 변수:boolean)
 *
 * target에 viewport가 접근시 callback함수를 실행한다. boolean 타입의 제어변수로 연속적으로 호출되는것을 방지한다.
 *
 * 비동기 callback인경우 async()=>callback() 으로 호출한다.
 *
 * 예제)  const target = useInfiniteScroll( async () => dispatch(getUserGalleryApi(page)), isFetching);
 *
 */
export const useInfiniteScroll = (
  callback: () => void,
  isLoading: boolean[],
) => {
  const target = useRef<HTMLDivElement>(null);

  const observerCallback: IntersectionObserverCallback = useCallback(
    ([entry], observer) => {
      if (entry.isIntersecting && !isLoading.some((value) => value)) {
        observer.unobserve(entry.target);
        callback();
      }
    },
    [callback, isLoading],
  );

  useEffect(() => {
    if (!target) {
      return;
    }

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.8,
    }); //ointersection observer 인스턴스 생성

    if (target.current) {
      observer.observe(target.current); // 타겟 관찰 시작
    }

    return () => {
      if (target.current) {
        observer.unobserve(target.current);
      }
    };
  }, [target, observerCallback]);

  return target;
};
