import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

interface LoginFormState {
  isLogin: boolean;
  loginLoading: boolean;
  isLogout: boolean;
  profile: ProfileState;
  logoutAgency: string;
  successLogin: boolean;
}

interface ProfileState {
  email: string;
  name: string;
  date_joined: string;
  image_count: number;
  manager: boolean;
  profile_image: string;
}

const initialState: LoginFormState = {
  isLogin: false,
  loginLoading: false,
  isLogout: false,
  profile: {
    email: '',
    name: '',
    date_joined: '',
    image_count: 0,
    manager: false,
    profile_image: '',
  },
  logoutAgency: '',
  successLogin: false,
};

interface SetLoginParamType {
  email: string;
  password: string;
  agency: string;
}

export const setLoginApi = createAsyncThunk(
  'account/login',
  async (info: SetLoginParamType, labelSpinnerSlice) => {
    const response = await fetch(`${apiInfo.api_url}/account/login`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: info.email,
        password: info.password,
        agency: info.agency,
      }),
    });
    let data = await response.json();

    return data;
  },
);

export const setLogoutApi = createAsyncThunk('account/logout', async () => {
  const response = await fetch(`${apiInfo.api_url}/account/logout`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let data = await response.json();

  return data;
});

const LoginFormSlice = createSlice({
  name: 'LoginForm',
  initialState,
  reducers: {
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLoginApi.pending, (state) => {
        state.isLogin = false;
        state.loginLoading = true;
        state.successLogin = false;
        sessionStorage.removeItem('session_anuta');
      })
      .addCase(setLoginApi.fulfilled, (state, action) => {
        state.loginLoading = false;
        if (action.payload.code === '200') {
          state.isLogin = true;
          state.successLogin = true;
          state.profile = action.payload.payload;
          //action.payload.payload.date_joined('YYYY-MM-DD'를 'YYYY년 MM월 DD일 시작'으로 변경
          let date = new Date(action.payload.payload.date_joined);
          let year = date.getFullYear();
          let month = date.getMonth() + 1;
          let day = date.getDate();
          state.profile.date_joined = `${year}년 ${month}월 ${day}일 시작`;

          sessionStorage.setItem('session_anuta', action.payload.payload.name);
          sessionStorage.setItem(
            'session_expire',
            action.payload.payload.token_expire,
          );
          sessionStorage.setItem('isAdmin', action.payload.payload.manager);
        } else alert(action.payload.message);
      })
      .addCase(setLoginApi.rejected, (state) => {
        state.isLogin = false;
        state.loginLoading = false;
        state.successLogin = false;
        sessionStorage.removeItem('session_anuta');
        alert('서버 연결을 확인해 주세요.');
      })

      .addCase(setLogoutApi.pending, (state) => {
        state.isLogout = false;
        state.logoutAgency = '';
      })
      .addCase(setLogoutApi.fulfilled, (state, action) => {
        if (action.payload.code === '202') {
          state.isLogout = true;
          sessionStorage.removeItem('session_anuta');
          sessionStorage.removeItem('session_expire');
          sessionStorage.removeItem('isAdmin');
          state.isLogin = false;
        }
        state.logoutAgency = action.payload.payload;
      })
      .addCase(setLogoutApi.rejected, (state) => {
        state.isLogout = false;
        state.logoutAgency = '';
      });
  },
});

export const { setIsLogin } = LoginFormSlice.actions;

export default LoginFormSlice.reducer;
