import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { useInfiniteScroll } from '../../../common/utils/useInfiniteScroll';

import {
  copyNextElements,
  copyPreviousElements,
  getDeletePosition,
  getMinHeightMasonryColumn,
} from '../../../common/utils/masonryGalleryUtil';

import Spinner from '../../../components/Spinner';
import { ImageCards } from '../../../types/ImageCards';
import { ImageCard } from './ImageCard';
import ImageCardWithBtn from './ImageCardWithBtn';
import ImageBtnDelete from './ImageDeleteBtn';
import ImageBtnDown from './ImageDownBtn';

interface MasonryGalleryProps {
  imageListData: ImageCards['images'];
  actionEvent: () => void;
  initData: () => void;
  initEvent?: () => void;
  page: number;
  isFetching: boolean;
  checkable?: boolean;
  isBtnDelete?: boolean;
  isBtnDown?: boolean;
  isLoading?: boolean;
  isPrompt?: boolean;
}

const MasonryGallery = ({
  imageListData,
  actionEvent,
  initData,
  initEvent,
  page,
  isFetching,
  checkable = false,
  isBtnDelete = true,
  isBtnDown = true,
  isLoading = false,
  isPrompt = true,
}: MasonryGalleryProps) => {
  // masonry 레이아웃의 컬럼 수를 결정하는 상수
  // ex) 5 -> | * | * | * | * | * | * |
  //     4 -> |  *  |  *  |  *  |  *  |
  //     3 -> |   *   |   *   |   *   |
  //     2 -> |     *     |     *     |
  const COLUMN_COUNT = 5;
  const dispatch = useAppDispatch();

  const [isRendering, setIsRendering] = useState(false);

  //target에 접근하면 이미지 갤러리를 조회한다.
  const target = useInfiniteScroll(async () => actionEvent(), [isRendering, isFetching]);

  const initialColumnElements: JSX.Element[][] = Array.from({ length: COLUMN_COUNT }, () => []);
  const [masonryColumnElements, setMasonryColumnElements] = useState<Array<JSX.Element[]>>(initialColumnElements);

  const masonryColumnsRef = useRef<null[] | HTMLDivElement[]>([]);

  const [nextImage, setNextImage] = useState<number>(0);

  useEffect(() => {
    return () => {
      if (initEvent) {
        initEvent();
      }
    };
  }, [dispatch]);

  //이미지 데이터를 조회 후 masonry 레이아웃을 렌더링한다
  useLayoutEffect(() => {
    while (imageListData.length > 0 && imageListData.length > nextImage) {
      setIsRendering(true);
      const image = imageListData[nextImage];
      if (image) {
        const img = new Image();
        img.src = 'data:image/png;base64,' + image.image;

        //이미지 로드가 완료되면 이미지 카드를 렌더링한다.
        img.onload = (e) => {
          if (e.target) {
            const minIndex = getMinHeightMasonryColumn(COLUMN_COUNT, masonryColumnsRef);
            const ImageCardWithBton = ImageCardWithBtn(ImageCard);
            const updatedColumn = [
              ...masonryColumnElements[minIndex],
              <ImageCardWithBton
                key={image.id}
                image={image}
                imageElement={img}
                checkable={checkable}
                isPrompt={isPrompt}
                buttonPlacement={
                  <>
                    {isBtnDown && <ImageBtnDown image={image} />}
                    {isBtnDelete && <ImageBtnDelete image={image} />}
                  </>
                }
              />,
            ];

            //컬럼의 높이를 비교하여 가장 높이가 낮은 컬럼에 이미지 카드를 추가한다.
            setMasonryColumnElements((prevElements) => {
              const newElements = [...prevElements];
              newElements[minIndex] = updatedColumn;
              return newElements;
            });
            //다음 이미지를 렌더링한다.
            setNextImage((prev) => (prev !== undefined ? prev + 1 : 0));
          }
        };
        break;
      }
    }

    //이미지 카드를 전부 렌더링 한 다음 초기화
    if (imageListData.length > 0 && imageListData.length === nextImage) {
      initData();
      setNextImage(0);
      setIsRendering(false);
    }
  }, [imageListData, nextImage]);
  const deleteImageId = useAppSelector((state) => state.imageDelete.deleteImageId);

  // 이미지 카드를 삭제하면 해당 이미지 카드를 삭제한 뒤 렌더링한다.
  useLayoutEffect(() => {
    if (deleteImageId) {
      const { startColumn, startIndex } = getDeletePosition(masonryColumnElements, deleteImageId);
      if (startColumn >= 0 || startIndex >= 0) {
        const previouesElements = copyPreviousElements(masonryColumnElements, startColumn, startIndex, COLUMN_COUNT);

        setMasonryColumnElements(previouesElements);

        const nextElements = copyNextElements(masonryColumnElements, startColumn, startIndex);

        if (nextElements.length > 0 && nextElements[0] !== undefined) {
          setMasonryColumnElements((prevElements) => {
            const newElements = [...prevElements];

            // 가장 작은 컬럼의 인덱스를 구한다.
            const minIndex = newElements.reduce(
              (smallestIndex, currentArray, currentIndex) => {
                return currentArray.length < newElements[smallestIndex].length ? currentIndex : smallestIndex;
              },
              0, // 초기값은 첫 번째 배열의 인덱스로 설정
            );

            // 가장 작은 컬럼의 인덱스부터 순차적으로 nextElements를 넣어준다.
            for (let i = 0; i < nextElements.length; i++) {
              newElements[(minIndex + i) % newElements.length] = [
                ...newElements[(minIndex + i) % newElements.length],
                nextElements[i],
              ];
            }

            return newElements;
          });
        }
      }
    }
  }, [deleteImageId, masonryColumnElements]);

  return (
    <div className="w-full h-full overflow-auto ">
      {/* Body */}
      {isLoading && isFetching && <Spinner />}
      {/* {isRendering && <LabelSpinner isOpen={isRendering} />} */}
      <div className="flex items-start gap-x-[40px]">
        {masonryColumnElements.map((masonryColumn, index) => (
          <div
            key={index}
            className="w-full flex flex-col overflow-hidden"
            ref={(element) => {
              masonryColumnsRef.current[index] = element;
            }}
          >
            {masonryColumn}
          </div>
        ))}
      </div>
      {page > 0 ? <div ref={target} id="scroll-target" className="h-[100px]"></div> : null}
    </div>
  );
};

export default MasonryGallery;
