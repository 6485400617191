import { useEffect, useState } from 'react';

const usePagination = (
  initialPage: number,
  totalSequence: number,
  setPage: (page: number) => void,
) => {
  const [activePage, setActivePage] = useState(initialPage);

  const handlePageClick = (index: number) => {
    setActivePage(index);
    setPage(index);
  };

  const handleNextPage = () => {
    if (activePage === totalSequence) return;
    setActivePage((prevActive) => prevActive + 1);
    setPage(activePage + 1);
  };

  const handlePrevPage = () => {
    if (activePage === 1) return;
    setActivePage((prevActive) => prevActive - 1);
    setPage(activePage - 1);
  };

  useEffect(() => {
    setActivePage(initialPage);
  }, [initialPage]);

  return {
    activePage,
    handlePageClick,
    handleNextPage,
    handlePrevPage,
  };
};

export default usePagination;
