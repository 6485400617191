import { createSlice } from '@reduxjs/toolkit';
import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

interface BannerState {
  slideOptionsIsOpen: boolean;
  slideShowModalIsOpen: boolean;
}

const initialState: BannerState = {
  slideOptionsIsOpen: false,
  slideShowModalIsOpen: false,
};

const bannerSlice = createSlice({
  name: 'slideBanner',
  initialState,
  reducers: {
    setSlideOptionsModal: (state, action) => {
      state.slideOptionsIsOpen = action.payload;
    },
    setSlideShowModal: (state, action) => {
      state.slideShowModalIsOpen = action.payload;
    },
  },
});
export const { setSlideOptionsModal, setSlideShowModal } = bannerSlice.actions;
export default bannerSlice.reducer;
