import { useEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setSlideShowModal } from '../banner/bannerSlice';
import Carousel from './slideShowCarousel';
import { getSlideImageApi, initSlideImage } from './slideShowSlice';

const SlideShowModal = () => {
  const modalRef = useRef(null);
  const dispatch = useAppDispatch();
  const slideOptionSettings = useAppSelector(
    (state) => state.slideOptionSettings,
  );
  const checkedImages = useAppSelector(
    (state) => state.galleryManager.checkedImages,
  );

  const [isMouseMoving, setIsMouseMoving] = useState(false);
  const [slideShowImages, setSlideShowImages] = useState([{}]);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    let mouseTimer;

    const hideControls = () => {
      mouseTimer = setTimeout(() => {
        setIsMouseMoving(false);
      }, 3000);
    };

    const handleMouseMove = () => {
      setIsMouseMoving(true);
      clearTimeout(mouseTimer);
      hideControls();
    };

    const handleMouseLeave = () => {
      setIsMouseMoving(false);
    };

    hideControls();

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  //Carousel Component에 접근시 FullScreen 동작
  useEffect(() => {
    const { current } = modalRef;

    if (current) {
      current.requestFullscreen();
    }
  }, [modalRef]);

  useEffect(() => {
    let cancelFetch = false;
    let slideShow = [];

    const fetchData = async () => {
      for (let i = 0; i < checkedImages.length; i += 10) {
        const chunk = checkedImages.slice(i, i + 10);

        if (cancelFetch) {
          dispatch(initSlideImage());
          break;
        }

        const fetchedData = await dispatch(
          getSlideImageApi({ ids: chunk }),
        ).unwrap();
        slideShow.push(...fetchedData);
        if (i === 0) {
          setSlideShowImages(fetchedData);
        }
      }
      if (!cancelFetch) {
        setSlideShowImages(slideShow);
      }
    };

    fetchData();

    return () => {
      cancelFetch = true;
      dispatch(initSlideImage());
    };
  }, [checkedImages, dispatch]);

  const closeModal = () => {
    dispatch(initSlideImage());
    dispatch(setSlideShowModal(false));
  };

  // fullscreen 동작시 slideShowModalIsOpen을 false로 변경
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        dispatch(setSlideShowModal(false));
        dispatch(initSlideImage());
        closeModal();
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [dispatch]);

  return (
    <div
      ref={modalRef}
      className={`modal fixed inset-0 grid grid-cols-1 bg-black bg-opacity-50 ${
        isMouseMoving ? '' : 'cursor-none'
      }`}
    >
      <div className="modal-main bg-white rounded ">
        <Carousel
          slideShowImages={slideShowImages}
          settings={slideOptionSettings}
        />
      </div>
      {isMouseMoving && (
        <button
          className="absolute mt-4  h-10 w-10  text-white rounded-full  right-10  hover:bg-gray-300 hover:bg-opacity-50 "
          onClick={closeModal}
        >
          <span className="ri-close-line text-[25px]"></span>
          {/* <div className="text-white">{elapsedTime}</div> */}
        </button>
      )}
    </div>
  );
};

export default SlideShowModal;
