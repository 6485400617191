import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Image } from '../../../types/ImageCards';
import ImageDeleteModal from './ImageDeleteModal';
import { setDeleteClickId, setOpenDelete } from './imageDeleteSlice';

const ImageBtnDelete: React.FC<{ image: Image }> = ({ image }) => {
  const dispatch = useAppDispatch();
  const isOpenDelete = useAppSelector((state) => state.imageDelete.isOpenDelete);
  const deleteClickId = useAppSelector((state) => state.imageDelete.deleteClickId);

  const isDeleteModalOpen = () => {
    dispatch(setOpenDelete(true));
    dispatch(setDeleteClickId([image.id]));
  };

  return (
    <>
      {isOpenDelete && deleteClickId?.includes(image.id) && <ImageDeleteModal />}

      <button
        className=" w-1/2  border-1 border-[#3B403E] rounded-md px-2 py-1 text-[#AAAAAA] hover:bg-[#3B403E] hover:text-white"
        onClick={isDeleteModalOpen}
      >
        삭제하기
      </button>
    </>
  );
};
export default ImageBtnDelete;
