import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  getImageSelectApi,
  setImageId,
  setOpenDetail,
} from './userGallerySlice';
import {
  getDetailBestimgApi,
  setOpenBestDetailModal,
} from '../bestGallery/bestGallerySlice';
import {
  deleteImageApi,
  setDeleteClickId,
  setOpenDelete,
} from './imageDeleteSlice';
import DefaultModal from '../../image/DefaultModal';
interface ImageData {
  id: number;
  image: string;
  prompt?: string;
}

export interface ImageCardPrevProps {
  image: ImageData;
  imageElement: HTMLImageElement;
  pageType: 'bestGallery' | 'myGallery' | 'manageGallery';
}

export const ImageCardPrev: React.FC<ImageCardPrevProps> = ({
  image,
  imageElement,
  pageType,
}) => {
  const dispatch = useAppDispatch();
  const openDelete = useAppSelector((state) => state.imageDelete.isOpenDelete);
  const deleteClickId = useAppSelector(
    (state) => state.imageDelete.deleteClickId,
  );

  const onClickImg = useCallback(
    (id: number) => {
      switch (pageType) {
        case 'bestGallery':
          dispatch(getDetailBestimgApi(id));
          dispatch(setOpenBestDetailModal(true));
          break;
        case 'myGallery':
          dispatch(getImageSelectApi(id));
          dispatch(setOpenDetail(true));
          dispatch(setImageId(id));
          break;
        case 'manageGallery':
          break;

        default:
          break;
      }
    },
    [dispatch],
  );

  const onClickDownload = useCallback(
    (id: number) => {
      dispatch(getImageSelectApi(id)).then((res) => {
        const link = document.createElement('a');
        link.href = 'data:image/png;base64,' + res.payload.image;
        link.download = res.payload.seed + '.png';
        link.click();
      });
    },
    [dispatch],
  );

  const isDeleteModalOpen = useCallback(() => {
    dispatch(setOpenDelete(true));
    dispatch(setDeleteClickId([image.id]));
  }, []);

  const closeDeleteModal = () => {
    dispatch(setOpenDelete(false));
  };

  const confirmDeleteButton = () => {
    if (deleteClickId) {
      dispatch(deleteImageApi(deleteClickId));
      dispatch(setOpenDelete(false));
    }
  };

  const printImgCardOpt = () => {
    switch (pageType) {
      case 'bestGallery':
        return null;
      case 'myGallery':
        return (
          <>
            <div className="mt-2 h-[24px] overflow-hidden mb-[8px]">
              <p className="font-normal text-[#ccc] ">{image.prompt}</p>
            </div>
            <div className="card-footer flex w-full gap-[5px]">
              <a
                className="w-full h-[26px] flex items-center justify-center border-1 border-[#3B403E] rounded hover:bg-[#3B403E]"
                href={'data:image/png;base64,' + image.image}
                target=""
                onClick={() => onClickDownload(image.id)}
                rel="noopener noreferrer"
              >
                <p className="text-[14px] text-[#aaa]">다운로드</p>
              </a>

              <button
                className="w-full h-[26px] flex items-center justify-center border-1 border-[#3B403E] rounded hover:bg-[#3B403E]"
                onClick={isDeleteModalOpen}
              >
                <p className="text-[14px] text-[#aaa]">삭제하기</p>
              </button>
            </div>
          </>
        );
      case 'manageGallery':
        return null;

      default:
        return null;
    }
  };

  return (
    <div className="mb-[40px] last:mb-0">
      {/* 삭제 모달 */}
      {openDelete ? (
        <DefaultModal
          text="선택한 그림을 지우시겠습니까?"
          basic
          onClickCheck={closeDeleteModal}
          onClickConfirm={confirmDeleteButton}
        />
      ) : null}

      <div
        className="w-full flex relative group"
        onClick={() => onClickImg(image.id)}
      >
        <div
          id="border-wrapper"
          className="absolute w-full h-full bg-inherit rounded-[10px] group-hover:border-4 group-hover:border-[#F95E21]"
        />
        <img
          className="w-full object-fill rounded-[10px] cursor-pointer "
          alt={image.id.toString()}
          src={imageElement.src}
        />
      </div>
      {printImgCardOpt()}
    </div>
  );
};
