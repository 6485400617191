import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { GroupImage } from '../../../types/GroupImage';

import GroupCard from './GroupCard';
import { getGroupListApi, setGroupUser, setSelectedGroup } from './groupCardSlice';
import { addToggleGroups, initToggleGroups, removeToggleGroups } from './groupUserCardSlice';
import UserCardList from './UserCardList';
import { getUserListApi, setSelectedUser } from './userCardSlice';

const GroupUserCardList = () => {
  const dispatch = useAppDispatch();
  const toggleGroups = useAppSelector((state) => state.groupUserCard.toggleGroups);
  const groups = useAppSelector((state) => state.groupCard.groups);
  const isDeleteComplete = useAppSelector((state) => state.imageDelete.isDeleteComplete);
  useEffect(() => {
    dispatch(getGroupListApi());
    dispatch(initToggleGroups());
    dispatch(setSelectedUser(null));
    dispatch(setSelectedGroup(null));
  }, [dispatch]);

  const clickGroupItem = useCallback(
    (groupId: number) => () => {
      if (toggleGroups.includes(groupId)) {
        dispatch(removeToggleGroups(groupId));
        dispatch(setSelectedUser(null));
      } else {
        dispatch(addToggleGroups(groupId));

        dispatch(getUserListApi(groupId)).then((res) => {
          dispatch(setGroupUser({ groupId, users: res.payload.user_list }));
        });
      }
    },
    [dispatch, toggleGroups],
  );

  useEffect(() => {
    if (isDeleteComplete) {
      dispatch(getGroupListApi()).then((res) => {
        dispatch(addToggleGroups(toggleGroups));
        toggleGroups.forEach((groupId) => {
          dispatch(getUserListApi(groupId)).then((res) => {
            dispatch(setGroupUser({ groupId, users: res.payload.user_list }));
          });
        });
      });
    }
  }, [isDeleteComplete]);

  return (
    <div className="bg-[#EFE7D6] flex flex-col  rounded-[10px] py-5 px-3 space-y-1 overflow-y-scroll  ">
      {groups.map((group: GroupImage) => (
        <div key={group.group_id} className="bg-white rounded">
          <GroupCard key={group.group_id} group={group} clickEvent={clickGroupItem(group.group_id)} />
          {toggleGroups.includes(group.group_id) && <UserCardList group={group} />}
        </div>
      ))}
    </div>
  );
};

export default GroupUserCardList;
