export interface ImageState {
  image: string;
  prompt: string;
  negative_prompt: string;
  width: number;
  height: number;
  style: string;
  material: string;
  sampler_name: string;
  cfg_scale: number;
  steps: number;
  clip_skip?: number;
  seed: number;
  init_image?: string;
  denoising_strength?: number;
  create_date: string;
  user?: string;
}

export const initImageState: ImageState = {
  image: '',
  prompt: '',
  negative_prompt: '',
  width: 512,
  height: 512,
  style: '',
  material: '',
  sampler_name: '',
  cfg_scale: 0,
  steps: 0,
  clip_skip: 0,
  seed: -1,
  init_image: '',
  denoising_strength: 0,
  create_date: '',
  user: '',
};
