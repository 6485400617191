import { createSlice } from '@reduxjs/toolkit';

interface selectedOptionsState {
  selectedOptions: {
    resolution: string;
    material: string;
    style: string;
  };
}

const initialState: selectedOptionsState = {
  selectedOptions: {
    resolution: '',
    material: '',
    style: '',
  },
};

export const selectedOptionsSlice = createSlice({
  name: 'selectedOptions',
  initialState,
  reducers: {
    setResolutionName: (state, action) => {
      state.selectedOptions.resolution = action.payload;
    },
    setMaterialName: (state, action) => {
      state.selectedOptions.material = action.payload;
    },
    setStyleName: (state, action) => {
      state.selectedOptions.style = action.payload;
    },
    initOptions: () => {
      return initialState;
    },
  },
});

export const { setResolutionName, setMaterialName, setStyleName, initOptions } =
  selectedOptionsSlice.actions;

export default selectedOptionsSlice.reducer;
