import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { initRenderingImageList } from '../admin/adminForm/adminFormSlice';
import { addToggleGroups } from '../etc/group/groupUserCardSlice';
import { setSelectedUser } from '../etc/group/userCardSlice';
import ImageDeleteModal from '../gallery/userGallery/ImageDeleteModal';
import {
  setDeleteClickId,
  setOpenDelete,
} from '../gallery/userGallery/imageDeleteSlice';
import {
  clearManagerImage,
  initCheckedImages,
  selectManagerImage,
} from './galleryManagerSlice';

const Banner = () => {
  const dispatch = useAppDispatch();

  const [isAllChecked, setIsAllChecked] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const openDelete = useAppSelector((state) => state.imageDelete.isOpenDelete);
  const checkedImages = useAppSelector(
    (state) => state.galleryManager.checkedImages,
  );
  const renderingImageList = useAppSelector(
    (state) => state.adminForm.renderingImageList,
  );
  const selectedUser = useAppSelector((state) => state.userCard.selectedUser);
  const toggleGroups = useAppSelector(
    (state) => state.groupUserCard.toggleGroups,
  );
  const deleteComplete = useAppSelector(
    (state) => state.imageDelete.isDeleteComplete,
  );
  const agency = useAppSelector((state) => state.navigation.profile.agency);
  const deleteImages = () => {
    dispatch(setDeleteClickId(checkedImages));
    dispatch(setOpenDelete(true));
  };

  useEffect(() => {
    dispatch(initCheckedImages());
  }, [selectedUser]);

  useEffect(() => {
    if (checkedImages.length === 0) {
      setIsAllChecked(false);
    } else {
      const isAllRenderingImageIdsIdsIncludedIncheckedImages =
        checkedImages.every(
          (checkedImage) =>
            checkedImages.length === renderingImageList.length &&
            renderingImageList.includes(checkedImage),
        );

      if (isAllRenderingImageIdsIdsIncludedIncheckedImages) {
        setIsAllChecked(true);
      } else {
        setIsAllChecked(false);
      }
    }
  }, [checkedImages.length, renderingImageList]);

  useEffect(() => {
    dispatch(addToggleGroups(toggleGroups));
    dispatch(setSelectedUser(selectedUser));
    dispatch(initRenderingImageList());
    dispatch(initCheckedImages());
  }, [deleteComplete]);

  const clickAllChecked = () => {
    setIsAllChecked(!isAllChecked);
    const imageCardsIds = renderingImageList;
    if (isAllChecked) {
      dispatch(clearManagerImage());
    } else {
      dispatch(selectManagerImage(imageCardsIds));
    }
  };

  useEffect(() => {
    if (checkedImages.length > 0) {
      setDisableBtn(false);
    } else setDisableBtn(true);
  }, [checkedImages]);

  return (
    <div className="w-full h-20 rounded-[10px] bg-[#056D48] flex flex-col flex-none md:flex-row justify-between items-center">
      <div className="ml-10">
        <p className="text-[22px] font-medium text-white">
          {agency} 갤러리 관리
        </p>
      </div>
      <div className="flex items-center justify-start ml-10">
        <div className="flex items-center space-x-1">
          <input
            type="checkbox"
            className="w-5 h-5 mr-1"
            checked={isAllChecked}
            onChange={clickAllChecked}
          />

          <span className="text-white text-md pr-3 ">전체선택</span>

          <span className="text-[#FFFF10] text-lg mr-1">
            {checkedImages.length}{' '}
          </span>
          <span className="text-white ">개 선택됨</span>
        </div>

        <div>
          {openDelete ? <ImageDeleteModal /> : null}
          <button
            className={`mr-10 md:ml-7 w-36 md:w-40 h-12 bg-[#FFFF10] rounded-lg text-black text-lg font-medium hover:bg-[#FDFD6A] ${
              disableBtn ? 'cursor-not-allowed' : ''
            }`}
            onClick={deleteImages}
            disabled={disableBtn}
          >
            {'선택 이미지 삭제'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
