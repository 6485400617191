import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ReactComponent as IconSlideOptionSVG } from '../../../assets/icons/icon-slide-option.svg';
import {
  clearSlideImages,
  selectSlideImages,
} from '../../galleryManager/galleryManagerSlice';

import SlideShowModal from '../slideImage/SlideShowModal';
import SlideOptionModal from '../slideImage/SlideShowOptionModal';
import { setSlideOptionsModal, setSlideShowModal } from './bannerSlice';

const Banner = () => {
  const dispatch = useAppDispatch();
  const slideOptionsIsOpen = useAppSelector(
    (state) => state.banner.slideOptionsIsOpen,
  );
  const totalGroupCount = useAppSelector(
    (state) => state.groupCard.totalGroupCount,
  );
  const totalImageCount = useAppSelector(
    (state) => state.groupCard.totalImageCount,
  );

  const checkedImages = useAppSelector(
    (state) => state.galleryManager.checkedImages,
  );

  const slideshowIsOpen = useAppSelector(
    (state) => state.banner.slideShowModalIsOpen,
  );
  const imageCards = useAppSelector(
    (state) => state.galleryManager.imageCards.images,
  );
  const page = useAppSelector((state) => state.imageViewOption.page);
  const agency = useAppSelector((state) => state.navigation.profile.agency);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const clickAllChecked = () => {
    setIsAllChecked(!isAllChecked);
    if (isAllChecked) {
      const groupImageIds = imageCards.map((item) => item.id);
      dispatch(clearSlideImages(groupImageIds));
    } else {
      dispatch(selectSlideImages(imageCards));
    }
  };

  const handleSlideShow = () => {
    if (checkedImages.length === 0) {
      alert('슬라이드쇼를 시작할 이미지를 선택해주세요.');
      return;
    }
    dispatch(setSlideShowModal(true));
  };

  const handleSlideOptionClick = () => {
    dispatch(setSlideOptionsModal(true));
  };

  useEffect(() => {
    if (checkedImages.length === 0) {
      setIsAllChecked(false);
    } else {
      const groupImageIds = imageCards.map((item) => item.id);
      const isAllgroupImageIdsIdsIncludedIncheckedImages = groupImageIds.every(
        (id) => checkedImages.includes(id),
      );
      if (isAllgroupImageIdsIdsIncludedIncheckedImages) {
        setIsAllChecked(true);
      } else {
        setIsAllChecked(false);
      }
    }
  }, [page, checkedImages.length, imageCards]);

  return (
    <div className="w-full h-20 rounded-[10px] bg-[#056D48] flex flex-col flex-none md:flex-row justify-between items-center">
      <div className="ml-10">
        <p className="text-[22px] font-medium text-white">
          {agency} 슬라이드쇼 관리
        </p>
      </div>
      <div className="w-auto flex items-center">
        <div className="flex items-center space-x-1">
          <input
            type="checkbox"
            className="w-5 h-5"
            checked={isAllChecked}
            onChange={clickAllChecked}
          />
          <span className="text-white pr-3 ">전체선택</span>
          <span className="text-[#FFFF10] text-lg">
            {checkedImages.length}{' '}
          </span>
          <span className="text-white pr-3">개 선택됨</span>
        </div>
        <div
          className="flex items-center pr-6  cursor-pointer"
          onClick={handleSlideOptionClick}
        >
          <IconSlideOptionSVG />
          <span className="text-white text-md pl-1  ">슬라이드쇼 옵션</span>
        </div>
        {/* 슬라이드 옵션 모달 */}
        {slideOptionsIsOpen && <SlideOptionModal />}
        <div className="flex items-center pr-6 ">
          <span className="text-[#00FFA4] mr-2 ">총</span>
          <span className="text-[#FFFF10] mr-1 ">{totalGroupCount}</span>
          <span className="text-[#00FFA4] mr-2">개반</span>
          <span className="text-[#FFFF10] mr-1">{totalImageCount}</span>
          <span className="text-[#00FFA4] ">이미지</span>
        </div>
        <div>
          <button
            className="mr-10 md:ml-7 w-36 md:w-40 h-12 bg-[#FFFF10] rounded-lg text-black text-lg font-medium hover:bg-[#FDFD6A]"
            onClick={handleSlideShow}
          >
            슬라이드쇼 하기
          </button>
          {slideshowIsOpen && <SlideShowModal />}
        </div>
      </div>
    </div>
  );
};

export default Banner;
