import React, { useEffect, useState } from 'react';
import Select from '../../../components/Select';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { searchUserApi } from '../adminForm/adminFormSlice';
import { setPage, setSearchKeyword, setSearchType } from './userManagerSlice';

const SearchUser = () => {
  const dispatch = useAppDispatch();
  const searchType = useAppSelector((state) => state.userManager.searchType);
  const search = useAppSelector((state) => state.userManager.searchKeyword);
  const groupId = useAppSelector((state) => state.adminForm.selectedGroup);
  const OPTIONS = [
    { id: 1, value: '이름', type: 'name' },
    { id: 2, value: '아이디', type: 'id' },
  ];

  const onClickSelect = (type: string) => {
    dispatch(setSearchType(type));
  };

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchKeyword(event.target.value));
  };

  const handleOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onClickSearchButton();
    }
  };

  const onClickSearchButton = () => {
    // 검색버튼 클릭시 페이지 넘버 1로 초기화
    dispatch(setPage(1));
    dispatch(
      searchUserApi({
        type: searchType,
        search,
        sequence: 1,
        groupId,
      }),
    );
  };

  return (
    <div className="w-full h-[90px] rounded-[10px] bg-[#F8F6F2] flex items-center justify-center">
      <p className="text-[18px] text-[#555555] font-bold">회원검색</p>
      <div className="mx-[10px] w-[120px] h-[50px] z-30">
        <Select
          options={OPTIONS}
          placehorder="이름"
          defaultValue="이름"
          createMode
          onClickSearch={onClickSelect}
        />
      </div>
      <input
        className="w-[400px] h-[50px] border border-[#e9e7e3] rounded-[5px] px-[10px] mr-[10px] bg-white text-[18px] text-[#555555] placeholder:text-[#aaaaaa] focus:outline-none"
        type="text"
        placeholder="검색어를 입력하세요"
        onChange={handleSearchInput}
        onKeyDown={handleOnKeyPress}
      />
      <button
        className="w-[100px] h-[50px] bg-[#ebebea] rounded-[5px] text-[18px] text-[#555555] font-semibold"
        onClick={onClickSearchButton}
      >
        검색
      </button>
    </div>
  );
};

export default SearchUser;
