import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GroupImage } from '../../../types/GroupImage';
import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

interface GroupCardState {
  isFetching: boolean;
  groups: GroupImage[];
  totalGroupCount: number;
  totalImageCount: number;
  selectedGroup?: number;
}

const initialState: GroupCardState = {
  isFetching: false,
  groups: [],
  totalGroupCount: 0,
  totalImageCount: 0,
  selectedGroup: undefined,
};

export const getGroupListApi = createAsyncThunk('group/list', async () => {
  const response = await fetch(`${apiInfo.api_url}/manager/group`);
  let data = await response.json();

  return data.payload;
});

const groupCardSlice = createSlice({
  name: 'groupCard',
  initialState,
  reducers: {
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },

    setGroupUser: (state, action) => {
      state.groups = state.groups.map((group) => {
        if (group.group_id === action.payload.groupId) {
          group.users = action.payload.users;
        }
        return group;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      // 갤러리 조회
      .addCase(getGroupListApi.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getGroupListApi.fulfilled, (state, action) => {
        state.isFetching = false;
        state.groups = action.payload;
        state.totalGroupCount = action.payload.length;
        state.totalImageCount = state.groups.reduce((acc, cur) => {
          return acc + cur.image_count;
        }, 0);
      })
      .addCase(getGroupListApi.rejected, (state, action) => {
        state.isFetching = false;
        state.groups = [];
      });
  },
});
export const { setSelectedGroup, setGroupUser } = groupCardSlice.actions;
export default groupCardSlice.reducer;
