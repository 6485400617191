import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export interface SlideShoweState {
  slideShowImages: string[]; //슬라이드쇼의 원본 이미지를 담을 배열
  isFetching: boolean;
}

const initialState: SlideShoweState = {
  slideShowImages: [],
  isFetching: false,
};

// 이미지 ID를 여러 번 나누어서 요청하고 그 결과를 합치는 비동기 액션 생성 함수
export const getSlideImageApi = createAsyncThunk(
  'manager/showimage',
  async (info: { ids: number[] }) => {
    const response = await fetch(
      `${apiInfo.api_url}/manager/showimage?id=[${info.ids.join(',')}]`,
    );
    let data = await response.json();
    return data.payload;
  },
);

const slideImageCardSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    initSlideImage: (state) => {
      state.slideShowImages = [];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getSlideImageApi.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSlideImageApi.fulfilled, (state, action) => {
        state.isFetching = false;

        state.slideShowImages.push(...action.payload);
      })
      .addCase(getSlideImageApi.rejected, (state) => {
        state.isFetching = false;
        state.slideShowImages = [];
      });
  },
});

export const { initSlideImage } = slideImageCardSlice.actions;

export default slideImageCardSlice.reducer;
