import { createSlice } from '@reduxjs/toolkit';

interface ImageViewOptionState {
  viewCount: number;
  page: number;
  totalSequence: number;
}

const initialState: ImageViewOptionState = {
  viewCount: 20,
  page: 1,
  totalSequence: 0,
};

const imageViewOptionSlice = createSlice({
  name: 'imageOption',
  initialState,
  reducers: {
    setViewCount: (state, action) => {
      state.viewCount = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    initTotalSequence: (state) => {
      state.totalSequence = initialState.totalSequence;
    },
  },
});
export const { setViewCount, setPage, initTotalSequence } =
  imageViewOptionSlice.actions;
export default imageViewOptionSlice.reducer;
