import { configureStore } from '@reduxjs/toolkit';

import { Socket } from '../common/utils/Socket';
import imageListReducer from '../features/image/imageGallery/imageGallerySlice';

import referenceImageReducer from '../features/image/imageReference/imageReferenceSlice';
import sizeReducer from '../features/image/imageSize/imageSizeSlice';

import adminFormSlice from '../features/admin/adminForm/adminFormSlice';
import groupCardReducer from '../features/etc/group/groupCardSlice';
import groupUserCardReducer from '../features/etc/group/groupUserCardSlice';
import userCardReducer from '../features/etc/group/userCardSlice';
import imageViewOptionReducer from '../features/etc/imageViewOption/imageViewOptionSlice';
import navigationReducer from '../features/etc/navigation/navigationSlice';
import userGalleryReducer from '../features/gallery/userGallery/userGallerySlice';
import galleryManagerReducer from '../features/galleryManager/galleryManagerSlice';
import imageOptionsReducer from '../features/image/imageOptions/imageOptionSlice';
import imageStylesReducer from '../features/image/imageStyle/imageStylesSlice';
import nagativePromptReducer from '../features/image/negativePrompt/negativePromptSlice';
import promptReducer from '../features/image/prompt/promptSlice';
import selectedOptionsReducer from '../features/image/selectedOptions/selectedOptionsSlice';
import bannerSlice from '../features/slideShow/banner/bannerSlice';

import userManagerReducer from '../features/admin/userManager/userManagerSlice';
import imageDeleteReducer from '../features/gallery/userGallery/imageDeleteSlice';
import slideShowOptionReducer from '../features/slideShow/slideImage/slideShowOptionSlice';
import slideShowImageReducer from '../features/slideShow/slideImage/slideShowSlice';
import editMypageSlice from '../features/user/editMypage/editMypageSlice';
import loginFormReducer from '../features/user/LoginForm/loginFormSlice';
import signupFormReducer from '../features/user/signupForm/signupFormSlice';
import bestGalleryReducer from '../features/gallery/bestGallery/bestGallerySlice';
import { socketMiddleware } from '../middleware/socket';

export const store = configureStore({
  reducer: {
    imageSize: sizeReducer,
    prompt: promptReducer,
    imageList: imageListReducer,
    referenceImage: referenceImageReducer,
    imageOptions: imageOptionsReducer,
    nagativePrompt: nagativePromptReducer,
    imageStyles: imageStylesReducer,
    userGallery: userGalleryReducer,
    LoginForm: loginFormReducer,
    signupForm: signupFormReducer,
    adminForm: adminFormSlice,
    navigation: navigationReducer,
    editMypage: editMypageSlice,
    selectedOptions: selectedOptionsReducer,
    slideImage: slideShowImageReducer,
    groupCard: groupCardReducer,
    slideOptionSettings: slideShowOptionReducer,
    banner: bannerSlice,
    userManager: userManagerReducer,
    userCard: userCardReducer,
    groupUserCard: groupUserCardReducer,
    imageViewOption: imageViewOptionReducer,
    galleryManager: galleryManagerReducer,
    bestGallery: bestGalleryReducer,
    imageDelete: imageDeleteReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(socketMiddleware(new Socket())),
});

//추후에 필요에 따라 참조할 수 있도록 RootState type과 Dispatch type을 추출함.
//스토어에서 이러한 타입들을 추론한다는 것은 state slice를 추가하거나 미들웨어 세팅을 수정할 때 올바르게 업데이트 된다는 것을 의미

//RooteState와 AppDispatch가 스토어 자체에서 추론된다.
export type RootState = ReturnType<typeof store.getState>;
// 추론된 타입:
// Inferred type: {imageSize: ImageSizeStates, prompt: PromptState, imageList: ImageListState ...}
export type AppDispatch = typeof store.dispatch;

export default store;
