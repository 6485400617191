import Banner from '../features/gallery/userGallery/Banner';
import UserGallery from '../features/gallery/userGallery/UserGallery';

const GalleryPage = () => {
  return (
    <div className="w-full h-full flex flex-col overflow-hidden px-[15px]">
      <Banner />
      <div id="body" className="w-full h-full overflow-auto mt-[40px]">
        <UserGallery />
      </div>
    </div>
  );
};

export default GalleryPage;
