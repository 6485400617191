import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ImageCards } from '../../types/ImageCards';
import config from '../config.api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

interface galleryManagerState {
  checkedImages: number[];
  sequence?: number;
  num_view?: number;
  imageCards: ImageCards;
  isFetching: boolean;
}

const initialState: galleryManagerState = {
  checkedImages: [],
  imageCards: {
    totalSequence: 0,
    images: [],
  },
  isFetching: false,
};

// 그룹별 갤러리 조회
export const getGroupImageApi = createAsyncThunk(
  'manager/groupImage',
  async (info: { group_id: number; sequence: number; num_view: number }) => {
    const response = await fetch(
      `${apiInfo.api_url}/manager/slideshow?group_id=${info.group_id}&sequence=${info.sequence}&num_view=${info.num_view}`,
    );
    let data = await response.json();
    return data.payload;
  },
);
// 유저별 갤러리 조회
export const getUserImageListApi = createAsyncThunk(
  'userImage/list',

  async (param: { user_id: number; sequence: number; viewCount: number }) => {
    const response = await fetch(
      `${apiInfo.api_url}/manager/image?user_id=${param.user_id}&sequence=${param.sequence}`,
    );
    let data = await response.json();

    return data.payload;
  },
);

const galleryManagerSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    selectSlideImages: (state, action) => {
      // 전체 선택 액션
      action.payload.forEach((element: any) => {
        const index = state.checkedImages.findIndex(
          (image) => image === element.id,
        );
        if (index === -1) {
          state.checkedImages.push(element.id);
        }
      });
      state.checkedImages.sort((a, b) => b - a);
    },
    clearSlideImages: (state, action) => {
      // 전체 해제 액션
      state.checkedImages = state.checkedImages.filter(
        (id) => !action.payload.includes(id),
      );
    },
    selectManagerImage: (state, action) => {
      // 이미지 전체 선택 액션
      state.checkedImages = action.payload;
    },
    clearManagerImage: (state) => {
      // 이미지 전체 해제 액션
      state.checkedImages = [];
    },

    initCheckedImages: (state) => {
      state.checkedImages = [];
    },
    initImageCards: (state) => {
      state.imageCards = initialState.imageCards;
    },
    setImageCards: (state, action) => {
      state.imageCards.images = action.payload;
    },
    setChecked: (state, action) => {
      const index = state.checkedImages.findIndex(
        (image) => image === action.payload,
      );
      if (index !== -1) {
        state.checkedImages.splice(index, 1);
      } else {
        state.checkedImages.push(action.payload);
      }
      state.checkedImages.sort((a, b) => a - b);

      state.checkedImages.sort((a, b) => b - a);
    },
    setPresentImage: (state, action) => {
      state.checkedImages.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      // 그룹이미지 조회
      .addCase(getGroupImageApi.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getGroupImageApi.fulfilled, (state, action) => {
        state.isFetching = false;

        if (action.payload.images.length > 0) {
          state.imageCards.totalSequence = action.payload.total_sequence;
          state.imageCards.images = action.payload.images;
        } else {
          state.isFetching = false;
        }
      })
      .addCase(getGroupImageApi.rejected, (state) => {
        state.isFetching = false;
        state.imageCards = initialState.imageCards;
      })

      // 유저 이미지 조회
      .addCase(getUserImageListApi.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getUserImageListApi.fulfilled, (state, action) => {
        state.isFetching = false;

        if (action.payload.images.length > 0) {
          state.imageCards.totalSequence = action.payload.total_sequence;
          state.imageCards.images = action.payload.images;
        } else {
          state.isFetching = false;
        }
      })
      .addCase(getUserImageListApi.rejected, (state) => {
        state.isFetching = false;
        state.imageCards = initialState.imageCards;
      });
  },
});

export const {
  selectSlideImages,
  clearSlideImages,
  selectManagerImage,
  clearManagerImage,
  initCheckedImages,
  initImageCards,
  setImageCards,
  setChecked,
  setPresentImage,
} = galleryManagerSlice.actions;

export default galleryManagerSlice.reducer;
