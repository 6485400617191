import React, { useEffect, useState } from 'react';
import ChangePassword from './ChangePassword';
import UserInfo from './UserInfo';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import DefaultModal from '../../image/DefaultModal';
import { closeResultModal } from './editMypageSlice';

const MyPageLayout = () => {
  const dispatch = useAppDispatch();
  const openResultModal = useAppSelector(
    (state) => state.editMypage.resultModalOpen,
  );
  const modalMessage = useAppSelector((state) => state.editMypage.modalMessage);
  const modalStatus = useAppSelector((state) => state.editMypage.modalStatus);

  const [editType, setEditType] = useState(0);

  return (
    <div
      id="modal-layout"
      className="w-[600px] h-[480px] bg-white rounded-[20px]"
    >
      {openResultModal ? (
        <DefaultModal
          type={modalStatus}
          text={modalMessage}
          onClickCheck={() => dispatch(closeResultModal())}
        />
      ) : null}
      <div className="w-full h-[90px] flex border-b border-[#eeeeee]">
        <button
          className={`w-1/2 h-full border-r border-r-[#eeeeee] text-[20px] ${
            editType === 0
              ? `border-b-4 border-b-black font-semibold text-[#222222]`
              : null
          }`}
          onClick={() => setEditType(0)}
        >
          개인정보 수정
        </button>
        <button
          className={`w-1/2 h-full text-[20px] ${
            editType === 1
              ? `border-b-4 border-b-black font-semibold text-[#222222]`
              : null
          }`}
          onClick={() => setEditType(1)}
        >
          비밀번호 수정
        </button>
      </div>
      {editType ? <ChangePassword /> : <UserInfo />}
    </div>
  );
};

export default MyPageLayout;
