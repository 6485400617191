import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import DetailImage from './DetailImage';
import Masonry from '../../../components/Masonry';
import { useEffect } from 'react';
import { getUserGalleryApi } from './userGallerySlice';
import Spinner from '../../../components/Spinner';

const UserGallery = () => {
  const dispatch = useAppDispatch();
  // state
  const loadingImgList = useAppSelector(
    (state) => state.userGallery.loadingImgList,
  );
  const isOpenDetail = useAppSelector(
    (state) => state.userGallery.isOpenDetail,
  );
  const loadingDetail = useAppSelector(
    (state) => state.userGallery.loadingDetail,
  );
  const imageDetailData = useAppSelector(
    (state) => state.userGallery.imageSelectData,
  );
  const imageListData = useAppSelector(
    (state) => state.userGallery.imageListData,
  );

  useEffect(() => {
    dispatch(getUserGalleryApi(1));
  }, []);

  return (
    <div className="w-full h-full">
      {isOpenDetail ? (
        <DetailImage
          isLoading={loadingDetail}
          image={imageDetailData.image}
          width={imageDetailData.width}
          height={imageDetailData.height}
          prompt={imageDetailData.prompt}
          material={imageDetailData.material}
          style={imageDetailData.style}
        />
      ) : null}
      {loadingImgList ? <Spinner /> : null}
      <Masonry
        pageType="myGallery"
        imageList={imageListData}
        callApi={(num) => dispatch(getUserGalleryApi(num))}
        apiLoading={loadingImgList}
      />
    </div>
  );
};

export default UserGallery;
