import { createSlice } from '@reduxjs/toolkit';

interface NegativePropmptState {
  negativePrompt: string;
}

const initialState: NegativePropmptState = {
  negativePrompt: '',
};

export const negativePromptSlice = createSlice({
  name: 'negativePrompt',
  initialState,
  reducers: {
    setNegativePrompt: (state, action) => {
      state.negativePrompt = action.payload;
    },
  },
});

export const { setNegativePrompt } = negativePromptSlice.actions;

export default negativePromptSlice.reducer;
